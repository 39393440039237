import React from 'react';
import { Box, Container } from '@mui/material';

import { Logo } from './Logo';
import { MainNavigation } from './MainNavigation';
import { RightNavigation } from './RightNavigation';
import { RolesRestricted } from '../RequiredAuth/RolesAuthRoute';

const Header = () => {
  return (
    <Box sx={{ display: 'flex', borderBottom: '1px solid #d1d1d1' }}>
      <Container maxWidth="lg" sx={{ paddingY: 3 }}>
        <Box
          display={'flex'}
          width={'100%'}
          alignItems={'flex-end'}
          justifyContent={{ xs: 'space-between', md: 'flex-start' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              order: { xs: '2', md: '1' },
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Logo />
          </Box>
          <Box sx={{ order: { xs: '1', md: '2' }, ml: { xs: 0, md: 5 } }}>
            <MainNavigation />
          </Box>
          <Box sx={{ order: '3', marginLeft: { xs: 0, md: 'auto' } }}>
            <RolesRestricted roles={['member', 'staff']} hideChildComponent={true}>
              <RightNavigation />
            </RolesRestricted>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default Header;
