import React, { useEffect, useState } from 'react';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { MemberActivity } from './types';
import {
  GetMemberActivitiesQuery,
  useGetMemberActivitiesQuery,
  Activity
} from 'src/graphql/generated';

const useFetchAllActivities = (activeCycle: string) => {
  const handleError = useErrorHandler();
  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;
  const [allActivities, setAllActivities] = useState<MemberActivity[]>([]);
  const [fetchActivitesError, setFetchActivitesError] = useState<string | undefined>();

  useEffect(() => {
    setAllActivities([]);
  }, [activeMemberId, activeCycle]);

  const { data, loading, error, fetchMore } = useGetMemberActivitiesQuery({
    variables: { memberid: activeMemberId, cycleName: activeCycle },
    onError: handleError,
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const handleFetchMore = () => {
      const nextToken = data?.getMemberActivities?.nextToken;
      if (nextToken) {
        fetchMore({
          variables: { memberid: activeMemberId, cycleName: activeCycle, nextToken: nextToken },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            const prevItems = prev.getMemberActivities?.items as Activity[];
            const moreItems = fetchMoreResult.getMemberActivities?.items as Activity[];
            const mergedItems: any = [...prevItems, ...moreItems];

            const mergedResult: GetMemberActivitiesQuery = {
              getMemberActivities: {
                items: mergedItems,
                nextToken: fetchMoreResult.getMemberActivities?.nextToken
              }
            };

            return mergedResult;
          }
        }).catch((error) => {
          setFetchActivitesError(error);
        });
      }
    };
    handleFetchMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // Trigger useEffect when data changes

  const isLoadingOrLoadingFetchMore = !!(loading || data?.getMemberActivities?.nextToken);
  useEffect(() => {
    if (!isLoadingOrLoadingFetchMore) {
      setAllActivities(data?.getMemberActivities?.items ?? []);
    }
  }, [data, loading, isLoadingOrLoadingFetchMore]);

  return {
    data,
    loading: isLoadingOrLoadingFetchMore,
    error,
    allActivities: allActivities,
    fetchActivitesError
  };
};

export default useFetchAllActivities;
