import React, { useContext } from 'react';
import { Box } from '@mui/material';

import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { LoadableText } from 'src/components/Loadable/LoadableText';

const ResultsSummary = () => {
  const { firstItemDisplayed, lastItemDisplayed, searchData, resultsForKeyword, searchLoading } =
    useContext(BrowseContext) as BrowseContextType;

  const hasUsedkeywordInSearch = resultsForKeyword && resultsForKeyword.length > 0;
  const totalCount = searchData?.searchActivity?.total ?? 0;
  const showText =
    totalCount > 1
      ? `Showing ${firstItemDisplayed}-${lastItemDisplayed} of ${totalCount} results`
      : `Showing ${totalCount} result${totalCount !== 1 ? 's' : ''}`;

  return (
    <Box>
      <LoadableText
        loading={searchLoading}
        variant="body1"
        color="primary"
        mb={1}
        skeletonProps={{ width: 200 }}
      >
        <span>
          Results
          {hasUsedkeywordInSearch && (
            <>
              {' for '}
              <span style={{ fontWeight: 600 }}>'{resultsForKeyword}'</span>
            </>
          )}
        </span>
      </LoadableText>

      <LoadableText loading={searchLoading} variant="body2" skeletonProps={{ width: 200 }}>
        <span>{showText}</span>
      </LoadableText>
    </Box>
  );
};

export { ResultsSummary };
