import { useMemo, useState, useContext } from 'react';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { Box, Divider, MenuItem, Paper, Select, Typography, Button, Modal } from '@mui/material';
import {
  GetMemberQuery,
  GetRequirementsSummaryQuery,
  HlrStatus,
  useGetMemberHlrDetailsQuery
} from 'src/graphql/generated';
import { CPDHourRequirement } from './CPDHourRequirement';
import { CpdStatementPanel } from './CpdStatementPanel';
import { CPRRequirement } from './CPRRequirement';
import { PLRRequirement } from './PLRRequirement';
import { CPDOneOffRequirement } from './CPDOneOffRequirement';
import { LoadableText } from 'src/components/Loadable/LoadableText';
import { extractRequirements } from '../functions/extract-requirements';
import { colors } from 'src/components/App/colors';
import { useCycles } from 'src/hooks/useCycles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { HighLevelRequirements } from '../HighLevelRequirements/HighLevelRequirements';
import { format } from 'date-fns';

const modalBox = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'hidden',
  width: '80%',
  maxWidth: '500px'
};
type DashboardProps = {
  memberInfo?: GetMemberQuery;
  requirementsSummary?: GetRequirementsSummaryQuery;
  activeCycle: string;
  onChangeActiveCycle: (cycle: string) => void;
  onPressItem: (categoryGroup: any) => void;
};

export const Dashboard = ({
  requirementsSummary,
  memberInfo,
  activeCycle,
  onChangeActiveCycle,
  onPressItem
}: DashboardProps) => {
  const [open, setOpen] = useState(false);
  const {
    requirementMBA,
    categoryGroupEA,
    categoryGroupPDP,
    categoryGroupBLS,
    categoryGroupRPMO,
    categoryGroupRPMOEU,
    categoryGroupPLR,
    requirementRACGP
  } = useMemo(() => {
    return extractRequirements(requirementsSummary);
  }, [requirementsSummary]);

  const { cyclesList } = useCycles();

  const annualCompleted = requirementMBA?.category_group_requirements?.filter(
    (mbaRequirement) => mbaRequirement?.completed
  )?.length;
  const annualTotal = requirementMBA?.category_group_requirements?.length;

  const racgpAnnualCompleted = requirementRACGP?.category_group_requirements?.filter(
    (racgpRequirement) => racgpRequirement?.completed
  )?.length;
  const racgpAnnualTotal = requirementRACGP?.category_group_requirements?.length;

  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const eaHours = requirementMBA?.category_code_totals
    ?.filter((code) => code?.type === 'EA')
    .map((hour) => hour?.hours);
  const rpHours = requirementMBA?.category_code_totals
    ?.filter((code) => code?.type === 'RP')
    .map((hour) => hour?.hours);
  const moHours = requirementMBA?.category_code_totals
    ?.filter((code) => code?.type === 'MO')
    .map((hour) => hour?.hours);

  const { data: hlrDetailsData } = useGetMemberHlrDetailsQuery({
    variables: { memberId: activeMemberId, asAtDate: activeCycle + '-01-01' },
    fetchPolicy: 'cache-and-network'
  });

  const hlrCompleted = hlrDetailsData?.getMemberHlrDetails?.filter(
    (hlr) => hlr?.hlr_status === HlrStatus.HlrSubmitted
  ).length;
  const hlrTotal = hlrDetailsData?.getMemberHlrDetails?.length;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          gap: { xs: 1, md: 3 },
          gridTemplateAreas: {
            sm: `"welcome . cycle-selector"`,
            xs: `"welcome"
            "cycle-selector"`
          },
          gridTemplateColumns: { xs: `1fr`, sm: '1fr 1fr' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: 1,
            gridArea: 'welcome'
          }}
        >
          <LoadableText
            loading={!memberInfo}
            variant="caption"
            gutterBottom
            skeletonProps={{ width: 100 }}
            color={'primary'}
          >
            Welcome
            <Typography color={'primary'} variant="caption" fontWeight={600} gutterBottom>
              , {memberInfo?.getMember?.first_name}
            </Typography>
          </LoadableText>
          <Typography color={'primary'} noWrap={true} variant="h2">
            myCPD {activeCycle}
          </Typography>
        </Box>
        <Box
          sx={{
            gridArea: 'cycle-selector',
            justifySelf: 'flex-end',
            display: 'flex',
            alignItems: 'end'
          }}
        >
          <CpdStatementPanel></CpdStatementPanel>
          <Select
            value={activeCycle}
            onChange={(event) => onChangeActiveCycle(event.target.value)}
            sx={{
              ml: 2,
              borderRadius: '8px',
              backgroundColor: 'white'
            }}
            size="small"
            renderValue={(value) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', color: '#323232' }}>
                  <CalendarTodayIcon sx={{ pr: 1, fontSize: '15px' }} />
                  Year: {`  `}
                  {value}
                </Box>
              );
            }}
          >
            {cyclesList.map((cycle) => {
              return (
                <MenuItem value={cycle} key={cycle}>
                  {cycle}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          py: { xs: 2, md: 3 },
          gap: { xs: 1, md: 2 }
        }}
      >
        <Box sx={{ flex: 1, flexBasis: { xs: '49%', sm: '0%' } }}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              border: `1px solid ${colors['stone.100']}`,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ display: 'flex', flex: 1 }}>
              <Typography
                color={'primary'}
                noWrap={true}
                display={'block'}
                variant="caption"
                gutterBottom
              >
                Total Hours
              </Typography>
              <Button onClick={handleOpen} sx={{ minWidth: 'initial', p: 0 }}>
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{ mt: -0.5, ml: 0.5, color: 'primary.main' }}
                />
              </Button>
            </Box>
            <LoadableText
              color={'primary'}
              loading={!requirementMBA}
              noWrap={true}
              variant="h2"
              sx={{ mt: 'auto' }}
            >
              {requirementMBA?.hours}{' '}
              <Box display={'inline-block'}>
                <Typography color={'inherit'} display={'inline'} variant="subtitle1">
                  / {requirementsSummary?.getRequirementsSummary?.requirements?.[0]?.minimum}
                </Typography>
              </Box>
            </LoadableText>
          </Paper>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalBox}>
              <Box sx={{ position: 'absolute', alignItems: 'flex-end', top: '8px', right: '8px' }}>
                <CloseIcon onClick={handleClose} sx={{ marginLeft: 'auto', cursor: 'pointer' }} />
              </Box>
              <Box id="modal-modal-title">
                <Typography variant="h3" sx={{ color: 'primary.main', display: 'flex' }}>
                  <AccessTimeIcon
                    fontSize="small"
                    sx={{ mr: 1, mt: '2px', color: 'secondary.main' }}
                  />{' '}
                  Breakdown of Hours
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box id="modal-modal-description">
                <Box sx={{ display: 'flex', alignItems: 'center' }} id="modal-modal-title">
                  <Typography variant="body1">Educational Activites (EA)</Typography>
                  <Typography sx={{ marginLeft: 'auto', textAlign: 'right' }}>
                    <>{eaHours} CPD Hours</>
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }} id="modal-modal-title">
                  <Typography variant="body1">Reviewing Performance (RP)</Typography>
                  <Typography sx={{ marginLeft: 'auto', textAlign: 'right' }}>
                    <>{rpHours} CPD Hours</>
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }} id="modal-modal-title">
                  <Typography variant="body1">Measuring Outcomes (MO)</Typography>
                  <Typography sx={{ marginLeft: 'auto', textAlign: 'right' }}>
                    <>{moHours} CPD Hours</>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
        <Box sx={{ flex: 1, flexBasis: { xs: '49%', sm: '0%' } }}>
          <Paper
            elevation={0}
            sx={{ p: 2, border: `1px solid ${colors['stone.100']}`, height: '100%' }}
          >
            <Typography
              color={'primary'}
              sx={{ whiteSpace: { xs: 'normal', sm: 'nowrap' } }}
              display={'block'}
              variant="caption"
              gutterBottom
            >
              Annual Requirements
            </Typography>
            <LoadableText
              color={'primary'}
              loading={!requirementsSummary}
              noWrap={true}
              variant="h2"
            >
              {annualCompleted}{' '}
              <Box display={'inline-block'}>
                <Typography color={'inherit'} display={'inline'} variant="subtitle1">
                  / {annualTotal}
                </Typography>
              </Box>
            </LoadableText>
          </Paper>
        </Box>
        <Box sx={{ flex: 1, flexBasis: { xs: '49%', sm: '0%' } }}>
          <Paper
            elevation={0}
            sx={{ p: 2, border: `1px solid ${colors['stone.100']}`, height: '100%' }}
          >
            <Typography
              color={'primary'}
              sx={{ whiteSpace: { xs: 'normal', sm: 'nowrap' } }}
              display={'block'}
              variant="caption"
              gutterBottom
            >
              GP Specialist High-Level Requirement (CPR)
            </Typography>
            <LoadableText
              color={'primary'}
              loading={!requirementsSummary}
              noWrap={true}
              variant="h2"
            >
              {racgpAnnualCompleted}{' '}
              <Box display={'inline-block'}>
                <Typography color={'inherit'} display={'inline'} variant="subtitle1">
                  / {racgpAnnualTotal}
                </Typography>
              </Box>
            </LoadableText>
          </Paper>
        </Box>
        {activeCycle > '2023' && hlrTotal! > 0 && (
          <Box sx={{ flex: 1, flexBasis: { xs: '49%', sm: '0%' } }}>
            <Paper
              elevation={0}
              sx={{ p: 2, border: `1px solid ${colors['stone.100']}`, height: '100%' }}
            >
              <Typography
                color={'primary'}
                sx={{ whiteSpace: { xs: 'normal', sm: 'nowrap' } }}
                display={'block'}
                variant="caption"
                gutterBottom
              >
                Dual Fellowship Requirements
              </Typography>
              <LoadableText
                color={'primary'}
                loading={!requirementsSummary}
                noWrap={true}
                variant="h2"
              >
                {hlrCompleted}{' '}
                <Box display={'inline-block'}>
                  <Typography color={'inherit'} display={'inline'} variant="subtitle1">
                    / {hlrTotal}
                  </Typography>
                </Box>
              </LoadableText>
            </Paper>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          py: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: 1,
          width: '100%'
        }}
      >
        <Typography color={'primary'} sx={{ fontWeight: 600 }} variant={'body1'}>
          Requirements
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: `1fr`, sm: `2fr 2fr`, md: `1fr 1fr 1fr 1fr` },
            py: 2
          }}
          width={'100%'}
          height="auto"
          gap={2}
        >
          <Box>
            <CPDHourRequirement
              onSelect={() => onPressItem(categoryGroupEA)}
              categoryGroupRequirement={categoryGroupEA}
              color={'#11364D'}
            />
          </Box>
          <Box>
            <CPDHourRequirement
              onSelect={() => onPressItem(categoryGroupRPMO)}
              categoryGroupRequirement={categoryGroupRPMO}
              color={'#F06000'}
            />
          </Box>
          <Box>
            <CPDHourRequirement
              onSelect={() => onPressItem(categoryGroupRPMOEU)}
              categoryGroupRequirement={categoryGroupRPMOEU}
              color={'#1D9F90'}
            />
          </Box>
          <Box display={'flex'} flex={1} flexDirection={'column'} gap={2}>
            <Box flexGrow={1}>
              <CPDOneOffRequirement
                categoryGroupRequirement={categoryGroupPDP}
                activeCycle={activeCycle}
              />
            </Box>
            {activeCycle > '2023' && (
              <Box flexGrow={1}>
                <PLRRequirement
                  activeCycle={activeCycle}
                  categoryGroupRequirement={categoryGroupPLR}
                />
              </Box>
            )}
            <Box flexGrow={1}>
              <CPRRequirement
                cprToDate={requirementRACGP?.requirement_to}
                onSelect={() => onPressItem(categoryGroupBLS)}
                categoryGroupRequirement={categoryGroupBLS}
              />
            </Box>
          </Box>
        </Box>
        {activeCycle > '2023' && (
          <HighLevelRequirements activeCycle={activeCycle} hlrDetailsData={hlrDetailsData} />
        )}
      </Box>
    </Box>
  );
};
