import * as React from 'react';

import { formatISO, startOfDay, endOfDay } from 'date-fns';
import { ApolloError } from '@apollo/client';
import { DateRange } from '@mui/x-date-pickers-pro';

import {
  useGetSearchActivityLazyQuery,
  GetSearchActivityQuery,
  SortFieldInput,
  SortOrder,
  Facet
} from 'src/graphql/generated';
import { FACET_NAMES } from 'src/components/Pages/Browse/constants';

const BrowseContext = React.createContext<BrowseContextType | null>(null);

export type BrowseContextType = {
  keyword: string | null;
  setKeyword: (keyword: string) => void;
  specificRequirements: string[];
  setSpecificRequirements: (specificRequirements: string[]) => void;
  providerTypes: string[];
  setProviderTypes: (providerTypes: string[]) => void;
  requirement: string[];
  setRequirement: (requirement: string[]) => void;
  programLevels: string[];
  setProgramLevels: (programLevels: string[]) => void;
  deliveryMode: string[];
  setDeliveryMode: (deliveryMode: string[]) => void;
  ruralCpdYesNo: string;
  setRuralCpdYesNo: (ruralCpdYesNo: string) => void;
  eligibleForGrantsYesNo: string;
  setEligibleForGrantsYesNo: (eligibleForGrantsYesNo: string) => void;
  activityCriteriaFilterCount: number;
  setActivityCriteriaFilterCount: (activityCriteriaFilterCount: number) => void;
  selectedStates: string[];
  duration: string[];
  setDuration: (duration: string[]) => void;
  setSelectedStates: (selectedStates: string[]) => void;
  selectedDays: string[];
  setSelectedDays: (selectedDays: string[]) => void;
  dateDateArray: DateRange<Date>;
  setDateDateArray: (dateDateArray: DateRange<Date>) => void;
  areasOfInterests: string[];
  setAreasOfInterests: (areasOfInterests: string[]) => void;
  searchData: GetSearchActivityQuery | undefined;
  searchError: ApolloError | undefined;
  searchLoading: boolean;
  getFacetList: (
    facetName: string,
    defaultList: { label: string; value: string }[]
  ) => { label: string; value: string }[];
  lastChangeFacet: string;
  setLastChangeFacet: (facetName: string) => void;
  executeSearch: (pageNumber?: number) => void;
  executeNewSearch: () => void;
  clearFilters: () => void;
  canClearFilter: boolean;
  resultsForKeyword: string;
  firstItemDisplayed: number;
  lastItemDisplayed: number;
  paginationPageNumber: number;
  paginationPageCount: number;
  selectedFiltersCount: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  orderBy: string;
  setOrderBy: (sortBy: string) => void;
};

const BrowseContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [paginationPageNumber, setPaginationPageNumber] = React.useState<number>(1);
  const paginationLimit = 10;
  const [keyword, setKeyword] = React.useState<string>('');
  const setKeywordTrimmed = (keyword: string) => setKeyword(keyword.trim());
  const [selectedFiltersCount, setSelectedFiltersCount] = React.useState<number>(0);
  const [providerTypes, setProviderTypes] = React.useState<string[]>([]);
  const [requirement, setRequirement] = React.useState<string[]>([]);
  const [programLevels, setProgramLevels] = React.useState<string[]>([]);
  const [deliveryMode, setDeliveryMode] = React.useState<string[]>([]);
  const [selectedStates, setSelectedStates] = React.useState<string[]>([]);
  const [ruralCpdYesNo, setRuralCpdYesNo] = React.useState<string>('');
  const [activityCriteriaFilterCount, setActivityCriteriaFilterCount] = React.useState<number>(0);
  const [eligibleForGrantsYesNo, setEligibleForGrantsYesNo] = React.useState<string>('');
  const [duration, setDuration] = React.useState<string[]>([]);
  const [selectedDays, setSelectedDays] = React.useState<string[]>([]);
  const [specificRequirements, setSpecificRequirements] = React.useState<string[]>([]);
  const [resultsForKeyword, setResultsForKeyword] = React.useState<string>('');
  const [dateDateArray, setDateDateArray] = React.useState<DateRange<Date>>([null, null]);
  const [areasOfInterests, setAreasOfInterests] = React.useState<string[]>([]);
  const [initialFacet, setInitialFacet] = React.useState<Facet[] | null>(null);
  const [lastChangeFacet, setLastChangeFacet] = React.useState<string>('');
  const [clearCounter, setClearCounter] = React.useState<number>(0);
  const [orderBy, setOrderBy] = React.useState<string>('score');

  const [getSearchContentLazy, { data: searchData, error: searchError, loading: searchLoading }] =
    useGetSearchActivityLazyQuery({
      onCompleted: (data) => {
        if (initialFacet) return;
        const initialFacetResult = data.searchActivity?.facets as Facet[];
        setInitialFacet(initialFacetResult);
      }
    });

  const getFacetList = (facetName: string, defaultList: { label: string; value: string }[]) => {
    const facetList =
      initialFacet
        ?.find((facet) => facet?.name === facetName)
        ?.values?.map((value) => {
          return { label: value?.value as string, value: value?.value as string };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || defaultList;

    return facetList;
  };

  const totalCount = searchData?.searchActivity?.total ?? paginationLimit;
  const paginationPageCount = Math.ceil(totalCount / paginationLimit);
  const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPaginationPageNumber(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const firstItemDisplayed = (paginationPageNumber - 1) * paginationLimit + 1;
  const lastItemDisplayed = Math.min(
    searchData?.searchActivity?.total ?? paginationLimit,
    paginationPageNumber * paginationLimit
  );

  function executeSearch(pageNumber?: number) {
    let filters: any[] = [];

    // TODO: add other filters here.
    // Examples: https://racgp.visualstudio.com/CPD%20Azure/_wiki/wikis/CPD-Azure-Migration.wiki/133/How-do-I-to-use-the-CPD-activity-search-index-

    const hasProviderType = providerTypes && providerTypes.length > 0;
    if (hasProviderType) {
      providerTypes.forEach((providerType) => {
        filters.push({ name: FACET_NAMES.ProviderType, value: providerType });
      });
    }

    const hasRequirement = requirement && requirement.length > 0;
    if (hasRequirement) {
      requirement.forEach((requirements) => {
        filters.push({ name: FACET_NAMES.RequirementsName, value: requirements });
      });
    }

    const hasProgramLevels = programLevels && programLevels.length > 0;
    if (hasProgramLevels) {
      programLevels.forEach((programLevel) => {
        filters.push({ name: FACET_NAMES.ProgramLevels, value: programLevel });
      });
    }

    const hasDeliveryMode = deliveryMode && deliveryMode.length > 0;
    if (hasDeliveryMode) {
      deliveryMode.forEach((deliveryModes) => {
        filters.push({ name: FACET_NAMES.DeliveryMode, value: deliveryModes });
      });
    }

    const hasSpecificRequirement = specificRequirements && specificRequirements.length > 0;
    if (hasSpecificRequirement) {
      specificRequirements.forEach((specificRequirement) => {
        filters.push({
          name: FACET_NAMES.SpecificRequirements,
          value: specificRequirement
        });
      });
    }

    // Displayed in UI under Activity Criteria
    const hasRuralCpdYesNo = ruralCpdYesNo;
    if (hasRuralCpdYesNo) {
      filters.push({ name: FACET_NAMES.RuralCpdYesNo, value: ruralCpdYesNo });
    }
    // Displayed in UI under Activity Criteria
    const hasEligibleForGrantsYesNo = eligibleForGrantsYesNo;
    if (hasEligibleForGrantsYesNo) {
      filters.push({ name: FACET_NAMES.EligibleForGrantsYesNo, value: eligibleForGrantsYesNo });
    }
    const hasDuration = duration && duration.length > 0;
    if (hasDuration) {
      duration.forEach((durations) => {
        filters.push({ name: FACET_NAMES.HoursGroup, value: durations });
      });
    }
    const hasSessionState = selectedStates && selectedStates.length > 0;
    if (hasSessionState) {
      selectedStates.forEach((selectedState) => {
        filters.push({ name: FACET_NAMES.SessionsState, value: selectedState });
      });
    }
    const hasSessionFromDayOfWeek = selectedDays && selectedDays.length > 0;
    if (hasSessionFromDayOfWeek) {
      selectedDays.forEach((selectedDay) => {
        filters.push({ name: FACET_NAMES.SessionsFromDayOfWeek, value: selectedDay });
      });
    }

    const sessionDateFrom = dateDateArray && dateDateArray[0];
    if (sessionDateFrom) {
      const sessionsFromDateTime = formatISO(startOfDay(sessionDateFrom));
      filters.push({ name: FACET_NAMES.SessionsFromDateTime, value: sessionsFromDateTime });
    }

    const sessionDateTo = dateDateArray && dateDateArray[1];
    if (sessionDateTo) {
      const sessionsToDateTime = formatISO(endOfDay(sessionDateTo));
      filters.push({ name: FACET_NAMES.SessionsToDateTime, value: sessionsToDateTime });
    }
    const hasAreaOfInterest = areasOfInterests && areasOfInterests.length > 0;
    if (hasAreaOfInterest) {
      areasOfInterests.forEach((areasOfInterest) => {
        filters.push({ name: FACET_NAMES.CurriculumContextualUnitsName, value: areasOfInterest });
      });
    }

    const hasOrderBy = orderBy && orderBy !== 'score';
    let orderByList: SortFieldInput[] = [];
    if (hasOrderBy) {
      if (orderBy === 'ApprovedDate') {
        orderByList = [{ name: 'StatusEffectiveDate', order: SortOrder.Descending }];
      } else if (orderBy === 'HoursAscending') {
        orderByList = [{ name: 'Hours', order: SortOrder.Ascending }];
      } else if (orderBy === 'HoursDescending') {
        orderByList = [{ name: 'Hours', order: SortOrder.Descending }];
      } else {
        console.error(`Unsupported OrderBy selected (${orderBy}).`);
      }
    }

    const from = pageNumber ?? paginationPageNumber;
    setPaginationPageNumber(from);

    getSearchContentLazy({
      variables: {
        request: {
          text: keyword ?? '',
          filters: filters,
          from: from,
          limit: paginationLimit,
          orderBy: orderByList
        }
      }
    });
    setResultsForKeyword(keyword ?? '');
  }

  function executeNewSearch() {
    executeSearch(1);
  }

  const totalFilterCount =
    providerTypes.length +
    requirement.length +
    programLevels.length +
    deliveryMode.length +
    activityCriteriaFilterCount +
    duration.length +
    selectedStates.length +
    selectedDays.length;

  const canClearFilter = () =>
    totalFilterCount > 0 ||
    !!dateDateArray[0] ||
    !!dateDateArray[1] ||
    specificRequirements.length > 0 ||
    areasOfInterests.length > 0;

  function clearFilters() {
    setProviderTypes([]);
    setRequirement([]);
    setProgramLevels([]);
    setDeliveryMode([]);
    setRuralCpdYesNo('');
    setEligibleForGrantsYesNo('');
    setActivityCriteriaFilterCount(0);
    setDuration([]);
    setSelectedStates([]);
    setSpecificRequirements([]);
    setSelectedDays([]);
    setDateDateArray([null, null]);
    setAreasOfInterests([]);
    setClearCounter(clearCounter + 1);
    setLastChangeFacet('');
  }

  // Effect to clear counters
  React.useEffect(() => {
    executeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCounter]);

  // Effect to get new page
  React.useEffect(() => {
    executeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationPageNumber]);

  // Effect to trigger search on filter change
  React.useEffect(() => {
    executeNewSearch();
    setSelectedFiltersCount(totalFilterCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    providerTypes,
    requirement,
    programLevels,
    deliveryMode,
    specificRequirements,
    ruralCpdYesNo,
    activityCriteriaFilterCount,
    eligibleForGrantsYesNo,
    duration,
    selectedStates,
    dateDateArray,
    areasOfInterests,
    orderBy
  ]);

  // Effect on first load
  React.useEffect(() => {
    executeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowseContext.Provider
      value={{
        firstItemDisplayed: firstItemDisplayed,
        lastItemDisplayed: lastItemDisplayed,
        keyword: keyword,
        setKeyword: setKeywordTrimmed,
        specificRequirements: specificRequirements,
        setSpecificRequirements: setSpecificRequirements,
        providerTypes: providerTypes,
        setProviderTypes: setProviderTypes,
        requirement: requirement,
        setRequirement: setRequirement,
        programLevels: programLevels,
        setProgramLevels: setProgramLevels,
        deliveryMode: deliveryMode,
        setDeliveryMode: setDeliveryMode,
        ruralCpdYesNo: ruralCpdYesNo,
        setRuralCpdYesNo: setRuralCpdYesNo,
        eligibleForGrantsYesNo: eligibleForGrantsYesNo,
        setEligibleForGrantsYesNo: setEligibleForGrantsYesNo,
        activityCriteriaFilterCount: activityCriteriaFilterCount,
        setActivityCriteriaFilterCount: setActivityCriteriaFilterCount,
        duration: duration,
        setDuration: setDuration,
        selectedStates: selectedStates,
        setSelectedStates: setSelectedStates,
        selectedDays: selectedDays,
        setSelectedDays: setSelectedDays,
        dateDateArray: dateDateArray,
        setDateDateArray: setDateDateArray,
        areasOfInterests: areasOfInterests,
        setAreasOfInterests: setAreasOfInterests,
        searchData: searchData,
        searchError: searchError,
        searchLoading: searchLoading,
        executeSearch: executeSearch,
        executeNewSearch: executeNewSearch,
        clearFilters: clearFilters,
        canClearFilter: canClearFilter(),
        resultsForKeyword: resultsForKeyword,
        paginationPageNumber: paginationPageNumber,
        selectedFiltersCount: selectedFiltersCount,
        paginationPageCount: paginationPageCount,
        handlePageChange: onPageChange,
        getFacetList: getFacetList,
        lastChangeFacet: lastChangeFacet,
        setLastChangeFacet: setLastChangeFacet,
        orderBy: orderBy,
        setOrderBy: setOrderBy
      }}
    >
      {children}
    </BrowseContext.Provider>
  );
};

export { BrowseContext, BrowseContextProvider };
