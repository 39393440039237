import React from 'react';
import { Container, Paper, Skeleton } from '@mui/material';

function PdpLoading() {
  return (
    <>
      <Container maxWidth="lg">
        <Paper sx={{ p: 2, mt: 2 }}>
          <Skeleton
            variant="rectangular"
            sx={{ mt: 2, width: '100px', borderRadius: '10px', bgcolor: 'grey.100' }}
          />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '30%', bgcolor: 'grey.100' }} />
        </Paper>
      </Container>
    </>
  );
}
export { PdpLoading };
