import React, { useContext, useEffect } from 'react';
import { Grid, Paper, useTheme, useMediaQuery, Typography } from '@mui/material';
import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { FilterByDrawers } from './FilterByDrawers';
import { useGetSearchActivitySuggestionsLazyQuery } from 'src/graphql/generated';
import { SearchBar } from '../../SearchBar/SearchBar';

const SearchCard = () => {
  const { keyword, setKeyword } = useContext(BrowseContext) as BrowseContextType;
  const [suggestedKeywordsList, setSuggestedKeywordsList] = React.useState<string[]>([]);
  const { executeNewSearch } = useContext(BrowseContext) as BrowseContextType;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [getSearchSuggestionsLazy] = useGetSearchActivitySuggestionsLazyQuery();

  useEffect(() => {
    const hasKeyword = keyword && keyword.length >= 2;
    if (hasKeyword) {
      getSearchSuggestionsLazy({
        variables: { text: keyword },
        onCompleted: (data) => {
          const keywordListTemp = (data?.searchActivitySuggestions as string[]) ?? [];
          if (keywordListTemp.length > 0) {
            setSuggestedKeywordsList(keywordListTemp.slice(0, 10));
          } else {
            setSuggestedKeywordsList(['No matching results']);
          }
        }
      });
    } else {
      setSuggestedKeywordsList([]);
    }
  }, [keyword, getSearchSuggestionsLazy]);

  return (
    <Paper elevation={3} sx={{ width: '100%', p: { xs: 2, md: 3 }, my: 2 }}>
      <Grid container>
        <SearchBar
          keyword={keyword}
          onSelectKeyword={(selectedKeyword) => setKeyword(selectedKeyword)}
          onExecuteSearch={executeNewSearch}
          suggestedKeywordsList={suggestedKeywordsList}
        />
        {matches && (
          <Grid item xs={12}>
            <FilterByDrawers />
          </Grid>
        )}
        <Typography ml={{ sm: 0, md: 2 }} variant={'caption'} mt={0.5}>
          Search by Provider name, Activity title and Activity ID
        </Typography>
      </Grid>
    </Paper>
  );
};

export { SearchCard };
