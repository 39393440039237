import React, { useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField,
  Paper,
  PaperProps
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { FacetCount } from './FacetCount';
import { FACET_NAMES, FACET_VALUES_SPECIFIC_REQUIREMENTS_LIST } from './constants';

const StyledPaper = (props: PaperProps) => {
  return (
    <Paper
      id="filterby-specific-requirements"
      elevation={0}
      {...props}
      sx={{
        backgroundColor: '#f8f8f8'
      }}
    />
  );
};

const FilterBySpecificRequirements = () => {
  const { specificRequirements, setSpecificRequirements, setLastChangeFacet, getFacetList } =
    useContext(BrowseContext) as BrowseContextType;

  const facetList = getFacetList(
    FACET_NAMES.SpecificRequirements,
    FACET_VALUES_SPECIFIC_REQUIREMENTS_LIST
  );

  const getOptionLabel = (optionValue: string) => {
    const matchingOption = facetList.find((option) => option.value === optionValue);
    return matchingOption?.label || optionValue;
  };

  const handleChange = (event: any, selectedValue: any) => {
    setLastChangeFacet(FACET_NAMES.SpecificRequirements);
    setSpecificRequirements(selectedValue);
  };

  return (
    <Box>
      <Accordion elevation={0} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="specific-requirements-content"
          id="specific-requirements-header"
        >
          <Typography variant="subtitle2" color="primary">
            Specific Requirements{' '}
            {specificRequirements.length > 0 && '(' + specificRequirements.length + ')'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <Autocomplete
            PaperComponent={StyledPaper}
            limitTags={1}
            value={specificRequirements}
            onChange={handleChange}
            multiple={true}
            fullWidth
            disablePortal={true}
            options={facetList.map((option) => option.value)}
            // getOptionLabel={getOptionLabel}
            disableCloseOnSelect
            renderOption={(props, optionValue) => {
              const lable = getOptionLabel(optionValue);
              return (
                <li {...props}>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Typography variant="body2">{lable}</Typography>
                    <FacetCount
                      facetName={FACET_NAMES.SpecificRequirements}
                      facetValueString={optionValue}
                    />
                  </Box>
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Search" placeholder="Search" />}
            sx={{
              '&.MuiAutocomplete-fullWidth': { maxWidth: { sm: '287px', lg: 'initial' } },
              '& .MuiAutocomplete-tag': { maxWidth: { xs: '100%', sm: '170px' } }
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { FilterBySpecificRequirements };
