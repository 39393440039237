import { useContext } from 'react';
import { Paper, Typography, Button, useTheme, Box, Alert } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import {
  Notification,
  NotificationType,
  NotificationStatus,
  useUpdateNotificationsMutation
} from 'src/graphql/generated';
import { keyframes } from '@emotion/react';
import { NotificationsOutlined } from '@mui/icons-material';
import { RemoveNotificationButton } from './RemoveNotificationButton';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

type Props = {
  notification: Notification | null | undefined;
};

const CPDUpdateResult: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const startDate = format(
    parseISO(`${props.notification?.cpd_update_details?.start_date}`),
    'eee do MMM Y - p'
  );

  const url = props.notification?.cpd_update_details?.url ?? '';
  const hasUrl = url && url.length > 0;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    updateNotifications();
    window.open(url, '_blank');
  };

  var notification_type = NotificationType.CpdUpdate;
  if (props.notification?.type) {
    notification_type = props.notification?.type;
  }
  var notification_status = NotificationStatus.Read;

  const [updateNotifications, { error }] = useUpdateNotificationsMutation({
    variables: {
      memberid: activeMemberId,
      notifications: [
        {
          type: notification_type,
          reference_id: props.notification?.reference_id,
          notification_status: notification_status
        }
      ]
    },
    refetchQueries: ['getNotifications']
  });

  if (error) {
    return (
      <Paper
        sx={{
          p: 2,
          mt: 2,
          animation: `${fadeInAnimation} 0.5s`,
          border:
            props.notification?.notification_status === 'unread'
              ? '3px solid' + theme.palette.secondary.main
              : 'transparent'
        }}
      >
        <Box sx={{ p: 2 }}>
          <Alert severity="error">Something went wrong please try again</Alert>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper
      sx={{
        p: 2,
        mt: 2,
        animation: `${fadeInAnimation} 0.5s`,
        border:
          props.notification?.notification_status === 'unread'
            ? '3px solid' + theme.palette.secondary.main
            : 'transparent'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NotificationsOutlined color="secondary" sx={{ mr: 1 }} />
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            CPD Program Update
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1
          }}
        >
          <RemoveNotificationButton notification={props.notification} activity={null} />
        </Box>
      </Box>
      <Typography variant="body2" color="secondary">
        {startDate}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 600, mt: 1 }}>
        {props.notification?.cpd_update_details?.title}
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        {props.notification?.cpd_update_details?.description}
      </Typography>

      {hasUrl && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 4
          }}
        >
          <Button onClick={handleClick}>
            View Program Update <ChevronRight />
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export { CPDUpdateResult };
