import { useGetPdpDetailsQuery, useGetMemberAttachmentUrlsQuery } from 'src/graphql/generated';
import { useMemo } from 'react';

export const useMemberAttachments = ({
  memberId,
  pdp_year
}: {
  memberId: string | undefined | null;
  pdp_year: string | undefined | null;
}) => {
  const { data } = useGetPdpDetailsQuery({
    variables: { memberid: memberId, pdp_year: pdp_year },
    fetchPolicy: 'cache-and-network'
  });

  const uploadedAttachments = data?.getPdpDetails?.attachments?.map((attachment) => {
    const { url, __typename, ...rest } = attachment!;
    return {
      ...rest
    };
  });

  const { data: memberAttachmentsUrls } = useGetMemberAttachmentUrlsQuery({
    variables: { memberid: memberId, attachments: uploadedAttachments },
    skip: !data
  });

  const memberAttachmentsData = useMemo(() => {
    return memberAttachmentsUrls
      ? {
          attachments: memberAttachmentsUrls?.getMemberAttachmentsUrls
        }
      : undefined;
  }, [memberAttachmentsUrls]);

  return { memberAttachmentsData };
};
