import React, { useContext } from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';

import { HistoryContext, HistoryContextType } from 'src/contexts/HistoryContext';

type AlertsProps = {
  updatedId?: string;
  deletedId?: string;
};

export const Alerts = ({ updatedId, deletedId }: AlertsProps) => {
  const {
    deleteQuickLogMutation: [, deleteQuickLogMutationStatus],
    deleteHlrMutation: [, deleteHlrMutationStatus],
    backdateMutation: [, backdateMutationStatus]
  } = useContext(HistoryContext) as HistoryContextType;

  const successfulDelete =
    !!deletedId || !!deleteQuickLogMutationStatus.data?.deleteQuickLogActivity?.activitykey;

  const successfulHlrDelete = !!deleteHlrMutationStatus.data?.deleteHlrActivity?.sortkey;
  const successfulBackdate = !!(
    backdateMutationStatus.data?.backdateActivity?.is_backdated ?? false
  );

  if (updatedId) {
    return (
      <Alert severity={'success'}>
        <AlertTitle>
          <Typography fontWeight={500} variant={'body2'}>
            Your Quick log has been successfully updated.
          </Typography>
        </AlertTitle>

        <Typography variant={'body2'}>
          Changes may take up to 2 minutes to appear on this page.
        </Typography>
      </Alert>
    );
  }

  if (successfulDelete) {
    return (
      <Alert severity={'success'}>
        <Typography variant={'body2'}>
          Your Quick Log record has successfully been deleted.
        </Typography>
      </Alert>
    );
  }

  if (successfulHlrDelete) {
    return (
      <Alert severity={'success'}>
        <Typography variant={'body2'}>
          Your Specialist High Level Requirement record has successfully been deleted.
        </Typography>
      </Alert>
    );
  }
  if (successfulBackdate) {
    return (
      <Alert severity={'success'}>
        <AlertTitle>
          <Typography fontWeight={500} variant={'body2'}>
            Your activity has been moved to 2023
          </Typography>
        </AlertTitle>
        <Typography variant={'body2'}>
          Changes may take up to 2 minutes to appear on this page.
        </Typography>
      </Alert>
    );
  }
  return null;
};
