export const MAX_UPLOAD_FILES = 5;
export const MAX_UPLOAD_FILE_SIZE = 15 * 1024 * 1024; //15MB
export const CPD_TOTAL_HOUR_LIMIT = 500;
export const SUCCESS_ALERT_ACTIVITY_TITLE_MAX_LENGTH = 50;

export const ACCEPTED_FILE_TYPES = {
  'image/png': ['.png'],
  'image/tiff': ['.tif', '.tiff'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx']
};
