import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';

export type PopupAlertProps = {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  message?: string;
  confirm?: boolean;
  onConfirm?: () => void;
};

export const PopupAlert = ({
  isOpen,
  onClose,
  title,
  message,
  confirm = false,
  onConfirm = () => {}
}: PopupAlertProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant={'h4'} fontWeight={500} component="span">
          {title}
        </Typography>
      </DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText>
            <Typography variant={'body1'} component="span">
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {confirm && (
          <Button
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            Delete
          </Button>
        )}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
