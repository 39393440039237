import React from 'react';
import { Typography, Box } from '@mui/material';
import { SearchActivityItem } from 'src/graphql/generated';
import { Person, DesktopMacOutlined, Videocam, Subject, Groups } from '@mui/icons-material';
import {
  DELIVERY_MODE_BLENDED,
  DELIVERY_MODE_ELEARNING,
  DELIVERY_MODE_FACE_TOFACE,
  DELIVERY_MODE_VIRTUAL
} from '../constants';

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
};

const DeliveryMode: React.FC<Props> = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {(() => {
        switch (props?.searchActivity?.activity?.deliveryMode) {
          case DELIVERY_MODE_BLENDED:
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Groups color="secondary" sx={{ fontSize: '20px', mr: 1 }} />
                <Typography variant="body2">Blended </Typography>
              </Box>
            );
          case DELIVERY_MODE_ELEARNING:
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <DesktopMacOutlined color="secondary" sx={{ fontSize: '20px', mr: 1 }} />
                <Typography variant="body2">e-Learning </Typography>
              </Box>
            );
          case DELIVERY_MODE_FACE_TOFACE:
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Person color="secondary" sx={{ fontSize: '20px', mr: 1 }} />
                <Typography variant="body2">Face-to-face</Typography>
              </Box>
            );
          case DELIVERY_MODE_VIRTUAL:
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Videocam color="secondary" sx={{ fontSize: '20px', mr: 1 }} />
                <Typography variant="body2">Virtual</Typography>
              </Box>
            );
          default:
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Subject color="secondary" sx={{ fontSize: '20px', mr: 1 }} />
                <Typography variant="body2">Other</Typography>
              </Box>
            );
        }
      })()}
    </Box>
  );
};

export { DeliveryMode };
