import React, { useContext } from 'react';
import { Container, Box, Drawer, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';

import { FilterBy } from './FilterBy';
import { OrderBy } from './OrderBy';

const FilterByDrawers = () => {
  const { selectedFiltersCount } = useContext(BrowseContext) as BrowseContextType;
  const [drawerContent, setDrawerContent] = React.useState<JSX.Element>();
  const [anchorLeft, setAnchor] = React.useState<boolean>(true);

  const closeDrawer = () => {
    setDrawerContent(undefined);
  };

  const onPressFilters = () => {
    setAnchor(false);
    setDrawerContent(
      <Container sx={{ pb: 5, pt: 3 }} maxWidth={'md'}>
        <Box display={'flex'} justifyContent={'flex-end'} mb={2}>
          <CloseIcon cursor={'pointer'} onClick={closeDrawer} />
        </Box>
        <FilterBy />
      </Container>
    );
  };

  const onPressSort = () => {
    setAnchor(true);
    setDrawerContent(
      <Container sx={{ pb: 5, pt: 3 }} maxWidth={'md'}>
        <Box display={'flex'} justifyContent={'flex-end'} mb={2}>
          <CloseIcon cursor={'pointer'} onClick={closeDrawer} />
        </Box>
        <OrderBy />
      </Container>
    );
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent="space-around" flexWrap="wrap" gap={1} mt={2} mb={1}>
        <Button
          startIcon={<FilterListIcon />}
          color="primary"
          variant="outlined"
          sx={{ minWidth: 160, width: { xs: '100%', sm: 'auto' } }}
          onClick={onPressFilters}
        >
          Filter {selectedFiltersCount > 0 && '(' + selectedFiltersCount + ')'}
        </Button>
        <Button
          startIcon={<SwapVertIcon />}
          color="primary"
          variant="outlined"
          sx={{ minWidth: 160, width: { xs: '100%', sm: 'auto' } }}
          onClick={onPressSort}
        >
          Sort
        </Button>
      </Box>
      <Drawer
        open={!!drawerContent}
        anchor={anchorLeft ? 'right' : 'left'}
        onClose={closeDrawer}
        PaperProps={{
          sx: { width: { xs: '100%', sm: 'initial' } }
        }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export { FilterByDrawers };
