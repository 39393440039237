import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { setSentryUserInfo } from 'src/sentryErrorMonitor';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { Loading } from 'src/components/Pages/Loading';
import { useGetMemberQuery, Member } from 'src/graphql/generated';

type CustomOktaUserInfoType = UserClaims<{
  memberid: string;
  cpd_role: string;
  cpd_pilot: string;
}>;

export type AuthContextType = {
  memberid: string | null;
  cpdRole: string | null;
  hasPilotAccess: boolean;
  impersonateMemberid: string | null;
  impersonateFullName: string | null;
  canImpersonate: boolean;
  startImpersonate: (impersonateMemberid: string, impersonateFullName: string) => void;
  endImpersonate: () => void;
  activeMemberId: string | null;
};
const AuthContext = React.createContext<AuthContextType | null>(null);

const AuthContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const handleError = useErrorHandler();
  const [userInfo, setUserInfo] = React.useState<CustomOktaUserInfoType | null>(null);
  const [memberid, setMemberid] = React.useState<string | null>(null);
  const [impersonateMemberid, setImpersonateMemberid] = React.useState<string | null>(null);
  const [impersonateFullName, setImpersonateFullName] = React.useState<string | null>(null);
  const [canImpersonate, setCanImpersonate] = React.useState<boolean>(false);
  const [cpdRole, setCpdRole] = React.useState<string | null>(null);
  const [hasPilotAccess, setHasPilotAccess] = React.useState<boolean>(false);
  const { authState, oktaAuth } = useOktaAuth();

  const isReady = authState !== null;
  const isAuthenticated = isReady && authState.isAuthenticated;

  function startImpersonate(memberidToImpersonate: string, impersonateFullName: string) {
    if (canImpersonate) {
      setImpersonateMemberid(memberidToImpersonate);
      setImpersonateFullName(impersonateFullName);
    }
  }
  function endImpersonate() {
    setImpersonateMemberid(null);
    setImpersonateFullName(null);
  }

  const { search } = useLocation();

  const { memberid: impersonateId } = queryString.parse(search);
  const memberidInput = (impersonateId as string) ?? '';
  const hasValidImpersonateFromUrl = memberidInput?.length > 0 && canImpersonate;

  const { loading: isLoadingImpersonate } = useGetMemberQuery({
    skip: !hasValidImpersonateFromUrl,
    variables: { memberid: memberidInput },
    onCompleted: (data) => {
      const memberData = data?.getMember as Member;
      startImpersonate(memberData?.memberid ?? '0', memberData.full_name ?? '');
    },
    onError: (error) => {
      endImpersonate();
      handleError(error);
    }
  });

  // Effect to get user info from Okta and keep in sync.
  // Use this to pull any additional info from the Okta user info object
  React.useEffect(() => {
    if (!isReady || !isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      if (!userInfo) {
        // user has authenticated and therefore we have accessToken
        console.log('accessToken', authState.accessToken);
        // You can also get user information from the `/userinfo` endpoint
        oktaAuth
          .getUser()
          .then((info) => {
            const racgpUserInfo = info as CustomOktaUserInfoType;
            setUserInfo(racgpUserInfo);
            console.log('userInfo', racgpUserInfo);
          })
          .catch((error) => {
            setUserInfo(null);
            handleError(error, { sentry: true, errorBoundary: false });
          });
      }
    }
  }, [authState?.accessToken, handleError, isAuthenticated, isReady, userInfo, oktaAuth]); // Update if authState changes

  // Effect to keep everything in sync with current UserInfo
  React.useEffect(() => {
    // set app state
    setMemberid(userInfo?.memberid ?? null);
    setCpdRole(userInfo?.cpd_role ?? null);
    const canImpersonate = !!(userInfo && userInfo.cpd_role === 'staff');
    setCanImpersonate(canImpersonate);
    const cpd_pilot = (userInfo?.cpd_pilot ?? '') === 'true';
    setHasPilotAccess(cpd_pilot);
    // set sentry state
    setSentryUserInfo({
      email: userInfo?.email,
      id: userInfo?.memberid,
      username: userInfo?.preferred_username,
      cpd_role: userInfo?.cpd_role
    });

    // effect to add user attributes to data layer
    // @ts-ignore
    if (window.hj) {
      // @ts-ignore
      window.hj('identify', userInfo?.memberid, {
        cpd_role: userInfo?.cpd_role
      });
    }
  }, [userInfo]);

  const authError = authState?.error;
  const displayError = authError;
  if (displayError) {
    return <React.Fragment>Error with Auth: {displayError.message}</React.Fragment>;
  }

  const hasMinimumData = memberid && cpdRole;
  if (!isReady || !isAuthenticated || !hasMinimumData || isLoadingImpersonate) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        memberid: memberid,
        cpdRole: cpdRole,
        hasPilotAccess: hasPilotAccess,
        canImpersonate: canImpersonate,
        impersonateMemberid: impersonateMemberid,
        impersonateFullName: impersonateFullName,
        startImpersonate: startImpersonate,
        endImpersonate: endImpersonate,
        activeMemberId: impersonateMemberid || memberid
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
