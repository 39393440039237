import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import { Tooltip } from './Tooltip';
import { LoadableBox } from '../../Loadable/LoadableBox';

type CPDInputSectionProps = {
  title: string;
  children: ReactNode;
  tooltip: string;
  loading?: boolean;
};

export const CPDTypeInputSection = ({
  title,
  children,
  tooltip,
  loading = false
}: CPDInputSectionProps) => {
  return (
    <Box my={3}>
      <Box display={'flex'} gap={1} alignItems={'center'} mb={2}>
        <Typography variant={'subtitle1'} color={'primary'}>
          {title}
        </Typography>
        <Tooltip tooltip={tooltip} />
      </Box>
      <LoadableBox loading={loading}>{children}</LoadableBox>
    </Box>
  );
};
