import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    bordered: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#11364d',
      light: '#254B65',
      dark: '#254B65'
    },
    secondary: {
      main: '#008074',
      light: '#68e3d5'
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Apple Color Emoji"'
    ].join(','),
    fontSize: 16,
    allVariants: {
      color: '#2d2d2d'
    },
    h1: {
      fontSize: '2.125rem',
      fontWeight: 400,
      lineHeight: 1.333,
      letterSpacing: '0.00625em'
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: 1.6,
      letterSpacing: '0.00625em'
    },
    h3: {
      fontSize: '1.1875rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.00625em'
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.00625em'
    },
    h5: {
      fontSize: '1.0625rem',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.00625em'
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.00625em'
    },
    subtitle1: {
      fontSize: '1rem',
      letterSpacing: '0.0094em',
      lineHeight: 1.5,
      fontWeight: 400
    },
    subtitle2: {
      fontSize: '0.875rem',
      letterSpacing: '0.00625em',
      lineHeight: 1.5,
      fontWeight: 500
    },
    body1: {
      fontSize: '1rem',
      letterSpacing: '0.0094em',
      lineHeight: 1.5,
      fontWeight: 400
    },
    body2: {
      fontSize: '0.875rem',
      letterSpacing: '0.0094em',
      lineHeight: 1.5,
      fontWeight: 400
    },
    caption: {
      fontSize: '0.75rem',
      letterSpacing: '0.0334em',
      lineHeight: 1.334,
      fontWeight: 400
    },
    overline: {
      fontSize: '0.625rem',
      letterSpacing: '0.0334em',
      lineHeight: 1.334,
      fontWeight: 400
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 100,
          borderRadius: '100px',
          textTransform: 'none'
        }
      },
      variants: [
        {
          props: { variant: 'bordered' },
          style: {
            minWidth: 0,
            width: '2.5rem',
            height: '2.5rem',
            borderRadius: '8px',
            border: '1px solid #D2DCE1'
          }
        }
      ]
    }
  }
});
