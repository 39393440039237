import { Get } from 'type-fest';
import { Box, Divider, Typography } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tile } from 'src/components/Surface/Title';
import { GetRequirementsSummaryQuery } from 'src/graphql/generated';

import { getCategoryCodeAccentColor } from '../functions/category-group-colors';
import { getDetailsForCategoryGroup } from '../functions/requirement-details';
import { LinearProgress } from './LinearProgress';
import { HourBasedCategoryRequirements } from '../components/HourBasedCategoryRequirements';

export const getTitleForCategoryCode = (categoryCode?: string) => {
  switch (categoryCode) {
    case 'EA-BLA':
      return `Educational Activities (EA)`;
    case 'RPMO':
      return `Reviewing Performance & Measuring Outcomes (RP & MO)`;
    case 'RPMOEU':
      return `Any CPD Type (EA, RP and MO)`;
    case 'BLS':
      return `CPR Requirement`;
    default:
      return null;
  }
};

type CPDHourRequirementDetailsProps = {
  categoryGroupRequirement?: Get<
    GetRequirementsSummaryQuery,
    ['getRequirementsSummary', 'requirements', '0', 'category_group_requirements', '0']
  >;
  showCategoryRequirements?: boolean;
  onSelect?: () => void;
};

const MIN_LINEAR_PROGRESS_AMOUNT = 2;

export const CPDHourRequirementDetails = ({
  categoryGroupRequirement,
  showCategoryRequirements
}: CPDHourRequirementDetailsProps) => {
  const { hours, minimum, completed, category_requirements } = categoryGroupRequirement || {};
  return (
    <>
      {categoryGroupRequirement?.category_group_code !== 'BLS' && (
        <Tile sx={{ mt: 2 }}>
          <Typography mb={1} variant={'body2'} fontWeight={600}>
            Current Progress
          </Typography>
          {hours != null && minimum != null && (
            <LinearProgress
              color={getCategoryCodeAccentColor(categoryGroupRequirement?.category_group_code)}
              value={completed ? 100 : (hours / minimum) * 100 || MIN_LINEAR_PROGRESS_AMOUNT}
            />
          )}

          <Box mt={1} display={'flex'} justifyContent={'space-between'} alignItems="center">
            <Box>
              {completed ? (
                <Typography
                  variant={'caption'}
                  fontWeight={600}
                  sx={{ alignItems: 'center', display: 'flex' }}
                >
                  <CheckCircleIcon color="secondary" sx={{ mr: 0.5, fontSize: '16px' }} /> Completed
                </Typography>
              ) : (
                <Typography variant={'caption'}>
                  {hours != null && minimum != null && minimum - hours}
                  &nbsp; hours remaining
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant={'caption'} display="inline-flex" whiteSpace="pre-wrap">
                <Box fontWeight={600}>{hours}</Box>
                {' / '}
                {minimum} hours
              </Typography>
            </Box>
          </Box>

          {showCategoryRequirements && category_requirements && (
            <>
              <Box></Box>
              <Box>
                <Box mt={1}>
                  <HourBasedCategoryRequirements
                    isInDrawer={true}
                    categoryRequirements={category_requirements}
                  />
                </Box>
              </Box>
            </>
          )}
        </Tile>
      )}
      <Box my={3} width={'100%'}>
        <Typography mb={0.5} variant={'h2'} sx={{ color: 'primary.main' }}>
          {categoryGroupRequirement?.category_group_code === 'BLS'
            ? 'Complete by 31 December 2025'
            : 'What you need to do annually'}
        </Typography>
        {getDetailsForCategoryGroup(categoryGroupRequirement?.category_group_code)?.whatToDo}
      </Box>
      <Box mt={3}>
        {getDetailsForCategoryGroup(categoryGroupRequirement?.category_group_code)?.description}
      </Box>
      <Box mt={2}>
        {getDetailsForCategoryGroup(categoryGroupRequirement?.category_group_code)?.activityGuide}
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box>
        {getDetailsForCategoryGroup(categoryGroupRequirement?.category_group_code)?.quickLinks}
      </Box>
    </>
  );
};
