import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { MultiColumnCTAButtonCard } from 'src/components/ContentPageCards/MultiColumnCTAButtonCard';

const portalURL = import.meta.env.VITE_PORTAL_URL;

const GuidesAndTemplatesPage = () => {
  return (
    <>
      <ContentPageTitle currentPageTitle="Guides and Templates" />

      <Container maxWidth="lg">
        <Typography color="primary.main" variant="body1" sx={{ mt: 3 }}>
          The RACGP has developed CPD activity guides and templates to support you in meeting your
          CPD program requirements.
        </Typography>
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="Evidence-based medicine journal club"
              description="A CPD activity that enables GPs to discuss and solve clinical questions that arise in their day to day practice in a peer supported environment using evidence based resources."
              tags={['Educational Activities', 'Reviewing Performance']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      'getmedia/7b4adf3c-c1d0-4f24-907f-aad64804e9b5/2023-25-Evidence-Based-Medicine-Journal-Club-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      'getmedia/a489faf9-766d-45e5-888a-fef351aae0ab/2023-25-Evidence-Based-Medicine-Journal-Club-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="General practice research"
              description="A CPD activity that provides GPs with an opportunity to participate (in) or conduct a relevant general practice research that focuses on research skills development, reflective and critical thinking."
              tags={['Educational Activities', 'Measuring Outcomes']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      'getmedia/f63f3d5a-7fee-4095-bff1-5daac2a1d002/2023-25-GP-Research-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      'getmedia/143f5da7-4019-4844-b7f2-db862df47b58/2023-25-GP-Research-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="Mini audit and audit"
              description="A CPD activity that systematically reviews aspects of GPs’ clinical or practice performance and/or data against defined best-practice guidelines or standards.

              "
              tags={['Measuring Outcomes']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      'getmedia/8091ec34-0b0a-43b5-89e5-ac84c3e3e53f/2023-25-Mini-audit-and-audit-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      'getmedia/1e43c45f-4b1c-437e-bc6b-c38303197b4a/2023-25-Mini-audit-and-audit-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="Peer-group learning"
              description="A CPD activity that utilises peer support, interaction and reflection through cased based discussions to enhance participants’ clinical competence, knowledge, skills, attitudes and performance."
              tags={['Educational Activities', 'Reviewing Performance']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      'getmedia/bd8ab09b-e938-4f18-93b3-2501e20dfea0/2023-25-Peer-Group-Learning-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      'getmedia/5910041e-9ad2-47ab-87c2-d37755282f06/2023-25-Peer-Group-Learning-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>

          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="Peer-reviewed journal article"
              description="A CPD activity for a published written article or an article accepted for publication after going through a peer review process."
              tags={['Educational Activities', 'Reviewing Performance']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      'getmedia/74eadca2-223a-4f87-a07c-9575676809c0/2023-25-Peer-Reviewed-Journal-Article-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      'getmedia/d91e8e87-c712-42c7-a1c5-f0e754d47406/2023-25-Peer-Reviewed-Journal-Article-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="Plan do study act"
              description="A CPD activity that focuses on improving the capability of the practice to deliver on quality patient care by systematically implementing change into manageable parts."
              tags={['Measuring Outcomes']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      'getmedia/3f695724-149a-4aae-a0a6-18aae80dfdb9/2023-25-Plan-Do-Study-Act-Activity-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      'getmedia/5a6b7137-2895-41b1-8bcb-9b2f9e57654f/2023-25-Plan-Do-Study-Act-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="Practice meeting"
              description="A Practice Meeting activity is where GPs along with other health professionals and practice staff share their knowledge and skills, discuss and reflect on their daily practice."
              tags={['Educational Activities', 'Reviewing Performance', 'Measuring Outcomes']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      '/getmedia/b2f2509a-7910-412a-8c60-1c8a1ff9c2b1/2023-25-Practice-meeting-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      '/getmedia/01e656de-d913-47ba-8a0b-e0f2e9d8aab3/2023-25-Practice-meeting-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="Random case analysis"
              description="A CPD activity that uses a specific method of case note review, using a random selection of cases, which has proven useful in identifying blind spots or ‘unknown unknowns’ in a clinician’s skills or knowledge."
              tags={['Educational Activities', 'Reviewing Performance']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      'getmedia/1401be3a-57ef-4c0e-9403-16dd61ae4f60/2023-25-Random-Case-Analysis-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      'getmedia/963b1ef5-6b1e-4b2d-8771-92148c73cf5b/2023-25-Random-Case-Analysis-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTAButtonCard
              heading="Supervised clinical attachment"
              description="A CPD activity designed as a period of attachment with a health professional (supervisor) who is an expert in a specific area to provide an individualised and hands-on learning experience using real cases."
              tags={['Reviewing Performance']}
              buttons={[
                {
                  cta: 'Download Guide',
                  link: {
                    url:
                      portalURL +
                      'getmedia/daa506ae-fd28-4318-9d68-7c03277bc6cd/2023-25-Supervised-Clinical-Attachment-activity-guide.pdf.aspx',
                    target: '_blank'
                  }
                },
                {
                  cta: 'Download Template',
                  link: {
                    url:
                      portalURL +
                      'getmedia/47c82c4f-ba45-4c87-b536-7f12ee1c2bb2/2023-25-Supervised-Clinical-Attachment-activity-template.docx.aspx',
                    target: '_blank'
                  }
                }
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export { GuidesAndTemplatesPage };
