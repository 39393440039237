import React from 'react';
import { Box, Typography, Paper, useTheme, Button } from '@mui/material';

interface Props {
  heading?: string;
  description: string;
  image?: { url: string; styles: {} };
  button: {
    cta: string;
    link: { url: string | undefined; target: string };
  };
}

const FullWidthCTAButtonCard: React.FC<Props> = (props) => {
  var link = '';
  if (props.button.link.url) {
    link = props.button.link.url;
  }
  const theme = useTheme();
  return (
    <Paper sx={{ p: 3, mt: 3, borderTop: '8px solid ' + theme.palette.secondary.main }}>
      <Box sx={{ display: { md: 'flex' }, justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ width: { md: '75%' } }}>
          {props.image && (
            <img src={props.image.url} alt="logo" style={{ ...props.image.styles }} />
          )}
          {props.heading && (
            <Typography
              color="primary.main"
              variant="body1"
              component={'h3'}
              sx={{ fontWeight: 600 }}
            >
              {props.heading}
            </Typography>
          )}
          <Typography sx={{ mt: 1 }} variant="body2">
            {props.description}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          href={link}
          target={props.button.link.target}
          sx={{
            flexGrow: 0,
            width: { xs: '100%', md: 'initial' },
            maxHeight: '40px',
            mt: { xs: 3, md: 0 }
          }}
        >
          {props.button.cta}
        </Button>
      </Box>
    </Paper>
  );
};

export { FullWidthCTAButtonCard };
