import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TooltipContent = () => {
  return (
    <Tooltip title="If you have an additional fellowship/s from an AMC-accredited specialist medical college that leads to specialist registration, you may be required to complete their specialist high-level CPD requirement/s. Select Add Dual Fellowship to explore and manage additional specialist high-level requirements.">
      <InfoOutlinedIcon fontSize="small" sx={{ ml: 2 }} />
    </Tooltip>
  );
};
export { TooltipContent };
