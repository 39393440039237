import { useContext, useState } from 'react';
import {
  Container,
  Alert,
  AlertTitle,
  Typography,
  Grid,
  Button,
  Box,
  TextField
} from '@mui/material';

import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { useGetMemberLazyQuery, Member } from 'src/graphql/generated';

const Impersonate = () => {
  const {
    canImpersonate,
    impersonateMemberid,
    impersonateFullName,
    startImpersonate,
    endImpersonate
  } = useContext(AuthContext) as AuthContextType;
  const [memberidInput, setMemberidInput] = useState<string>(impersonateMemberid ?? '');
  const isImpersonating = !(impersonateMemberid && impersonateMemberid.length > 0);

  const [getMemberLazy, { error, loading }] = useGetMemberLazyQuery({
    onError: endImpersonate
  });

  const getNowImpersonatingSummary = () => {
    let nowImpersonatingSummary = '-';
    if (loading) {
      nowImpersonatingSummary = `Loading...`;
    } else {
      nowImpersonatingSummary = `${impersonateMemberid} (${impersonateFullName})`;
    }
    return nowImpersonatingSummary;
  };

  function handleSubmit() {
    getMemberLazy({
      variables: { memberid: memberidInput },
      onCompleted: (data) => {
        const memberData = data?.getMember as Member;
        startImpersonate(memberData?.memberid ?? '0', memberData.full_name ?? '');
      }
    });
  }
  function handleCancel() {
    setMemberidInput('');
    endImpersonate();
  }
  function handleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setMemberidInput(event?.currentTarget.value);
  }

  if (!canImpersonate) {
    return null;
  }

  return (
    <Container
      maxWidth={'lg'}
      sx={{
        textAlign: 'start',
        marginY: 2
      }}
    >
      <Alert severity="info">
        <AlertTitle>
          <Typography variant="h3">Impersonate iMIS user</Typography>
        </AlertTitle>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <TextField
              variant="standard"
              label="iMIS ID"
              value={memberidInput}
              onChange={handleChange}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 6,
                autoFocus: isImpersonating,
                onKeyPress: (event) => event.key === 'Enter' && handleSubmit()
              }}
              onKeyPress={(event) => event.key === 'Enter' && handleSubmit()}
            />
          </Grid>
          <Grid item style={{ width: 'auto' }}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item>
                <Button color="primary" variant="contained" onClick={handleSubmit}>
                  Impersonate
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {error && (
          <Box mt={2}>
            <Typography variant="body1" color="error" style={{ marginBottom: 0 }}>
              {error?.message}
            </Typography>
          </Box>
        )}

        {impersonateMemberid && impersonateMemberid.length > 0 && (
          <Box mt={2}>
            <Typography variant="body1" style={{ marginBottom: 0 }}>
              Now impersonating:
              <span style={{ fontWeight: 'bold' }}> {getNowImpersonatingSummary()}</span>
            </Typography>
          </Box>
        )}
      </Alert>
    </Container>
  );
};

export { Impersonate };
