import { Box, Typography, Divider } from '@mui/material';
import { CompletedCheckmark } from './CompletedCheckmark';
import { isNil } from 'lodash';
import { LoadableText } from 'src/components/Loadable/LoadableText';
import { HoursRemaining } from './HoursRemaining';
import { getRequirementLabel } from '../functions/card-titles';
import { colors } from 'src/components/App/colors';
import { CategoryRequirements } from '../types';

type HourBasedCategoryRequirementsProps = {
  categoryRequirements: CategoryRequirements;
  expectedLength?: number;
  dividerStyles?: {};
  isInDrawer?: Boolean;
};

export const HourBasedCategoryRequirements = ({
  categoryRequirements,
  dividerStyles,
  isInDrawer
}: HourBasedCategoryRequirementsProps) => {
  const min = categoryRequirements?.[0]?.minimum;
  if (isNil(min)) {
    return null;
  }
  return (
    <Box>
      <Divider sx={{ my: 1 }} style={{ ...dividerStyles }} />
      <LoadableText loading={!categoryRequirements} variant={'caption'} fontWeight={600}>
        Complete {min} hours minimum for RP and {min} hours minimum for MO
      </LoadableText>
      {categoryRequirements?.map((categoryRequirement) => {
        if (!categoryRequirement) {
          return null;
        }

        return (
          <Box
            key={categoryRequirement.category_code}
            display={'flex'}
            flexDirection={'row'}
            mt={isInDrawer ? 0 : 1}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              {categoryRequirement.completed && (
                <Box mr={1}>
                  <CompletedCheckmark
                    size={'small'}
                    iconStyle={{ fontSize: '16px' }}
                    completed={true}
                  />
                </Box>
              )}
              <Typography sx={{ color: colors['charcoal.700'] }} variant={'caption'}>
                {getRequirementLabel(categoryRequirement.category_code)}
              </Typography>
            </Box>
            <HoursRemaining
              hours={categoryRequirement.hours}
              completed={categoryRequirement.completed}
              minimum={categoryRequirement.minimum}
            />
          </Box>
        );
      })}
    </Box>
  );
};
