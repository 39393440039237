import find from 'lodash/find';
import { GetRequirementsSummaryQuery } from 'src/graphql/generated';

export const extractRequirements = (
  requirementsSummary: GetRequirementsSummaryQuery | undefined
) => {
  const requirementMBA = find(
    requirementsSummary?.getRequirementsSummary?.requirements,
    (e: any) => e.requirement_code === 'MBA'
  );

  const requirementRACGP = find(
    requirementsSummary?.getRequirementsSummary?.requirements,
    (e: any) => e.requirement_code === 'RACGP'
  );

  const categoryGroupEA = find(
    requirementMBA?.category_group_requirements,
    (categoryGroup: any) => categoryGroup.category_group_code === 'EA-BLA'
  );

  const categoryGroupRPMO = find(
    requirementMBA?.category_group_requirements,
    (categoryGroup: any) => categoryGroup.category_group_code === 'RPMO'
  );

  const categoryGroupRPMOEU = find(
    requirementMBA?.category_group_requirements,
    (categoryGroup: any) => categoryGroup.category_group_code === 'RPMOEU'
  );

  const categoryGroupPDP = find(
    requirementMBA?.category_group_requirements,
    (categoryGroup: any) => categoryGroup.category_group_code === 'PDP'
  );

  const categoryGroupBLS = find(
    requirementRACGP?.category_group_requirements,
    (categoryGroup: any) => categoryGroup.category_group_code === 'BLS'
  );

  const categoryGroupPLR = find(
    requirementMBA?.category_group_requirements,
    (categoryGroup: any) => categoryGroup.category_group_code === 'PLR'
  );

  return {
    requirementMBA,
    requirementRACGP,
    categoryGroupEA,
    categoryGroupPDP,
    categoryGroupBLS,
    categoryGroupRPMO,
    categoryGroupRPMOEU,
    categoryGroupPLR
  };
};
