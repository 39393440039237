import { Skeleton, SkeletonProps, Typography, TypographyProps } from '@mui/material';

type LoadableTextProps = TypographyProps & {
  loading: boolean;
  skeletonProps?: SkeletonProps;
};

export const LoadableText = ({ loading, skeletonProps, ...rest }: LoadableTextProps) => {
  return (
    <>
      {loading ? (
        <Skeleton {...skeletonProps} variant={'text'} />
      ) : (
        <Typography {...rest}></Typography>
      )}
    </>
  );
};
