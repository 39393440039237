import React, { useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material';

import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';

const SORT_BY_OPTIONS = [
  { name: 'Relevance', value: 'score' },
  { name: 'Newest', value: 'ApprovedDate' },
  { name: 'CPD hours (ascending)', value: 'HoursAscending' },
  { name: 'CPD hours (descending)', value: 'HoursDescending' }
];

const OrderBy = () => {
  const { orderBy, setOrderBy } = useContext(BrowseContext) as BrowseContextType;

  const handleChange = (event: SelectChangeEvent) => {
    setOrderBy(event.target.value);
  };

  return (
    <Box display="flex" alignItems="baseline">
      <InputLabel variant="standard" htmlFor="orderby-select" id="orderby-label">
        <Typography variant="body2" fontWeight="600" mr={2}>
          Sort by
        </Typography>
      </InputLabel>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="orderby-label"
          value={orderBy}
          onChange={handleChange}
          autoWidth
          MenuProps={{
            keepMounted: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }}
          SelectDisplayProps={{
            id: 'orderby-select'
          }}
          sx={{
            gridArea: 'cycle-selector',
            justifySelf: 'flex-end',
            backgroundColor: '#fff',
            minWidth: 222
          }}
        >
          {SORT_BY_OPTIONS.map((option) => {
            return (
              <MenuItem value={option.value} key={option.value}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export { OrderBy };
