import React, { useContext, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { HistoryContext, HistoryContextType } from 'src/contexts/HistoryContext';
import { Attachment, useGetMemberActivityByKeyQuery } from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { ActivityActionsProps } from '../types';
import { ActionMenuItem } from './ActionMenuItem';

type ProviderNonRACGPActionsProps = ActivityActionsProps & {
  onClose: () => void;
};

export const ProviderNonRACGPActions = ({ activity, onClose }: ProviderNonRACGPActionsProps) => {
  const activitykeyUrlEncoded = encodeURIComponent(activity?.cpd_id ?? 'null');
  const {
    downloadExternalAttendanceCertificate,
    uploadAttendanceCertificate,
    deleteMemberAttachmentsMutation
  } = useContext(HistoryContext) as HistoryContextType;
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const { data } = useGetMemberActivityByKeyQuery({
    fetchPolicy: 'network-only',
    variables: { memberid: activeMemberId, activitykey: activity?.activitykey }
  });
  const inputRef = useRef<HTMLInputElement | null>(null);

  const hasAttachment =
    data?.getMemberActivityByKey?.attachments && data.getMemberActivityByKey.attachments.length > 0;

  const onPressDelete = () => {
    const [deleteMemberAttachments] = deleteMemberAttachmentsMutation;
    deleteMemberAttachments({
      variables: {
        memberid: activeMemberId,
        activitykey: activity?.activitykey,
        attachments: (data?.getMemberActivityByKey?.attachments as Array<Attachment>)?.map(
          (attachment) => ({
            attachment_name: attachment?.attachment_name,
            attachment_type: attachment?.attachment_type,
            retrieval_key: attachment?.retrieval_key
          })
        )
      }
    });
    onClose();
  };

  return (
    <>
      <input
        ref={inputRef}
        type={'file'}
        style={{ display: 'none' }}
        onChange={(event) => {
          if (activity?.activitykey && event?.currentTarget?.files?.[0]) {
            uploadAttendanceCertificate(activity.activitykey, event?.currentTarget?.files?.[0]);
          }
          onClose();
        }}
      />
      <NavLink
        to={{
          pathname: '/activity/' + activitykeyUrlEncoded,
          state: { backTo: '/history' }
        }}
      >
        <ActionMenuItem title={'View'} icon={'view'} />
      </NavLink>

      {!hasAttachment ? (
        <ActionMenuItem
          icon={'upload'}
          title={'Upload Attendance Certificate'}
          onClick={() => {
            inputRef.current?.click();
          }}
        />
      ) : (
        <>
          <ActionMenuItem
            icon={'download'}
            title={'Download Attendance Certificate'}
            onClick={() => {
              if (activity?.activitykey && data?.getMemberActivityByKey?.attachments?.[0]) {
                downloadExternalAttendanceCertificate(data?.getMemberActivityByKey?.attachments[0]);
              }
              onClose();
            }}
          />

          <ActionMenuItem
            onClick={onPressDelete}
            icon={'delete'}
            title={'Delete Attendance Certificate'}
          />
        </>
      )}
    </>
  );
};
