import { Box, BoxProps, Skeleton, SkeletonProps } from '@mui/material';

type LoadableTextProps = BoxProps & {
  loading: boolean;
  skeletonProps?: SkeletonProps;
};

export const LoadableBox = ({ loading, skeletonProps, children, ...rest }: LoadableTextProps) => {
  return (
    <Box {...rest}>
      {loading ? <Skeleton {...skeletonProps} variant={'rectangular'} /> : children}
    </Box>
  );
};
