import { SaveQuickLogActivityMutation } from '../../../graphql/generated';
import { SuccessAlert } from './SuccessAlert';
import { ApolloError } from '@apollo/client';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';

type AlertsProps = {
  submissionResponse: SaveQuickLogActivityMutation | null | undefined;
  onDismissSuccessfulSubmission: () => void;
  submissionError?: string | ApolloError | undefined;
  activityPending?: boolean;
  activityError?: ApolloError;
  deleteError?: ApolloError;
};

export const Alerts = ({
  submissionResponse,
  onDismissSuccessfulSubmission,
  submissionError,
  activityPending,
  activityError,
  deleteError
}: AlertsProps) => {
  if (submissionResponse) {
    return (
      <SuccessAlert
        onClickCancel={onDismissSuccessfulSubmission}
        onClickViewInHistory={() => {}}
        activityTitle={submissionResponse.saveQuickLogActivity?.title}
      ></SuccessAlert>
    );
  }
  if (submissionError) {
    return (
      <Box my={2}>
        <Alert severity="error">
          <AlertTitle>
            {typeof submissionError === 'string' ? submissionError : submissionError.message}
          </AlertTitle>
        </Alert>
      </Box>
    );
  }
  if (activityError) {
    return (
      <Box my={2}>
        <Alert severity="error">
          <AlertTitle>{activityError.message}</AlertTitle>
        </Alert>
      </Box>
    );
  }
  if (activityPending) {
    return (
      <Box my={2}>
        <Alert severity="warning">
          <AlertTitle>Your Quick log submission is being processed.</AlertTitle>
          <Typography variant={'body2'}>You will be able to update upon completion.</Typography>
        </Alert>
      </Box>
    );
  }
  if (deleteError) {
    return (
      <Box my={2}>
        <Alert severity="error">
          <AlertTitle>{deleteError.message}</AlertTitle>
        </Alert>
      </Box>
    );
  }
  return null;
};
