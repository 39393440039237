import React from 'react';
import { Box, Chip, useTheme } from '@mui/material';
import { SearchActivityItem, CpdActivityRequirement } from 'src/graphql/generated';
export type Maybe<T> = T | null;

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
};

const Requirements: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box>
      {props?.searchActivity?.activity?.requirements?.map(
        (requirement: Maybe<CpdActivityRequirement>, index) => {
          return (
            <Chip
              key={index}
              label={requirement?.name}
              size="small"
              sx={{ ...theme.typography.body2, mt: 1, mr: 1 }}
            />
          );
        }
      )}
    </Box>
  );
};

export { Requirements };
