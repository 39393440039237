import { AlertTitle, Box, Button, Typography } from '@mui/material';
import { colors } from 'src/components/App/colors';
import { InlineBox } from '../../Layout/InlineBox';
import { SUCCESS_ALERT_ACTIVITY_TITLE_MAX_LENGTH } from 'src/components/Attachments/constants';
import { NavLink } from 'react-router-dom';
import { truncate } from 'src/components/Attachments/utils';

type SuccessAlertProps = {
  onClickCancel: () => void;
  activityTitle: string | null | undefined;
};

export const SuccessAlert = ({ onClickCancel, activityTitle }: SuccessAlertProps) => {
  return (
    <Box sx={{ my: 2, p: 3, pb: 2, bgcolor: colors['green.50'] }}>
      <Box width={'100%'} flexGrow={1}>
        <AlertTitle>
          <Typography component={'span'} whiteSpace={'pre-wrap'} variant={'body1'}>
            <InlineBox fontWeight={600}>Dual Fellowship Requirement submitted:{'\t'}</InlineBox> `
            {activityTitle ? truncate(activityTitle, SUCCESS_ALERT_ACTIVITY_TITLE_MAX_LENGTH) : ''}`
          </Typography>
        </AlertTitle>
        <Typography width={'100%'} variant={'body2'}>
          Your Dual Fellowship Requirement submission is being processed and will appear in your CPD
          statement within 5 minutes. You will be able to update upon completion.
        </Typography>
        <Box display={'flex'} mt={2} justifyContent={'flex-end'} width={'100%'}>
          <Button onClick={onClickCancel} variant={'text'}>
            Close
          </Button>
          <Button variant={'text'}>
            <NavLink to={'/history'}>View In History</NavLink>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
