import { Checkbox, CheckboxProps } from '@mui/material';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import { colors } from 'src/components/App/colors';

type CheckmarkProps = CheckboxProps & {
  completed: boolean;
  iconStyle?: {};
};

export const CompletedCheckmark = ({ completed, style, iconStyle, ...rest }: CheckmarkProps) => {
  return (
    <Checkbox
      checked={completed}
      icon={<CircleUnchecked />}
      checkedIcon={<CircleCheckedFilled sx={{ ...iconStyle }} />}
      style={{
        color: completed ? colors['darkTeal.800'] : colors['stone.100'],
        padding: 0,
        ...style
      }}
      {...rest}
    ></Checkbox>
  );
};
