import React, { useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { FilterByFormControlLabel } from '../../Layout/FilterByCheckBox';
import { FacetCount } from './FacetCount';
import { FACET_NAMES } from './constants';

const stateList = [
  { label: 'ACT', value: 'ACT', summary: 'Australian Capital Territory' },
  { label: 'NSW', value: 'NSW', summary: 'New South Wales' },
  { label: 'NT', value: 'NT', summary: 'Northern Territory' },
  { label: 'QLD', value: 'QLD', summary: 'Queensland' },
  { label: 'SA', value: 'SA', summary: 'South Australia' },
  { label: 'TAS', value: 'TAS', summary: 'Tasmania' },
  { label: 'VIC', value: 'VIC', summary: 'Victoria' },
  { label: 'WA', value: 'WA', summary: 'Western Australia' },
  { label: 'Nationwide', value: 'Nationwide', summary: 'Nationwide' }
];

const FilterByState = () => {
  const { selectedStates, setSelectedStates, setLastChangeFacet } = useContext(
    BrowseContext
  ) as BrowseContextType;

  const handleChange = (event: any) => {
    const isChecked = event.target.checked;
    setLastChangeFacet(FACET_NAMES.SessionsState);
    if (isChecked) {
      setSelectedStates([...selectedStates, event.target.value]);
    } else {
      const newSelectedStates = selectedStates.filter((value) => value !== event.target.value);
      setSelectedStates(newSelectedStates);
    }
  };

  const isChecked = (value: string): boolean | undefined => {
    return selectedStates.includes(value);
  };

  return (
    <Box>
      <Accordion elevation={0} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="state-content"
          id="state-header"
          sx={{ minHeight: 'initial' }}
        >
          <Typography variant="subtitle2" color="primary">
            State {selectedStates.length > 0 && '(' + selectedStates.length + ')'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <FormGroup>
            {stateList.map((state) => (
              <FilterByFormControlLabel
                key={state.value}
                sx={{ width: '100%' }}
                control={
                  <Checkbox
                    onChange={handleChange}
                    value={state.value}
                    checked={isChecked(state.value)}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box>{state.label}</Box>
                    <FacetCount
                      facetName={FACET_NAMES.SessionsState}
                      facetValueString={state.value}
                    />
                  </Box>
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { FilterByState };
