import { ReactNode } from 'react';
import { Paper, PaperProps } from '@mui/material';
import { colors } from '../App/colors';

export type TileProps = PaperProps & {
  children: ReactNode;
};

export const Tile = ({ children, sx, ...rest }: TileProps) => {
  return (
    <Paper
      variant={'outlined'}
      sx={{ p: 2, borderColor: colors['stone.100'], borderRadius: '8px', ...sx }}
      {...rest}
    >
      {children}
    </Paper>
  );
};
