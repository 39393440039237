import React, { useContext, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { FACET_NAMES } from './constants';
type FacetCountProps = {
  facetName: string;
  facetValueString: string;
};

export const FacetCount = ({ facetName, facetValueString }: FacetCountProps) => {
  const [facetCount, setFacetCount] = React.useState<number>(1);
  const { searchData, lastChangeFacet } = useContext(BrowseContext) as BrowseContextType;

  const getFacetCount = useCallback(
    (facetName: string, facetValueString: string): number => {
      if (lastChangeFacet === facetName && lastChangeFacet !== FACET_NAMES.SpecificRequirements) {
        return facetCount; // use the current value
      }

      const facet = searchData?.searchActivity?.facets?.find((facet) => facet?.name === facetName);
      const facetValue = facet?.values?.find((facet) => facet?.value === facetValueString);
      const matchingfacetCount = facetValue?.count ?? 0;
      return matchingfacetCount;
    },
    [facetCount, lastChangeFacet, searchData?.searchActivity?.facets]
  );

  React.useEffect(() => {
    const facetCountTemp = getFacetCount(facetName, facetValueString);

    setFacetCount(facetCountTemp);
  }, [facetName, facetValueString, getFacetCount]);

  const hasFacetCount = facetCount && facetCount > 0;
  if (!hasFacetCount) return null;

  return (
    <Box sx={{ marginLeft: 'auto', marginRight: '4px', alignSelf: 'baseline' }}>
      <Typography variant="body2">{facetCount}</Typography>
    </Box>
  );
};
