import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ProvidersData } from './ProvidersData';
export interface TierProps {
  tierType?: 'Tier3' | 'Tier2' | 'Tier1';
}
const ProvidersList = ({ tierType }: TierProps) => {
  const sortedProviderListForTier = ProvidersData.filter(
    (provider) => provider.tier === tierType
  ).sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });
  return (
    <Grid container alignItems="stretch" spacing={2} rowSpacing={3} sx={{ mb: 3 }}>
      {sortedProviderListForTier.map((providerData, index) => (
        <Grid
          key={index}
          item
          xs={12}
          sm={(tierType === 'Tier2' && 6) || (tierType === 'Tier1' && 6)}
          md={
            (tierType === 'Tier3' && 6) ||
            (tierType === 'Tier2' && 4) ||
            (tierType === 'Tier1' && 3)
          }
        >
          <Paper
            sx={{
              p: 3,
              width: '100%',
              display: 'flex',
              height: '100%',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                mb: 2,
                img: {
                  maxWidth: '100%',
                  maxHeight: '40px',
                  '&:nth-of-type(2)': {
                    marginLeft: 3
                  }
                }
              }}
            >
              {providerData.providerLogoUrlList.map((providerLogo, index) => (
                <img
                  key={index}
                  src={providerLogo.logo}
                  loading="lazy"
                  alt={providerLogo.logoTitle}
                />
              ))}
            </Box>
            <Typography variant="body1" sx={{ fontWeight: 600, color: 'primary.main', mb: 1 }}>
              {providerData.name}
            </Typography>
            <Typography variant="body2">{providerData.description}</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 'auto',
                paddingTop: 2,
                color: 'primary.main',
                fontWeight: '500',
                a: {
                  display: 'flex',
                  '&:nth-of-type(2)': {
                    marginLeft: 3
                  }
                }
              }}
            >
              {providerData.providerLogoUrlList.map((providerUrl) => {
                const hasLink = !!providerUrl.link && !!providerUrl.linkText;
                if (!hasLink) return;
                return (
                  <a href={providerUrl.link} target="_blank" rel="noreferrer">
                    {providerUrl.linkText} <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
                  </a>
                );
              })}
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
export { ProvidersList };
