import React from 'react';
import { Box, Typography, Paper, useTheme, Button, Grid, Chip } from '@mui/material';

interface Props {
  heading: string;
  description: string;
  buttons: {
    cta: string;
    link: { url: string; target: string };
  }[];

  tags?: string[];
}

const MultiColumnCTAButtonCard: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        p: 3,
        mt: 3,
        borderTop: '8px solid ' + theme.palette.secondary.main
      }}
    >
      {props.tags && (
        <Box sx={{ mb: 2 }}>
          {props.tags.map((tag: any, index) => {
            return <Chip key={index} label={tag} size="small" sx={{ mt: 1, mr: 1 }} />;
          })}
        </Box>
      )}

      <Typography
        color="primary.main"
        variant="body1"
        component={'h3'}
        sx={{ mb: 1, fontWeight: 600 }}
      >
        {props.heading}
      </Typography>
      <Typography sx={{ mb: 3, flexGrow: 1 }} variant="body2">
        {props.description}
      </Typography>
      <Grid container spacing={2}>
        {props.buttons.map((button: any) => {
          return (
            <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
              <Button
                variant="outlined"
                href={button.link.url}
                target={button.link.target}
                sx={{
                  flexGrow: 0,
                  width: '100%',
                  maxHeight: '40px'
                }}
              >
                {button.cta}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

export { MultiColumnCTAButtonCard };
