import { useErrorHandler as useBoundaryErrorHandler } from 'react-error-boundary';
import { captureException } from '@sentry/react';

type Options = {
  errorBoundary?: boolean;
  sentry?: boolean;
};

export const useErrorHandler = () => {
  const propagateToErrorBoundary = useBoundaryErrorHandler();

  const logToSentry = (error: unknown) => {
    captureException(error);
  };

  const handleError = (
    error?: unknown,
    { errorBoundary = true, sentry = true }: Options = { errorBoundary: true, sentry: true }
  ) => {
    if (errorBoundary) {
      propagateToErrorBoundary(error);
    }

    if (sentry) {
      logToSentry(error);
    }
  };

  return handleError;
};
