import React, { useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Checkbox
} from '@mui/material';
import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FilterByFormControlLabel } from '../../Layout/FilterByCheckBox';
import { FacetCount } from './FacetCount';
import { FACET_NAMES, FACET_VALUES_HOURS_GROUP } from './constants';

const FilterByDuration = () => {
  const { duration, setDuration, setLastChangeFacet } = useContext(
    BrowseContext
  ) as BrowseContextType;

  const handleChange = (event: any) => {
    const isChecked = event.target.checked;
    setLastChangeFacet(FACET_NAMES.HoursGroup);
    if (isChecked) {
      setDuration([...duration, event.target.value]);
    } else {
      const newDuration = duration.filter((value) => value !== event.target.value);
      setDuration(newDuration);
    }
  };

  const isChecked = (value: string) => {
    return duration.includes(value);
  };

  return (
    <Box>
      <Accordion elevation={0} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="duration-content"
          id="duration-header"
          sx={{ minHeight: 'initial' }}
        >
          <Typography variant="subtitle2" color="primary">
            Duration {duration.length > 0 && '(' + duration.length + ')'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <FormGroup>
            {FACET_VALUES_HOURS_GROUP.map((duration) => (
              <FilterByFormControlLabel
                key={duration.value}
                sx={{ width: '100%' }}
                control={
                  <Checkbox
                    checked={isChecked(duration.value)}
                    onChange={handleChange}
                    value={duration.value}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box>{duration.label}</Box>{' '}
                    <FacetCount
                      facetName={FACET_NAMES.HoursGroup}
                      facetValueString={duration.value}
                    />
                  </Box>
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { FilterByDuration };
