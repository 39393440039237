import React from 'react';
import { Box, CardActionArea } from '@mui/material';
import { colors } from 'src/components/App/colors';
import { LoadableText } from 'src/components/Loadable/LoadableText';
import { getCardTitleForCategoryCode } from '../functions/card-titles';
import { CompletedCheckmark } from '../components/CompletedCheckmark';
import { CategoryGroupRequirement } from '../types';
import { NavLink } from 'react-router-dom';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

type CPDOneOffRequirementProps = {
  categoryGroupRequirement?: CategoryGroupRequirement;
  activeCycle: string;
};

export const CPDOneOffRequirement = ({
  categoryGroupRequirement,
  activeCycle
}: CPDOneOffRequirementProps) => {
  return (
    <CardActionArea
      component={NavLink}
      to={{ pathname: '/pdp/' + activeCycle }}
      sx={{ height: '100%' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          width: '100%',
          height: '100%',
          border: `1px solid ${colors['stone.100']}`,
          backgroundColor: 'white',
          px: 3,
          py: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LoadableText loading={!categoryGroupRequirement} variant={'caption'}>
            {categoryGroupRequirement?.category_group_code === 'PDP' &&
            categoryGroupRequirement?.completed === false ? (
              <Box color={colors['charcoal.700']}>Incomplete</Box>
            ) : (
              <Box display={'flex'} fontWeight={600} alignItems={'center'} gap={1}>
                <CompletedCheckmark
                  sx={{ width: 13, height: 13 }}
                  size={'small'}
                  completed={true}
                />
                Completed
              </Box>
            )}
          </LoadableText>
          <ChevronRightOutlinedIcon sx={{ color: 'primary.main', ml: 'auto', mr: -1 }} />
        </Box>
        <LoadableText
          loading={!categoryGroupRequirement}
          variant={'h4'}
          sx={{ fontWeight: '600', width: '100%', pt: 0.5 }}
        >
          {getCardTitleForCategoryCode(categoryGroupRequirement?.category_group_code)}
        </LoadableText>
        <LoadableText loading={!categoryGroupRequirement} mt={0.5} variant={'caption'}>
          <Box>Complete by 31 Dec {activeCycle}</Box>
        </LoadableText>
      </Box>
    </CardActionArea>
  );
};
