import { Typography, Box } from '@mui/material';

type TagWithListProps = { title: string; stringList?: string[] };

export const TagWithList = ({ title, stringList = [] }: TagWithListProps) => {
  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: '#F0F0F0',
        borderRadius: '20px',
        display: 'inline-block'
      }}
    >
      <Typography variant="body2">
        {title} <br />
        {stringList.map((subTag: any, index) => {
          return (
            <span style={{ display: 'block' }} key={index}>
              {' '}
              – {subTag}
            </span>
          );
        })}
      </Typography>
    </Box>
  );
};
