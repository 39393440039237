import { useContext, useMemo } from 'react';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { HighLevelRequirementDetail, useGetMemberAttachmentUrlsQuery } from 'src/graphql/generated';

export const useMemberAttachments = ({
  hlrCardDetails
}: {
  hlrCardDetails: HighLevelRequirementDetail;
}) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const uploadedAttachments = hlrCardDetails?.attachments?.map((attachment) => {
    const { url, __typename, ...rest } = attachment!;
    return {
      ...rest
    };
  });

  const { data: memberAttachmentsUrls } = useGetMemberAttachmentUrlsQuery({
    variables: { memberid: activeMemberId, attachments: uploadedAttachments },
    skip: !hlrCardDetails
  });

  const memberAttachmentsData = useMemo(() => {
    return memberAttachmentsUrls
      ? {
          attachments: memberAttachmentsUrls?.getMemberAttachmentsUrls
        }
      : undefined;
  }, [memberAttachmentsUrls]);

  return { memberAttachmentsData };
};
