import { useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';

import {
  GetMemberActivityByKeyQuery,
  useGetMemberActivityByKeyQuery,
  useGetMemberActivityByCpdIdQuery,
  useGetMemberAttachmentUrlsQuery
} from 'src/graphql/generated';
import { useErrorHandler } from 'src/hooks/useErrorHandler';

export const useMemberActivity = ({
  activityKey,
  cpdId,
  memberId
}: {
  memberId: string | undefined | null;
  cpdId: string | undefined | null;
  activityKey?: string;
}) => {
  const handleError = useErrorHandler();
  const [data, setData] = useState<GetMemberActivityByKeyQuery | undefined>();
  const [error, setError] = useState<ApolloError | undefined>();

  useGetMemberActivityByKeyQuery({
    variables: { memberid: memberId, activitykey: activityKey },
    skip: !activityKey,
    onCompleted: setData,
    onError: (error) => {
      setError(error);
      if (!error?.message?.includes('Activity')) {
        handleError(error);
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  useGetMemberActivityByCpdIdQuery({
    variables: { memberid: memberId, cpdId: cpdId },
    skip: !cpdId,
    onCompleted: (data) => {
      setData({
        getMemberActivityByKey: data.getMemberActivityByCpdId
      });
    },
    onError: (error) => {
      setError(error);
      if (!error?.message?.includes('Activity')) {
        handleError(error);
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const existingAttachments = data?.getMemberActivityByKey?.attachments?.map((attachment) => {
    const { url, __typename, ...rest } = attachment!;
    return {
      ...rest
    };
  });

  const { data: memberAttachmentsUrls } = useGetMemberAttachmentUrlsQuery({
    variables: { memberid: memberId, attachments: existingAttachments },
    skip: !data && !existingAttachments
  });

  const memberActivityData: GetMemberActivityByKeyQuery | undefined = useMemo(() => {
    return memberAttachmentsUrls && data?.getMemberActivityByKey
      ? {
          getMemberActivityByKey: {
            ...data.getMemberActivityByKey,
            attachments: memberAttachmentsUrls?.getMemberAttachmentsUrls
          }
        }
      : undefined;
  }, [data?.getMemberActivityByKey, memberAttachmentsUrls]);

  return { memberActivityData, error };
};
