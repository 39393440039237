import {
  AttachmentInput,
  AttType,
  useGetGeneratedUrlToUploadMemberAttachmentsLazyQuery
} from '../graphql/generated';
import { useContext } from 'react';
import { AuthContext, AuthContextType } from '../contexts/AuthContext';
import axios from 'axios';

export const useUploadAttachments = (uploadType: AttType) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const [getGeneratedUrls] = useGetGeneratedUrlToUploadMemberAttachmentsLazyQuery();

  return async (files: Array<File>) => {
    const attachments: Array<AttachmentInput> = files.map((file) => ({
      attachment_name: encodeURIComponent(file.name),
      attachment_type: uploadType
    }));

    const generatedUrls = (
      await getGeneratedUrls({ variables: { memberid: activeMemberId, attachments } })
    ).data?.getGeneratedUrlToUploadMemberAttachments;
    if (!generatedUrls) {
      throw Error('could not generate urls');
    }

    const uploadPromises = files.map((file) => {
      const generatedUrl = generatedUrls?.find(
        (generatedUrl) => generatedUrl?.attachment_name === encodeURIComponent(file.name)
      );
      if (!generatedUrl) {
        throw Error('generated url not found');
      }
      return axios.put(generatedUrl.url!, file, {
        headers: {
          'x-ms-blob-type': 'BlockBlob'
        }
      });
    });

    await Promise.all(uploadPromises);

    return generatedUrls;
  };
};
