import { Box, Container, Typography, IconButton, Button } from '@mui/material';
import { BookmarkBorder } from '@mui/icons-material';
import { keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

const SearchResultsEmpty = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ textAlign: 'center', py: 2, my: 2, animation: `${fadeInAnimation} 1s` }}>
        <IconButton>
          <BookmarkBorder sx={{ fontSize: '24px' }} />
        </IconButton>
        <Typography variant="body1" sx={{ my: 4, mb: 1, color: '#6F6F6F', fontWeight: '500' }}>
          You have no bookmarks
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          You can add bookmarks on activities you are interested in.
        </Typography>
        <Button variant="outlined" component={Link} to="/browse" sx={{ my: 3 }}>
          Browse activities
        </Button>
      </Box>
    </Container>
  );
};

export { SearchResultsEmpty };
