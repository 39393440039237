import React from 'react';
import { Typography } from '@mui/material';
import { format, parseISO, differenceInDays } from 'date-fns';
import { CpdActivitySession } from 'src/graphql/generated';

export const SessionTime = ({ session }: { session: CpdActivitySession }) => {
  const fromDate = parseISO(`${session.fromDateTime}`);
  const toDate = parseISO(`${session.toDateTime}`);
  const sessionHasDuration = fromDate < toDate;
  const sessionIsMultiday = Math.abs(differenceInDays(toDate, fromDate)) > 1;

  const fromDateString = format(fromDate, 'EEE, d MMM yyyy');
  const toDateString = format(toDate, 'EEE, d MMM yyyy');
  const fromTimeString = format(fromDate, 'p');
  const toTimeString = format(toDate, 'p');
  return (
    <>
      <Typography variant="body2" color="primary">
        {fromDateString} {sessionIsMultiday && `- ${toDateString}`}
      </Typography>
      {!sessionIsMultiday && (
        <Typography variant="body2" color="primary">
          {sessionHasDuration && `${fromTimeString} - ${toTimeString}`}
        </Typography>
      )}
    </>
  );
};
