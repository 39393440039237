import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

export const NoResults = () => {
  return (
    <Box sx={{ py: 2, my: 2, animation: `${fadeInAnimation} 1s` }}>
      <Typography variant="body1" sx={{ color: 'primary.main', fontWeight: '600' }}>
        Sorry, we couldn’t find any results for your search
      </Typography>
      <Typography variant="body1" sx={{ pt: 2, fontWeight: '600' }}>
        Tips to improve your search:
      </Typography>
      <List
        sx={{
          '& .MuiListItem-root': {
            listStyleType: 'disc',
            display: 'list-item',
            listStylePosition: 'inside',
            py: 0
          }
        }}
      >
        <ListItem>Check your spelling</ListItem>
        <ListItem>Check your applied filters</ListItem>
        <ListItem>Enter fewer words</ListItem>
        <ListItem>Enter similar search words</ListItem>
      </List>
    </Box>
  );
};
