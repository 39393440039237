import { Box, Button, Container, Typography } from '@mui/material';
import { colors } from '../App/colors';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom';

import { ContentPageTitle } from '../ContentPageTitle/ContentPageTitle';

const MissingPage = () => {
  return (
    <>
      <ContentPageTitle currentPageTitle="Page not found" showImpersonate={false} />
      <Container maxWidth={'lg'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} my={11}>
          <Box sx={{ borderRadius: 200, p: 1.5, bgcolor: colors['charcoal.300'], mb: 2 }}>
            <Box style={{ width: '24px', height: '24px' }}>
              <ErrorIcon
                sx={{
                  width: '100%',
                  height: '100%',
                  color: colors['charcoal.700']
                }}
              />
            </Box>
          </Box>
          <Typography
            textAlign={'center'}
            color={'primary'}
            variant={'body1'}
            fontWeight={600}
            maxWidth={'80%'}
          >
            Oops, looks like that page doesn't exist
          </Typography>
          <Typography
            textAlign={'center'}
            color={colors['charcoal.700']}
            variant={'body2'}
            mt={{ xs: 2, sm: 0.5 }}
            maxWidth={'80%'}
          >
            The following page or activity might have been removed or expired.
          </Typography>

          <Box display={'flex'} my={4} gap={2} flexWrap={'wrap'} justifyContent={'center'}>
            <Button
              variant="outlined"
              component={Link}
              to={'/'}
              sx={{ px: { xs: 2, sm: 4 }, minWidth: { xs: undefined, sm: 244 } }}
            >
              Go to myCPD home page
            </Button>
            <Button
              variant="outlined"
              component={Link}
              to={'/browse'}
              sx={{ px: { xs: 2, sm: 4 }, minWidth: { xs: undefined, sm: 244 } }}
            >
              Browse Activities
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export { MissingPage };
