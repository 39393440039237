import React from 'react';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridSelector,
  GridEvents
} from '@mui/x-data-grid-pro';
import { Pagination } from '@mui/material';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

type GridPaginationProps = {
  apiRef: React.MutableRefObject<GridApiPro>;
};

export const GridPagination = ({ apiRef }: GridPaginationProps) => {
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  React.useEffect(() => {
    const resetPageOnFilterChange = () => {
      apiRef.current.setPage(0);
    };
    return apiRef.current.subscribeEvent(GridEvents.filterModelChange, resetPageOnFilterChange);
  }, [apiRef]);

  return (
    <Pagination
      sx={{ justifySelf: 'center' }}
      disabled={!pageCount}
      page={pageCount ? page + 1 : 0} // 0 to remove highlight from 1 when there are no pages
      onChange={(event, value) => {
        apiRef.current.setPage(value - 1);
      }}
      count={pageCount || 1} // show indicator for 1 page even if there are no pages
    />
  );
};
