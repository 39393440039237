import React from 'react';
import { NavLink } from 'react-router-dom';
import { ActivityActionsProps } from '../types';
import { ActionMenuItem } from './ActionMenuItem';

export const PDPActions = ({ activity }: ActivityActionsProps) => {
  const activitykeyUrlEncoded = encodeURIComponent(activity?.pdp_year ?? 'null');

  return (
    <>
      <NavLink to={'/pdp/' + activitykeyUrlEncoded}>
        <ActionMenuItem title={'View'} icon={'view'} />
      </NavLink>
    </>
  );
};
