import React, { useContext } from 'react';
import { Box, Pagination, Paper, Skeleton } from '@mui/material';
import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { SearchResult } from './SearchResult';
import { SearchActivityItem } from 'src/graphql/generated';
import { SearchResultsEmpty } from './SearchResultsEmpty';

const SearchResults = () => {
  const {
    paginationPageNumber,
    paginationPageCount,
    handlePageChange,
    searchData,
    searchError,
    searchLoading
  } = useContext(BrowseContext) as BrowseContextType;

  if (searchError) {
    return <>Looks like something went wrong with your search.</>;
  }
  if (searchLoading) {
    return (
      <>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Skeleton
            variant="rectangular"
            sx={{ mt: 2, width: '100px', borderRadius: '10px', bgcolor: 'grey.100' }}
          />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '30%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 1, width: '40%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '80%', bgcolor: 'grey.100' }} />
          <Skeleton
            sx={{
              width: '100px',
              borderRadius: '10px',
              bgcolor: 'grey.100',
              mt: 4
            }}
          />
        </Paper>
      </>
    );
  }
  const itemList = searchData?.searchActivity?.items ?? ([] as SearchActivityItem[]);

  if (itemList.length > 0) {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        {itemList.map((item, index) => {
          const searchActivity = item as SearchActivityItem;
          return (
            <SearchResult
              confirmDeleteBookmark={false}
              searchActivity={searchActivity}
              key={index}
            />
          );
        })}

        <Pagination
          sx={{ mt: 3, alignSelf: 'center' }}
          page={paginationPageNumber}
          count={paginationPageCount}
          onChange={handlePageChange}
        />
      </Box>
    );
  } else {
    return <SearchResultsEmpty />;
  }
};

export { SearchResults };
