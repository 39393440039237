import { Typography } from '@mui/material';

const Slide1 = (props: any) => {
  return (
    <>
      <Typography variant="h2">Important change to CPD grace period</Typography>
      <Typography variant="body1">
        The MBA has advised there will be no grace period to complete your 2024 CPD in 2025.
      </Typography>
      <Typography variant="body2">
        Start your Professional Development Plan, find activities in our CPD home that interest you,
        and log the CPD you do in your everyday work.
      </Typography>
      <Typography variant="body2">
        As your CPD home, RACGP is here to help. Call for assistance on{' '}
        <a style={{ color: '#fff' }} href="tel:1800 716 853">
          1800 716 853
        </a>
        .
      </Typography>
    </>
  );
};
export { Slide1 };
