export const getCardTitleForCategoryCode = (categoryCode?: string) => {
  switch (categoryCode) {
    case 'EA-BLA':
      return `Educational Activities (EA)`;
    case 'RPMO':
      return `Reviewing Performance (RP) & Measuring Outcomes (MO)`;
    case 'RPMOEU':
      return `Any CPD Type\nEA, RP and MO`;
    case 'PDP':
      return 'Professional Development Plan (PDP)';
    case 'BLS':
      return `GP Specialist High-Level Requirement (CPR)`;
    case 'PLR':
      return 'Program-Level Requirements';

    default:
      return null;
  }
};

export const getRequirementLabel = (requirementCode: string) => {
  switch (requirementCode) {
    case 'RP':
      return 'Reviewing Performance (RP)';
    case 'MO':
      return 'Measuring Outcomes (MO)';
    default:
      return undefined;
  }
};
