import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { ActivityActionsProps } from '../types';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { HistoryContext, HistoryContextType } from 'src/contexts/HistoryContext';
import { ActionMenuItem } from './ActionMenuItem';

export const HlrActions = ({ activity }: ActivityActionsProps) => {
  const {
    deleteHlrMutation: [executeDeleteHlr],
    showPopup
  } = useContext(HistoryContext) as HistoryContextType;

  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;

  const activitykeyUrlEncoded = encodeURIComponent(activity?.activitykey ?? 'null');

  const deleteHlr = () => {
    showPopup({
      title: 'Are you sure you want to delete?',
      message:
        'All CPD hours and files you may have uploaded will permanently be deleted from this requirement.',
      confirm: true,
      onConfirm: () => {
        if (!activity?.activitykey) {
          return;
        }
        executeDeleteHlr({
          variables: {
            memberid: activeMemberId,
            activitykey: activity.activitykey
          }
        });
      }
    });
  };

  return (
    <>
      <NavLink to={'/hlr/' + activitykeyUrlEncoded}>
        <ActionMenuItem icon={'edit'} title={'Edit'} />
      </NavLink>
      <ActionMenuItem onClick={deleteHlr} icon={'delete'} title={'Delete'} />
    </>
  );
};
