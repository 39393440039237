import React, { useMemo, useState } from 'react';
import { Paper, Typography, Box, Button, Select, MenuItem } from '@mui/material';

import { CpdActivityContent, CpdActivitySession } from 'src/graphql/generated';
import { SessionNotificationButton } from './SessionNotificationButton';
import { SessionTime } from './SessionTime';
import { parseISO } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';

const states: any[] = [
  {
    label: 'Show all',
    value: 'ALL'
  },
  {
    label: 'ACT',
    value: 'ACT'
  },
  {
    label: 'NSW',
    value: 'NSW'
  },
  {
    label: 'NT',
    value: 'NT'
  },
  {
    label: 'QLD',
    value: 'QLD'
  },
  {
    label: 'SA',
    value: 'SA'
  },
  {
    label: 'TAS',
    value: 'TAS'
  },
  {
    label: 'VIC',
    value: 'VIC'
  },
  {
    label: 'WA',
    value: 'WA'
  }
];

type SessionDetailsListType = {
  activity: CpdActivityContent;
  sessionsList: CpdActivitySession[];
  showStateFilter: boolean;
};

const SessionDetailsList = ({
  activity,
  sessionsList,
  showStateFilter
}: SessionDetailsListType) => {
  const [selectedState, setSelectedState] = useState('ALL');
  const sessionsToShow = 5;
  const [viewMoreCount, setViewMoreCount] = useState<number>(sessionsToShow);
  const handleStateChange = (event: any) => {
    setSelectedState(event.target.value);
  };

  var filteredSessions = useMemo(getFilteredSessions, [selectedState, sessionsList]);

  function getFilteredSessions() {
    if (selectedState === 'ALL') {
      return sessionsList;
    }
    return sessionsList.filter((item) => item?.state === selectedState);
  }
  const visibleSessions = filteredSessions.filter(
    (filteredSession) => parseISO(`${filteredSession?.toDateTime}`) > new Date()
  );
  const showNext5 = () => {
    setViewMoreCount(viewMoreCount + sessionsToShow);
  };

  return (
    <>
      <Box
        id="sessions-placeholder"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 3
        }}
      >
        <Typography variant="h3" color="primary">
          Available sessions
        </Typography>
        {showStateFilter && (
          <Select onChange={handleStateChange} value={selectedState}>
            {states.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </Box>

      {visibleSessions?.slice(0, viewMoreCount)?.map((session: any, index) => (
        <SessionDetailsItem key={index} activityId={activity?.id ?? ''} session={session} />
      ))}
      {visibleSessions.length > viewMoreCount && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={showNext5}>
            <AddIcon fontSize="small" sx={{ mr: 1 }} /> View more
          </Button>
        </Box>
      )}
    </>
  );
};

type SessionDetailsType = {
  activityId: string;
  session: CpdActivitySession;
};

const SessionDetailsItem = ({ activityId, session }: SessionDetailsType) => {
  const sessionToDate = parseISO(`${session?.toDateTime}`);
  const sessionExpired = new Date() > sessionToDate;
  if (sessionExpired) {
    return null;
  }

  const isRegisterCard = (session?.venue ?? '').startsWith('http');
  const sessionTitleList = isRegisterCard
    ? [session?.state, session?.postcode]
    : [session?.venue, session?.state, session?.postcode];
  sessionTitleList.filter((n) => n); // remove empty
  const sessionTitle = sessionTitleList.join(', ');

  const registerURL = session?.venue ?? '#';

  return (
    <Paper
      sx={{
        p: { xs: 2, md: 3 },
        mt: 3
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography sx={{ pb: 1 }} variant="h3" color="primary">
            {sessionTitle}
          </Typography>
          <SessionTime session={session} />
        </Box>
        <Box>
          {isRegisterCard && (
            <Button
              color="primary"
              variant="outlined"
              size="medium"
              component="a"
              href={registerURL}
              target="_blank"
              sx={{ display: { xs: 'none', md: 'inline-flex' } }}
            >
              Register
            </Button>
          )}

          <SessionNotificationButton activityId={activityId} session={session} />
        </Box>
      </Box>
      {isRegisterCard && (
        <Box>
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            component="a"
            href={registerURL}
            target="_blank"
            sx={{ width: '100%', mt: 2, display: { xs: 'inline-flex', md: 'none' } }}
          >
            Register
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export { SessionDetailsList };
