import React from 'react';
import { useCallback, useContext } from 'react';
import {
  Divider,
  Typography,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Button,
  Box,
  Autocomplete,
  Paper,
  Alert,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { PDPFormText } from './PDPFormText';
import { clamp } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PdpDetailData } from './types';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import {
  PdpDetail,
  useGetPdpDetailsQuery,
  useSavePdpDetailsMutation,
  useSubmitPdpMutation,
  PdpType,
  PdpStatus,
  useGetReferencesPdpQuery,
  Lookup,
  CpdActivityPlan
} from 'src/graphql/generated';
import { PdpLoading } from './PdpLoading';

const PDPForm = () => {
  const { year } = useParams<{ year: string }>();
  const yearNumber = Number.parseInt(year);
  const is2024OrGreater = yearNumber >= 2024;
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const [pdpSubmitStatus, setPdpSubmitStatus] = React.useState<PdpStatus>();
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState<String>('');
  const [dialogDescription, setDialogDescription] = React.useState<String>('');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const history = useHistory();
  const { data: pdpReferenceData } = useGetReferencesPdpQuery();
  const pdpEaActivityList = ((pdpReferenceData?.pdp_ea_list?.items ?? []) as Lookup[])
    .map((reference) => reference.value ?? '')
    .sort();
  const pdpRpActivityList = ((pdpReferenceData?.pdp_rp_list?.items ?? []) as Lookup[])
    .map((reference) => reference.value ?? '')
    .sort();
  const pdpMoActivityList = ((pdpReferenceData?.pdp_mo_list?.items ?? []) as Lookup[])
    .map((reference) => reference.value ?? '')
    .sort();

  const { register, handleSubmit, control, watch, setValue, reset, formState } =
    useForm<PdpDetailData>({
      defaultValues: {
        current_situation: '',
        goals: '',
        annual_review_and_reflection: '',
        reflection_culturally_save_practice: '',
        reflection_health_inequities: '',
        reflection_professionalism_ethical_practice: '',
        hours_spent: 0,
        attachments: [],
        eaHours: 0,
        eaTags: [],
        rpHours: 0,
        rpTags: [],
        moHours: 0,
        moTags: []
      },
      mode: 'onChange'
    });

  // destructured here because otherwise this does not work on first render
  const { errors, isDirty, isValid } = formState;
  const watchAllFields = watch();
  const nanToZero = (num: number) => {
    if (Number.isNaN(num)) {
      return 0;
    } else return num;
  };
  const roundHalf = (num: number) => {
    return Math.round(num * 2) / 2;
  };
  const roundField = useCallback(
    (fieldName: 'eaHours' | 'rpHours' | 'moHours' | 'hours_spent') => {
      setValue(fieldName, clamp(roundHalf(watch(fieldName)), 0, 500), {
        shouldDirty: true,
        shouldValidate: true
      });
    },
    [setValue, watch]
  );

  const { loading, error: errorGetQuery } = useGetPdpDetailsQuery({
    variables: { memberid: activeMemberId, pdp_year: year },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const pdpDetailsData = data?.getPdpDetails as PdpDetail;

      const eaActivityPlan = pdpDetailsData?.cpdActivityPlans?.find(
        (cpdActivityPlanType) => cpdActivityPlanType?.type === 'ea'
      ) as CpdActivityPlan;
      const rpActivityPlan = pdpDetailsData?.cpdActivityPlans?.find(
        (cpdActivityPlanType) => cpdActivityPlanType?.type === 'rp'
      ) as CpdActivityPlan;
      const moActivityPlan = pdpDetailsData?.cpdActivityPlans?.find(
        (cpdActivityPlanType) => cpdActivityPlanType?.type === 'mo'
      ) as CpdActivityPlan;
      setPdpSubmitStatus(pdpDetailsData?.pdp_status as PdpStatus);

      reset({
        current_situation: pdpDetailsData?.current_situation ?? '',
        goals: pdpDetailsData?.goals ?? '',
        reflection_culturally_save_practice:
          pdpDetailsData?.reflection_culturally_save_practice ?? '',
        reflection_health_inequities: pdpDetailsData?.reflection_health_inequities ?? '',
        reflection_professionalism_ethical_practice:
          pdpDetailsData?.reflection_professionalism_ethical_practice ?? '',
        annual_review_and_reflection: pdpDetailsData?.annual_review_and_reflection ?? '',
        eaHours: eaActivityPlan?.hours ?? 0,
        rpHours: rpActivityPlan?.hours ?? 0,
        moHours: moActivityPlan?.hours ?? 0,
        eaTags: eaActivityPlan?.tags || [],
        rpTags: rpActivityPlan?.tags || [],
        moTags: moActivityPlan?.tags || [],
        hours_spent: pdpDetailsData?.hours_spent ?? 0
      });
    }
  });

  const [savePdpDetailsMutation, { error: errorSaveMutation }] = useSavePdpDetailsMutation({
    variables: {
      memberid: activeMemberId,
      pdp_year: year,
      pdp_details: {
        scope_of_practice: '',
        goals: watchAllFields.goals,
        general_comments: '',
        quick_log_reflection_comments: '',
        annual_review_and_reflection: watchAllFields.annual_review_and_reflection,
        commented_activities: [],
        cpd_activity_key: '',
        cpdActivityPlans: [
          {
            type: 'ea',
            tags: watchAllFields.eaTags ?? [],
            hours: watchAllFields.eaHours
          },
          {
            type: 'rp',
            tags: watchAllFields.rpTags ?? [],
            hours: watchAllFields.rpHours
          },
          {
            type: 'mo',
            tags: watchAllFields.moTags ?? [],
            hours: watchAllFields.moHours
          }
        ],
        attachments: [],
        pdp_type: PdpType.Template,
        exempt: false,
        current_situation: watchAllFields.current_situation,
        reflection_culturally_save_practice: watchAllFields.reflection_culturally_save_practice,
        reflection_professionalism_ethical_practice:
          watchAllFields.reflection_professionalism_ethical_practice,
        reflection_health_inequities: watchAllFields.reflection_health_inequities,
        hours_spent: watchAllFields.hours_spent
      }
    },
    onCompleted: () => {
      reset({
        current_situation: watchAllFields.current_situation,
        goals: watchAllFields.goals,
        reflection_culturally_save_practice: watchAllFields.reflection_culturally_save_practice,
        reflection_health_inequities: watchAllFields.reflection_health_inequities,
        reflection_professionalism_ethical_practice:
          watchAllFields.reflection_professionalism_ethical_practice,
        annual_review_and_reflection: watchAllFields.annual_review_and_reflection,
        eaHours: watchAllFields.eaHours,
        rpHours: watchAllFields.rpHours,
        moHours: watchAllFields.moHours,
        eaTags: watchAllFields.eaTags,
        rpTags: watchAllFields.rpTags,
        moTags: watchAllFields.moTags,
        hours_spent: watchAllFields.hours_spent
      });
      if (pdpSubmitStatus === PdpStatus.PdpSubmitted) {
        setOpen(true);
        setDialogTitle('Your PDP has been updated');
        setDialogDescription('Your hours have been added and you can view now in History.');
      }
      if (pdpSubmitStatus === PdpStatus.PdpDraft) {
        setOpenSnack(true);
      }
    },
    onError: () => {}
  });

  React.useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [isDirty]);

  const handleClose = () => {
    setOpen(false);
    history.push('/pdp/' + year, { selectedTemplate: 'home' });
  };
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const handleSave = () => {
    savePdpDetailsMutation();
  };
  const onSubmit = () => {
    savePdpDetailsMutation({ onCompleted: () => submitPdpMutation() });
  };

  const [submitPdpMutation, { error: errorSubmitQuery }] = useSubmitPdpMutation({
    variables: {
      memberid: activeMemberId,
      pdp_year: year
    },
    onCompleted: () => {
      setOpen(true);
      setDialogTitle(`Congratulations! You have successfully submitted your PDP for ${year}.`);
      setDialogDescription('Your hours have been added and you can view now in History.');
    }
  });

  React.useEffect(() => {
    if (isDirty && pdpSubmitStatus === PdpStatus.PdpDraft) {
      const interval = setInterval(() => {
        savePdpDetailsMutation();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isDirty, pdpSubmitStatus, reset, savePdpDetailsMutation, watchAllFields]);

  if (loading) {
    return <PdpLoading />;
  }

  if (errorGetQuery) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{errorGetQuery.message}</Alert>
      </Box>
    );
  }
  if (errorSubmitQuery) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{errorSubmitQuery.message}</Alert>
      </Box>
    );
  }
  if (errorSaveMutation) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{errorSaveMutation.message}</Alert>
      </Box>
    );
  }
  return (
    <Paper sx={{ p: 3, my: 2 }} elevation={3}>
      <PDPFormText />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Prompt
          when={isDirty}
          message={(location) => `You have unsaved changes, are you sure you want to leave?`}
        />
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              color={errors.current_situation ? 'red' : 'primary'}
              sx={{ mb: 2 }}
            >
              My current situation{' '}
              <Typography
                variant={'caption'}
                color={errors.current_situation ? 'red' : 'primary'}
                fontWeight={400}
              >
                (Required)
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body2" sx={{ pb: 2 }}>
              Briefly describe your current work situation.
            </Typography>
            <Controller
              name="current_situation"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  {...register('current_situation', { required: true, minLength: 3 })}
                  error={!!error}
                  fullWidth={true}
                  id="current_situation"
                  label="Your response"
                  multiline
                  inputProps={{ maxLength: 1600 }}
                  minRows={5}
                  maxRows={15}
                  variant="outlined"
                />
              )}
            />
            <Typography component="p" variant="caption" align="right" sx={{ mt: 1 }}>
              {watchAllFields.current_situation?.length}/1600
            </Typography>
            <Accordion
              sx={{ boxShadow: 'none', display: 'contents' }}
              disableGutters
              expanded={expanded === 'accordion1'}
              onChange={handleChange('accordion1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" fontSize="small" />}
                aria-controls="current-situation-content"
                id="current-situation-header"
                sx={{ display: 'inline-flex', p: 0, mt: '-30px' }}
              >
                <Typography variant="subtitle2" color="primary">
                  {expanded === 'accordion1' ? 'Hide' : 'Show'} sample response
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Typography variant="body2">
                  I work in a multidisciplinary practice but my specific interests are Women’s and
                  Children's health. I supervise registrars within the practice and regularly
                  participate in practice-based peer group learning.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color={errors.goals ? 'red' : 'primary'} sx={{ mb: 2 }}>
              My learning goals{' '}
              <Typography
                variant={'caption'}
                color={errors.goals ? 'red' : 'primary'}
                fontWeight={400}
              >
                (Required)
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body2" sx={{ pb: 2 }}>
              Briefly describe goals to help you maintain, add value and/or extend your scope of
              practice.
            </Typography>
            <Controller
              name="goals"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  {...register('goals', {
                    required: true,
                    minLength: 3
                  })}
                  error={!!error}
                  fullWidth={true}
                  id="outlined-textarea"
                  label="Your response"
                  multiline
                  inputProps={{ maxLength: 1600 }}
                  minRows={5}
                  maxRows={15}
                  variant="outlined"
                />
              )}
            />
            <Typography component="p" variant="caption" align="right" sx={{ mt: 1 }}>
              {watchAllFields.goals?.length}/1600
            </Typography>
            <Accordion
              sx={{ boxShadow: 'none', display: 'contents' }}
              disableGutters
              expanded={expanded === 'accordion2'}
              onChange={handleChange('accordion2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" fontSize="small" />}
                aria-controls="learning-goals-content"
                id="learning-goals-header"
                sx={{ display: 'inline-flex', p: 0, mt: '-30px' }}
              >
                <Typography variant="subtitle2" color="primary">
                  {expanded === 'accordion2' ? 'Hide' : 'Show'} sample response
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Typography variant="body2">
                  I would like to attend an update on diabetes and on skin cancer excision. I would
                  also be interested in cardiology and chronic disease management as they are the
                  most common presentations I see in my practice.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />

        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              color={errors.reflection_culturally_save_practice ? 'red' : 'primary'}
              sx={{ mb: 2 }}
            >
              My reflection on Culturally Safe Practice{' '}
              <Typography
                variant={'caption'}
                color={errors.reflection_culturally_save_practice ? 'red' : 'primary'}
                fontWeight={400}
              >
                (Required)
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body2" sx={{ pb: 2, a: { textDecoration: 'underline' } }}>
              How will you improve and apply your knowledge and understanding of Cultural Safety for
              Aboriginal and Torres Strait Islander people's to your scope of practice? A portion of
              your 50 hours CPD must address this subject annually. Recommended activities can be
              found{' '}
              <a
                href="https://www.racgp.org.au/education/professional-development/cpd/cpd-program-level-requirements/cultural-safety"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </Typography>
            <Controller
              name="reflection_culturally_save_practice"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  {...register('reflection_culturally_save_practice', {
                    required: true,
                    minLength: 3
                  })}
                  error={!!error}
                  fullWidth={true}
                  id="outlined-textarea"
                  label="Your response"
                  multiline
                  inputProps={{ maxLength: 1600 }}
                  minRows={5}
                  maxRows={15}
                  variant="outlined"
                />
              )}
            />
            <Typography component="p" variant="caption" align="right" sx={{ mt: 1 }}>
              {watchAllFields.reflection_culturally_save_practice?.length}/1600
            </Typography>
            <Accordion
              sx={{ boxShadow: 'none', display: 'contents' }}
              disableGutters
              expanded={expanded === 'accordion3'}
              onChange={handleChange('accordion3')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" fontSize="small" />}
                aria-controls="my-reflection-content"
                id="my-reflection-header"
                sx={{ display: 'inline-flex', p: 0, mt: '-30px' }}
              >
                <Typography variant="subtitle2" color="primary">
                  {expanded === 'accordion3' ? 'Hide' : 'Show'} sample response
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Typography variant="body2">
                  I will ensure that we display Aboriginal and Torres Strait Islander flags in our
                  practice and acknowledge traditional owners of the land where our practice is
                  located. I will use culturally appropriate materials and patient information in
                  our practice. I will reach out to Aboriginal and Torres Strait Islander health
                  organisations such as VACCHO for further training and/or resources on culturally
                  safe practices.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {is2024OrGreater && (
          <>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h6"
                  color={errors.reflection_health_inequities ? 'red' : 'primary'}
                  sx={{ mb: 2 }}
                >
                  My reflection on Health Inequities{' '}
                  <Typography
                    variant={'caption'}
                    color={errors.reflection_health_inequities ? 'red' : 'primary'}
                    fontWeight={400}
                  >
                    (Required)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="body2" sx={{ pb: 2, a: { textDecoration: 'underline' } }}>
                  How will you improve and apply your knowledge and understanding of Health
                  Inequities to your scope of practice? A portion of your 50 hours CPD must address
                  this subject annually. Recommended activities can be found{' '}
                  <a
                    href="https://www.racgp.org.au/education/professional-development/cpd/cpd-program-level-requirements/health-inequities"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </Typography>
                <Controller
                  name="reflection_health_inequities"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      {...register('reflection_health_inequities', {
                        required: is2024OrGreater,
                        minLength: 3
                      })}
                      error={!!error}
                      fullWidth={true}
                      id="outlined-textarea"
                      label="Your response"
                      multiline
                      inputProps={{ maxLength: 1600 }}
                      minRows={5}
                      maxRows={15}
                      variant="outlined"
                    />
                  )}
                />
                <Typography component="p" variant="caption" align="right" sx={{ mt: 1 }}>
                  {watchAllFields.reflection_health_inequities?.length}/1600
                </Typography>

                <Accordion
                  sx={{ boxShadow: 'none', display: 'contents' }}
                  disableGutters
                  expanded={expanded === 'accordion-h-i'}
                  onChange={handleChange('accordion-h-i')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" fontSize="small" />}
                    aria-controls="my-reflection-health-inequities"
                    id="my-reflection-health-inequities"
                    sx={{ display: 'inline-flex', p: 0, mt: '-30px' }}
                  >
                    <Typography variant="subtitle2" color="primary">
                      {expanded === 'accordion-h-i' ? 'Hide' : 'Show'} sample response
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Typography variant="body2">
                      In the community that we serve, inevitably, health inequality exists but is
                      often hidden. Also, while some health inequality is displayed to me, as a GP,
                      some is not. A patient may not present because of stigma, shame, fear or cost,
                      for example. I will ensure that our practice meetings consider the issue of
                      health inequality in the community that we serve, and will ensure that I
                      remove as many barriers as possible in my own clinical work to minimise the
                      risk of any health inequality continuing.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />
          </>
        )}

        {is2024OrGreater && (
          <>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h6"
                  color={errors.reflection_professionalism_ethical_practice ? 'red' : 'primary'}
                  sx={{ mb: 2 }}
                >
                  My reflection on Professionalism and Ethical Practice{' '}
                  <Typography
                    variant={'caption'}
                    color={errors.reflection_professionalism_ethical_practice ? 'red' : 'primary'}
                    fontWeight={400}
                  >
                    (Required)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="body2" sx={{ pb: 2, a: { textDecoration: 'underline' } }}>
                  How will you improve and apply your knowledge and understanding of Professionalism
                  and Ethical Practice to your scope of practice? A portion of your 50 hours CPD
                  must address this subject annually. Recommended activities can be found{' '}
                  <a
                    href="https://www.racgp.org.au/education/professional-development/cpd/racgp-s-cpd-solution-for-professionalism-and-ethic/introduction"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </Typography>
                <Controller
                  name="reflection_professionalism_ethical_practice"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      {...register('reflection_professionalism_ethical_practice', {
                        required: is2024OrGreater,
                        minLength: 3
                      })}
                      error={!!error}
                      fullWidth={true}
                      id="outlined-textarea"
                      label="Your response"
                      multiline
                      inputProps={{ maxLength: 1600 }}
                      minRows={5}
                      maxRows={15}
                      variant="outlined"
                    />
                  )}
                />
                <Typography component="p" variant="caption" align="right" sx={{ mt: 1 }}>
                  {watchAllFields.reflection_professionalism_ethical_practice?.length}/1600
                </Typography>

                <Accordion
                  sx={{ boxShadow: 'none', display: 'contents' }}
                  disableGutters
                  expanded={expanded === 'accordion-pep'}
                  onChange={handleChange('accordion-pep')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" fontSize="small" />}
                    aria-controls="reflection-professionalism-ethical-practice"
                    id="reflection-professionalism-ethical-practice"
                    sx={{ display: 'inline-flex', p: 0, mt: '-30px' }}
                  >
                    <Typography variant="subtitle2" color="primary">
                      {expanded === 'accordion-pep' ? 'Hide' : 'Show'} sample response
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Typography variant="body2">
                      Ethical and professional practice is the foundation upon which all clinical
                      practice occurs. It is all too easy to allow practice behaviours and pressures
                      to cause slippage in, for example, boundary management, billing practices,
                      interaction with peers and colleagues, and other aspects of good clinical
                      practice. This year I will complete, again, the education resources provided
                      by my indemnity provider, and will read carefully (and reflect upon
                      implications for my practice) the regular Medical Board newsletters. I will
                      also refer to the RACGP’s CPD resources on professionalism and ethical
                      practice for other educational options.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />
          </>
        )}

        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
              My CPD activity plan{' '}
              <Typography variant={'caption'} color="primary" fontWeight={400}>
                (Optional)
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Accordion sx={{ boxShadow: 'none', display: 'contents' }} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" fontSize="small" />}
                aria-controls="my-cpd_activity-content"
                id="my-cpd_activity-header"
                sx={{ p: 0, mt: '-12px' }}
              >
                <Typography variant="body2">
                  This is an optional activity which you may find useful in planning your CPD
                  activities throughout the year.
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Box>
                  <Typography variant="body2" sx={{ pb: 1 }}>
                    Select activities that align to your scope of practice and your learning goals
                    from the drop-down menu or write your own. Refer to the{' '}
                    <Link
                      href="https://portal.racgp.org.au/getmedia/400a7333-09bb-4248-90d8-7dec2a695ea7/Activities-for-your-CPD.aspx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      RACGP CPD Activity Guide
                    </Link>{' '}
                    for more suggestions.
                  </Typography>

                  <Typography variant="body1" fontWeight={600} color={'primary'} sx={{ pb: 1 }}>
                    Educational Activities
                  </Typography>
                  <Typography variant="body2" sx={{ pb: 2 }}>
                    These are activities that expand your general practice knowledge, skills and
                    attitudes related to your scope of practice.
                  </Typography>
                  <Controller
                    name="eaTags"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Autocomplete
                        sx={{ mt: 2 }}
                        id="educational-activities"
                        freeSolo
                        multiple
                        options={pdpEaActivityList}
                        value={value}
                        {...register('eaTags')}
                        onChange={(event, selectedValue: string[]) => onChange(selectedValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Educational Activities"
                            placeholder="Educational Activities"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                  <Box sx={{ display: 'flex', my: 3 }}>
                    <Controller
                      name="eaHours"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('eaHours', {
                            setValueAs: (v: any) => nanToZero(Number.parseFloat(v)),
                            onBlur: () => {
                              roundField('eaHours');
                            }
                          })}
                          sx={{ width: '100px', mr: 3 }}
                          type={'number'}
                          label="Hours"
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: 0.5
                            }
                          }}
                        />
                      )}
                    />
                    <Box>
                      <Typography variant="body2" fontWeight={600}>
                        Estimated hours:
                      </Typography>
                      <Typography variant="body2">
                        Enter your estimated hours for these activities for the year.
                      </Typography>
                    </Box>
                  </Box>

                  <Divider sx={{ mt: 2, mb: 4 }} />

                  <Typography variant="body1" fontWeight={600} color={'primary'} sx={{ pb: 1 }}>
                    Reviewing Performance
                  </Typography>
                  <Typography variant="body2">
                    These are activities that require reflection on feedback about your work.
                  </Typography>
                  <Controller
                    name="rpTags"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Autocomplete
                        id="reviewing-performance"
                        freeSolo
                        multiple
                        options={pdpRpActivityList}
                        value={value}
                        {...register('rpTags')}
                        onChange={(event: any, selectedValue: string[]) => onChange(selectedValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reviewing Performance"
                            placeholder="Reviewing Performance"
                            sx={{ mt: 2 }}
                          />
                        )}
                      />
                    )}
                  />
                  <Box sx={{ display: 'flex', my: 3 }}>
                    <Controller
                      name="rpHours"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('rpHours', {
                            setValueAs: (v: any) => nanToZero(Number.parseFloat(v)),
                            onBlur: () => {
                              roundField('rpHours');
                            }
                          })}
                          sx={{ width: '100px', mr: 3 }}
                          type={'number'}
                          label="Hours"
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: 0.5
                            }
                          }}
                        />
                      )}
                    />
                    <Box>
                      <Typography variant="body2" fontWeight={600}>
                        Estimated hours:
                      </Typography>
                      <Typography variant="body2">
                        Enter your estimated hours for these activities for the year.
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: 2, mb: 4 }} />
                  <Typography variant="body1" fontWeight={600} color={'primary'} sx={{ pb: 1 }}>
                    Measuring Outcomes
                  </Typography>
                  <Typography variant="body2">
                    These are activities that use your work data to ensure quality results.
                  </Typography>
                  <Controller
                    name="moTags"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Autocomplete
                        id="measuring-outcomes"
                        freeSolo
                        multiple
                        options={pdpMoActivityList}
                        value={value}
                        {...register('moTags')}
                        onChange={(event, selectedValue: string[]) => onChange(selectedValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Measuring Outcomes"
                            placeholder="Measuring Outcomes"
                            sx={{ mt: 2 }}
                          />
                        )}
                      />
                    )}
                  />
                  <Box sx={{ display: 'flex', my: 3 }}>
                    <Controller
                      name="moHours"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('moHours', {
                            setValueAs: (v: any) => nanToZero(Number.parseFloat(v)),
                            onBlur: () => {
                              roundField('moHours');
                            }
                          })}
                          sx={{ width: '100px', mr: 3 }}
                          type={'number'}
                          label="Hours"
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: 0.5
                            }
                          }}
                        />
                      )}
                    />
                    <Box>
                      <Typography variant="body2" fontWeight={600}>
                        Estimated hours:
                      </Typography>
                      <Typography variant="body2">
                        Enter your estimated hours for these activities for the year.
                      </Typography>
                    </Box>
                  </Box>

                  <Divider sx={{ my: 2 }} />
                  <Typography variant="body1" fontWeight={700}>
                    You have estimated{' '}
                    {(
                      Number(watchAllFields.eaHours) +
                      Number(watchAllFields.rpHours) +
                      Number(watchAllFields.moHours)
                    ).toFixed(1)}{' '}
                    hours in planned CPD Activities.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 4, mb: 2 }} />
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              color={errors.annual_review_and_reflection ? 'red' : 'primary'}
              sx={{ mb: 2 }}
            >
              Your annual reflection{' '}
              <Typography
                variant={'caption'}
                color={errors.annual_review_and_reflection ? 'red' : 'primary'}
                fontWeight={400}
              >
                (Required)
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body2" sx={{ pb: 2 }}>
              Review what you have or have not achieved in your learning goals this year and
              consider how this may direct your CPD planning for next year. Only complete this
              section once you have completed your CPD requirements for the&nbsp;year.
            </Typography>
            <Controller
              name="annual_review_and_reflection"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  {...register('annual_review_and_reflection', {
                    required: true,
                    minLength: 3
                  })}
                  error={!!error}
                  fullWidth={true}
                  id="outlined-textarea"
                  label="Your response"
                  multiline
                  minRows={5}
                  maxRows={15}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1600
                  }}
                />
              )}
            />
            <Typography component="p" variant="caption" align="right" sx={{ mt: 1 }}>
              {watchAllFields.annual_review_and_reflection?.length}/1600
            </Typography>
            <Accordion
              sx={{ boxShadow: 'none', display: 'contents' }}
              disableGutters
              expanded={expanded === 'accordion4'}
              onChange={handleChange('accordion4')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" fontSize="small" />}
                aria-controls="annual-reflection-content"
                id="annual-reflection-header"
                sx={{ display: 'inline-flex', p: 0, mt: '-30px' }}
              >
                <Typography variant="subtitle2" color="primary">
                  {expanded === 'accordion4' ? 'Hide' : 'Show'} sample response
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Typography variant="body2">
                  You may consider these reflective questions: What did you learn? How will you
                  change the way you practise? Other areas you’d like to improve.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color={errors.hours_spent ? 'red' : 'primary'} sx={{ mb: 2 }}>
              Time spent on my PDP{' '}
              <Typography
                variant={'caption'}
                color={errors.hours_spent ? 'red' : 'primary'}
                fontWeight={400}
              >
                (Required)
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="body2">
                  Log the time you have spent completing your PDP to a maximum of 5 hours.
                </Typography>
                <Typography variant="body2">
                  The hours will be allocated to Reviewing Performance upon submission.
                </Typography>
              </Box>
              <Box sx={{ display: 'block' }}>
                <Controller
                  name="hours_spent"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      color="secondary"
                      {...field}
                      {...register('hours_spent', {
                        required: true,
                        min: 0.5,
                        max: 5,
                        setValueAs: (v: any) => nanToZero(Number.parseFloat(v)),
                        onBlur: () => {
                          roundField('hours_spent');
                        }
                      })}
                      error={!!error}
                      sx={{ width: '130px' }}
                      type={'number'}
                      label="Hours"
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.5
                        }
                      }}
                    />
                  )}
                />
                <Typography
                  variant="body2"
                  sx={{ fontSize: '0.725rem', mt: 1 }}
                  color={errors.hours_spent ? 'red' : 'primary'}
                >
                  5 CPD hour limit per PDP
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'flex-end'
          }}
        >
          {pdpSubmitStatus === PdpStatus.PdpDraft && (
            <>
              <Button
                variant="outlined"
                sx={{ minWidth: '180px', mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 } }}
                onClick={handleSave}
              >
                Save Draft
              </Button>
              <Button variant="contained" sx={{ minWidth: '180px' }} type="submit">
                Submit PDP
              </Button>
            </>
          )}
          {pdpSubmitStatus === PdpStatus.PdpSubmitted && (
            <Button
              variant="contained"
              sx={{ minWidth: '180px', mr: 2 }}
              onClick={handleSave}
              type="button"
              disabled={!isValid}
            >
              Update
            </Button>
          )}
        </Box>
      </form>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
          Form saved.
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DialogContentText id="alert-dialog-description">
              <CheckCircleOutlineIcon color={'secondary'} />
            </DialogContentText>
            <Box>
              <DialogContentText>{dialogTitle}</DialogContentText>
              <DialogContentText>{dialogDescription}</DialogContentText>
            </Box>
          </Box>
          <DialogActions sx={{ p: 0 }}>
            <Button variant="text" onClick={handleClose} sx={{ minWidth: 'initial' }}>
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};
export { PDPForm };
