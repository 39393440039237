import React from 'react';
import { Typography } from '@mui/material';

import { SearchActivityItem, CpdActivitySession } from 'src/graphql/generated';
import { getUniqueSessionStatesString } from 'src/components/Pages/Browse/getUniqueSessionStates';

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
};

const States: React.FC<Props> = ({ searchActivity }) => {
  const sessionList = (searchActivity?.activity?.sessions ?? []) as CpdActivitySession[];
  const uniqueSessionStatesString = getUniqueSessionStatesString(sessionList);
  return <Typography variant="body2">{uniqueSessionStatesString}</Typography>;
};

export { States };
