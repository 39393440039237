import React, { useContext } from 'react';
import { IconButton, Box, Button, Modal, Typography, Paper, Divider } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import {
  useDeleteNotificationsMutation,
  SubscriptionInput,
  CpdActivityContent,
  NotificationType,
  CpdActivitySession
} from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { getUniqueSessionStatesString } from 'src/components/Pages/Browse/getUniqueSessionStates';
import { useHistory } from 'react-router-dom';
type Props = {
  notification: SubscriptionInput | null | undefined;
  activity: CpdActivityContent | null | undefined;
};

const RemoveNotificationButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  var notification_type = NotificationType.CpdUpdate;
  if (props.notification?.type) {
    notification_type = props.notification?.type;
  }

  const [deleteNotification] = useDeleteNotificationsMutation({
    variables: {
      memberid: activeMemberId,
      notifications: [
        {
          notification_status: props.notification?.notification_status,
          reference_id: props.notification?.reference_id,
          type: notification_type
        }
      ]
    },
    onCompleted: () => {
      history.push('/notifications/', { onNotificationDeleted: true });
    },
    refetchQueries: ['getNotifications']
  });
  function deleteNotificationAndRefreshResults() {
    deleteNotification();
    handleConfirmationModalClose();
  }

  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const handleConfirmationModalOpen = () => setConfirmationModalOpen(true);
  const handleConfirmationModalClose = () => setConfirmationModalOpen(false);
  var startDate;
  var uniqueSessionStatesString;
  if (props.activity) {
    startDate = format(parseISO(`${props.activity?.sessionSeriesStartDate}`), 'eee do MMM Y - p');
    const sessionList = (props.activity?.sessions ?? []) as CpdActivitySession[];
    uniqueSessionStatesString = getUniqueSessionStatesString(sessionList);
  }

  return (
    <Box>
      <IconButton onClick={() => handleConfirmationModalOpen()}>
        <Clear sx={{ fontSize: '24px' }} />
      </IconButton>
      <Modal
        open={confirmationModalOpen}
        onClose={handleConfirmationModalClose}
        aria-labelledby="delete notification"
        aria-describedby="delete notification"
      >
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            width: '90%',
            maxWidth: '600px'
          }}
        >
          <Typography variant="h3">Dismiss Notification</Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            Are you sure you wish to
            <span style={{ fontWeight: 600 }}> dismiss notification?</span> <br />
            You cannot undo this operation.
          </Typography>

          {props.activity && (
            <Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" color="secondary">
                {startDate}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {props.activity?.title}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 600, mt: 2 }}>
                {props.activity?.providerName}
              </Typography>
              <Typography variant="body2">{uniqueSessionStatesString}</Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => handleConfirmationModalClose()}
              variant="text"
              sx={{ color: '#6F6F6F' }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleteNotificationAndRefreshResults()}
              variant="text"
              sx={{ color: 'primary.main' }}
            >
              Dismiss notification
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export { RemoveNotificationButton };
