import { Alert, AlertTitle, Box, Container, Link, Typography } from '@mui/material';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const ICON_COLOR = 'rgb(42, 102, 198)';

export const UserNotAssignedError = () => {
  const membershipPageUrl = process.env.REACT_APP_MEMBER_HOME_URL + '/racgp-membership/membership';
  return (
    <Container maxWidth={'lg'}>
      <Box my={5}>
        <Alert severity="warning">
          <AlertTitle>Access denied</AlertTitle>
          <br />
          <Typography variant={'body2'} component="p">
            Your current membership category does not include access to RACGP's myCPD Home.
            <br />
            To upgrade your membership to include access to myCPD or if you believe you are seeing{' '}
            this message in error, please contact our Member Services team.
          </Typography>

          <Typography variant={'body2'} component="div">
            <br />
            <Box display={'flex'} mb={1} color={ICON_COLOR}>
              <MailOutlineIcon fontSize={'small'} sx={{ mr: 1 }} />
              <Link href={'mailto: racgp@racgp.org.au'}> racgp@racgp.org.au</Link>
            </Box>
            <Box display={'flex'} mb={1} color={ICON_COLOR}>
              <PhoneEnabledIcon fontSize={'small'} sx={{ mr: 1 }} />
              <Link href={'tel:1800472247'}>1800 4RACGP | 1800 472 247</Link>
            </Box>
            <Box display={'flex'} mb={1} color={ICON_COLOR}>
              <PhoneEnabledIcon fontSize={'small'} sx={{ mr: 1 }} />
              <Link href={'tel:+61386990300'}>International +61 (03) 8699 0300</Link>
            </Box>
          </Typography>
        </Alert>
      </Box>
    </Container>
  );
};
