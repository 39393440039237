import React, { useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FilterByFormControlLabel } from '../../Layout/FilterByCheckBox';
import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { FacetCount } from './FacetCount';
import { FACET_NAMES, FACET_VALUES_CONTENT_TYPE_LIST } from './constants';

const FilterByProvider = () => {
  const { providerTypes, setProviderTypes, setLastChangeFacet } = useContext(
    BrowseContext
  ) as BrowseContextType;

  const handleChange = (event: any) => {
    const isChecked = event.target.checked;
    setLastChangeFacet(FACET_NAMES.ProviderType);
    if (isChecked) {
      setProviderTypes([...providerTypes, event.target.value]);
    } else {
      const newProviderTypes = providerTypes.filter((value) => value !== event.target.value);
      setProviderTypes(newProviderTypes);
    }
  };

  const isChecked = (value: string): boolean => {
    return providerTypes.includes(value);
  };

  return (
    <Box>
      <Accordion defaultExpanded={true} elevation={0} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="provider-content"
          id="provider-header"
          sx={{ minHeight: 'initial' }}
        >
          <Typography variant="subtitle2" color="primary">
            Provider {providerTypes.length > 0 && '(' + providerTypes.length + ')'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <FormGroup>
            {FACET_VALUES_CONTENT_TYPE_LIST.map((type) => (
              <FilterByFormControlLabel
                key={type.value}
                sx={{ width: '100%' }}
                control={
                  <Checkbox
                    onChange={handleChange}
                    value={type.value}
                    checked={isChecked(type.value)}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box>{type.label}</Box>
                    <FacetCount
                      facetName={FACET_NAMES.ProviderType}
                      facetValueString={type.value}
                    />
                  </Box>
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { FilterByProvider };
