import { Box, Button, Container, Typography, Link as MuiLink, SvgIcon } from '@mui/material';
import { colors } from '../App/colors';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom';

import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ContentPageTitle } from '../ContentPageTitle/ContentPageTitle';

const HelpEntry: React.FC<{ label: string; href: string; Icon: typeof SvgIcon }> = ({
  Icon,
  label,
  href
}) => {
  return (
    <Box display={'flex'} mb={1}>
      <Icon color={'primary'} fontSize={'medium'} sx={{ mr: 1 }} />
      <MuiLink href={href}>
        <Typography color={colors['charcoal.800']} variant={'body1'}>
          {label}
        </Typography>
      </MuiLink>
    </Box>
  );
};

export const PageErrorFallback = () => {
  return (
    <>
      <ContentPageTitle currentPageTitle="Error" showImpersonate={false} />
      <Container maxWidth={'lg'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} my={11}>
          <Box sx={{ borderRadius: 200, p: 1.5, bgcolor: colors['charcoal.300'], mb: 2 }}>
            <Box style={{ width: '24px', height: '24px' }}>
              <ErrorIcon
                sx={{
                  width: '100%',
                  height: '100%',
                  color: colors['charcoal.700']
                }}
              />
            </Box>
          </Box>
          <Typography
            textAlign={'center'}
            color={'primary'}
            variant={'body1'}
            fontWeight={600}
            maxWidth={'80%'}
          >
            Sorry but it looks like there is an unexpected issue with the website
          </Typography>
          <Typography
            textAlign={'center'}
            color={colors['charcoal.700']}
            variant={'body2'}
            mt={{ xs: 2, sm: 0.5 }}
            maxWidth={'80%'}
          >
            Please return to the myCPD home page or try visiting at a later time.
          </Typography>

          <Box display={'flex'}>
            <Button
              variant="outlined"
              component={Link}
              to={'/'}
              sx={{ my: 4, px: { xs: 2, sm: 4 } }}
            >
              Go to myCPD home page
            </Button>
          </Box>

          <Box bgcolor={colors['white.300']} px={4} py={3}>
            <Typography variant={'body1'} fontWeight={600} color={'primary'} mb={3}>
              If you are continuing to get this error please let us know
            </Typography>
            <HelpEntry
              label={'1800 4RACGP | 1800 472 247'}
              href={'tel:1800472247'}
              Icon={PhoneIcon}
            />
            <HelpEntry
              label={'International +61 (03) 8699 0300'}
              href={'tel:+61386990300'}
              Icon={PhoneIcon}
            />
            <HelpEntry
              label={'cpd@racgp.org.au'}
              href={'mailto: cpd@racgp.org.au'}
              Icon={MailOutlineIcon}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};
