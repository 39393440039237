import React, { useContext, useEffect } from 'react';
import { Box, Menu, Typography, Divider, useTheme, useMediaQuery, Drawer } from '@mui/material';
import { SxProps } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useGetMemberQuery, useGetNotificationsQuery } from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { HeaderButton } from './HeaderButton';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import CloseIcon from '@mui/icons-material/Close';
import { DrawerMenuMyAccount } from './DrawerMenuMyAccount';

const navLinkParentStyles: SxProps = {
  'a.active .MuiSvgIcon-root': {
    color: 'text.primary'
  }
};

const RightNavigation = () => {
  const handleError = useErrorHandler();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean | undefined>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsDrawerOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsDrawerOpen(false);
  };

  const { memberid } = useContext(AuthContext) as AuthContextType;
  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;
  const { data } = useGetMemberQuery({
    variables: { memberid: memberid }, // use memberid here because we want logged in user not impersonated user
    onError: handleError
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const firstName = `${data?.getMember?.first_name}`;
  const lastName = `${data?.getMember?.last_name}`;
  const { data: getNotificationsData } = useGetNotificationsQuery({
    variables: {
      memberid: activeMemberId,
      from: 0,
      limit: 0
    },
    fetchPolicy: 'cache-and-network'
  });

  const location = useLocation();
  useEffect(() => {
    const checkMyaccountDrawerOpen = location.state || {};
    if (checkMyaccountDrawerOpen) {
      handleClose();
    }
  }, [location]);

  return (
    <>
      <Box sx={{ display: 'flex', float: 'right' }}>
        <Box sx={[{ display: { md: 'flex' }, alignItems: 'center', gap: 2 }, navLinkParentStyles]}>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <NavLink
              to="/bookmarks"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px'
              }}
            >
              <HeaderButton IconComponent={BookmarkIcon} />
            </NavLink>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <NavLink
              to="/notifications"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px'
              }}
            >
              <HeaderButton
                hasBadge={true}
                badgeCount={getNotificationsData?.getNotifications?.unread}
                IconComponent={NotificationsIcon}
              />
            </NavLink>
          </Box>
          <HeaderButton
            paperProps={{
              onClick: handleClick,
              sx: { borderWidth: { xs: 0, md: 1 } }
            }}
            IconComponent={AccountCircleIcon}
            label={matches ? undefined : 'My Account'}
          />
        </Box>
      </Box>
      <Menu
        sx={{
          display: { xs: 'none', md: 'flex' }
        }}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            minWidth: 274,
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box p={2}>
          <Typography mb={1} variant={'body2'} fontWeight={600}>
            {firstName} {lastName}
          </Typography>
          <Typography variant={'body2'}>RACGP no. {memberid}</Typography>
        </Box>
        <Divider></Divider>
        <DrawerMenuMyAccount />
      </Menu>
      <Drawer
        open={isDrawerOpen}
        anchor={'right'}
        PaperProps={{
          sx: { width: { xs: '100%', sm: 'auto' }, minWidth: { sm: '390px' } }
        }}
        sx={{
          display: { xs: 'flex', md: 'none' }
        }}
      >
        <Box p={2} sx={{ display: 'flex' }}>
          <Box>
            <Typography mb={1} variant={'body2'} fontWeight={600}>
              {firstName} {lastName}
            </Typography>
            <Typography variant={'body2'}>RACGP no. {memberid}</Typography>
          </Box>
          <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
            <CloseIcon cursor={'pointer'} onClick={handleClose} />
          </Box>
        </Box>
        <Divider></Divider>
        <DrawerMenuMyAccount />
      </Drawer>
    </>
  );
};
export { RightNavigation };
