import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Box, Container, Paper, Button, Typography, Chip, Alert, Link } from '@mui/material';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { PdpLoading } from './PdpLoading';
import { PdpStatus, PdpType, useGetPdpDetailsQuery } from 'src/graphql/generated';

const HomeTemplate = () => {
  const { year } = useParams<{ year: string }>();
  const [submitStatus, setSubmitStatus] = React.useState<PdpStatus>();
  const [pdpType, setPdpType] = React.useState<PdpType>();
  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;

  const { loading, error: errorQuery } = useGetPdpDetailsQuery({
    variables: { memberid: activeMemberId, pdp_year: year },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setPdpType(data?.getPdpDetails?.pdp_type as PdpType);
      setSubmitStatus(data?.getPdpDetails?.pdp_status as PdpStatus);
    }
  });

  if (loading) {
    return <PdpLoading />;
  }

  if (errorQuery) {
    return (
      <>
        <Container maxWidth="lg">
          <Paper sx={{ p: 2, mt: 2 }}>
            <Alert severity="error">{errorQuery.message}</Alert>
          </Paper>
        </Container>
      </>
    );
  }
  return (
    <>
      {submitStatus === PdpStatus.PdpDraft && (
        <Container maxWidth="lg">
          <Box sx={{ m: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              The{' '}
              <Link
                href="https://www.medicalboard.gov.au/Registration-Standards.aspx"
                target="_blank"
              >
                Medical Board of Australia’s new CPD Registration Standard
              </Link>{' '}
              requires all registered doctors to complete an annual PDP. For more ideas, access the{' '}
              <Link
                href="https://www.racgp.org.au/FSDEDEV/media/documents/CPD/RACGP-CPD-Solutions.pdf"
                target="_blank"
              >
                RACGP's CPD Solutions
              </Link>
              .
            </Typography>
            <Typography variant="body1">
              A PDP is a tool for planning your annual CPD based on your scope of practice and
              learning goals, ending with self-reflection on its impact on your practise.
            </Typography>
            <Typography variant="h2" color="primary" sx={{ mt: 2, fontSize: '1.25rem' }}>
              Select an option
            </Typography>
            <Typography variant="body1">
              To create your PDP, please select from the below options.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 4, flexDirection: { xs: 'column', sm: 'row' } }}>
            <Paper sx={{ flex: 1, m: 2 }} elevation={3}>
              <Button
                sx={{
                  p: 3,
                  borderRadius: 'initial',
                  textAlign: 'left',
                  height: '100%'
                }}
                component={NavLink}
                to={{ pathname: `/pdp/${year}`, state: { selectedTemplate: 'template' } }}
              >
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 2,
                      minHeight: '32px'
                    }}
                  >
                    <BallotOutlinedIcon color="secondary" />
                    {pdpType === 'template' && <Chip label="In Progress" color="secondary" />}
                  </Box>
                  <Typography color="primary" variant="body1" fontWeight={600} sx={{ pb: 0.5 }}>
                    RACGP PDP Tool
                  </Typography>
                  <Typography variant="body1">
                    An RACGP developed tool that assists you with your annual CPD planning and
                    reflection.
                  </Typography>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: 'auto',
                      pt: 2
                    }}
                    variant="body1"
                    fontWeight={600}
                    color="primary"
                  >
                    Use RACGP PDP tool
                    <KeyboardArrowRightOutlinedIcon color="primary" />
                  </Typography>
                </Box>
              </Button>
            </Paper>
            <Paper sx={{ flex: 1, m: 2 }} elevation={3}>
              <Button
                sx={{
                  p: 3,
                  borderRadius: 'initial',
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                  height: '100%',
                  width: '100%'
                }}
                component={NavLink}
                to={{ pathname: `/pdp/${year}`, state: { selectedTemplate: 'upload' } }}
              >
                <Box
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 2,
                      minHeight: '32px'
                    }}
                  >
                    <UploadFileOutlinedIcon color="secondary" />
                    {pdpType === 'upload' && <Chip label="In Progress" color="secondary" />}
                  </Box>
                  <Typography color="primary" variant="body1" fontWeight={600}>
                    Upload your own
                  </Typography>
                  <Typography variant="body1">
                    You may upload your own PDP e.g., Word or PDF document.
                  </Typography>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: 'auto',
                      pt: 2
                    }}
                    variant="body1"
                    fontWeight={600}
                    color="primary"
                  >
                    Upload <KeyboardArrowRightOutlinedIcon color="primary" />
                  </Typography>
                </Box>
              </Button>
            </Paper>
          </Box>
        </Container>
      )}
      {submitStatus === PdpStatus.PdpSubmitted && (
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', mb: 4 }}>
            <Paper sx={{ m: 2, width: '100%' }} elevation={3}>
              <Button
                sx={{
                  p: 3,
                  borderRadius: 'initial',
                  textAlign: 'left',
                  display: 'block',
                  width: '100%'
                }}
                component={NavLink}
                to={{ pathname: `/pdp/${year}`, state: { selectedTemplate: pdpType } }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    color="primary"
                    variant="body1"
                    component={'h2'}
                    fontWeight={600}
                    sx={{ pb: 0.5 }}
                  >
                    {year} PDP Submission
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant="body1">
                      You can edit PDP files after submission, you won’t lose or gain hours if
                      re-submit.
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: 'auto',
                        pt: 2
                      }}
                      variant="body1"
                      fontWeight={600}
                      color="primary"
                    >
                      Edit PDP <KeyboardArrowRightOutlinedIcon color="primary" />
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Paper>
          </Box>
        </Container>
      )}
    </>
  );
};

export { HomeTemplate };
