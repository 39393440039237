import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

interface Props {
  heading: string;
  description: string;
  CardIcon?: any;
  CardImage?: { url: string; styles: {} };
  ButtonIcon: any;
  button: {
    cta: string;
    link: { url: string; target: string };
  };
  IsNew: boolean;
}

const MultiColumnCTALinkCard: React.FC<Props> = (props) => {
  const CardIcon = props.CardIcon;
  const ButtonIcon = props.ButtonIcon;

  return (
    <Paper
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        p: 3,
        mt: 3
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          {props.CardImage && (
            <img src={props.CardImage.url} alt="logo" style={{ ...props.CardImage.styles }} />
          )}
          {CardIcon && <CardIcon sx={{ width: '2rem', height: '2rem', color: 'secondary.main' }} />}
        </Box>
        <Box>
          {props.IsNew && (
            <Typography color="secondary.main" variant="body1" sx={{ fontWeight: 600 }}>
              NEW
            </Typography>
          )}
        </Box>
      </Box>
      <Typography
        color="primary.main"
        variant="body1"
        component={'h3'}
        sx={{ mb: 1, fontWeight: 600 }}
      >
        {props.heading}
      </Typography>
      <Typography sx={{ mb: 2, flexGrow: 1 }} variant="body2">
        {props.description}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <a
          href={props.button.link.url}
          target={props.button.link.target}
          style={{ fontSize: '15px', fontWeight: 600, display: 'flex', alignItems: 'center' }}
        >
          {props.button.cta} <ButtonIcon sx={{ ml: 1 }} />
        </a>
      </Box>
    </Paper>
  );
};

export { MultiColumnCTALinkCard };
