import { Box, Button, Typography, Icon } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Tile } from 'src/components/Surface/Title';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type FindNewActivityProps = {
  icon: React.ElementType;
  title: string;
  content: string;
  buttonLink: string;
  buttonText: string;
};

export const FindNewActivityTile = ({
  icon,
  title,
  content,
  buttonLink,
  buttonText
}: FindNewActivityProps) => {
  return (
    <Button
      component={NavLink}
      to={buttonLink}
      sx={{ width: '100%', borderRadius: 0, p: 0, mt: 2 }}
    >
      <Tile sx={{ width: '100%' }}>
        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon component={icon} sx={{ mr: 1, color: 'secondary.main' }} fontSize="small" />
          {title}
        </Typography>
        <Typography variant="body2">{content}</Typography>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
          <Typography sx={{ color: 'primary.main', fontWeight: '600' }}>{buttonText}</Typography>{' '}
          <ChevronRightIcon style={{ fontSize: 24 }} />
        </Box>
      </Tile>
    </Button>
  );
};
