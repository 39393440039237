import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';

const ClearFilters = () => {
  const { clearFilters, canClearFilter } = useContext(BrowseContext) as BrowseContextType;

  return (
    <Box>
      <Button
        onClick={clearFilters}
        startIcon={<RefreshIcon sx={{ ml: 1 }} fontSize="large" />}
        disabled={!canClearFilter}
      >
        Clear Filters
      </Button>
    </Box>
  );
};

export { ClearFilters };
