import { Tooltip as MuiTooltip } from '@mui/material';
import { colors } from 'src/components/App/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type TooltipProps = {
  tooltip: string;
};

export const Tooltip = ({ tooltip }: TooltipProps) => {
  return (
    <MuiTooltip
      enterTouchDelay={0}
      title={tooltip}
      sx={{ maxWidth: 20, maxHeight: 20, color: colors['charcoal.900'] }}
    >
      <InfoOutlinedIcon />
    </MuiTooltip>
  );
};
