import React from 'react';
import {
  Box,
  CardActionArea,
  Typography,
  Drawer,
  Container,
  useTheme,
  useMediaQuery
} from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Tile } from 'src/components/Surface/Title';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import { LoadableText } from 'src/components/Loadable/LoadableText';
import { CategoryGroupRequirement } from '../types';
import { colors } from 'src/components/App/colors';
import { CompletedCheckmark } from '../components/CompletedCheckmark';
import { PlrItem } from 'src/graphql/generated';
import { PlrAccordion } from '../components/PlrAccordion';
import { FindNewActivityTile } from '../components/FindNewActivityTile';

type PLRRequirementType = {
  code: string;
  description: string;
};

// hard code codes for now. Need to move this to backend :(
const plrRequirementList: PLRRequirementType[] = [
  { code: 'CSP', description: 'Culturally Safe Practice' },
  { code: 'HI', description: 'Health Inequities' },
  { code: 'EP', description: 'Professionalism and Ethical Practice' }
];

type PLRRequirementProps = {
  categoryGroupRequirement?: CategoryGroupRequirement;
  activeCycle: string;
};
export const PLRRequirement = ({ categoryGroupRequirement, activeCycle }: PLRRequirementProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const completedPlrCodes = (categoryGroupRequirement?.program_levels as PlrItem[]) || [];
  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <>
      <CardActionArea onClick={() => setOpenDrawer(true)} sx={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            width: '100%',
            height: '100%',
            border: `1px solid ${colors['stone.100']}`,
            backgroundColor: 'white',
            px: 3,
            py: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LoadableText loading={!categoryGroupRequirement} variant={'caption'}>
              {categoryGroupRequirement?.completed ? (
                <Box display={'flex'} fontWeight={600} alignItems={'center'} gap={1}>
                  <CompletedCheckmark
                    sx={{ width: 13, height: 13 }}
                    size={'small'}
                    completed={true}
                  />
                  Completed {!categoryGroupRequirement}
                </Box>
              ) : (
                <Box color={colors['charcoal.700']}>Incomplete</Box>
              )}
            </LoadableText>
            <ChevronRightOutlinedIcon sx={{ color: 'primary.main', ml: 'auto', mr: -1 }} />
          </Box>
          <LoadableText
            loading={!categoryGroupRequirement}
            variant={'h4'}
            sx={{ fontWeight: '600', width: '100%', pt: 0.5 }}
          >
            Program-Level Requirements
          </LoadableText>
          <LoadableText loading={!categoryGroupRequirement} mt={0.5} variant={'caption'}>
            <Box>Complete by 31 Dec {activeCycle}</Box>
          </LoadableText>
        </Box>
      </CardActionArea>
      <Drawer
        variant="temporary"
        open={openDrawer}
        anchor={'right'}
        onClose={() => setOpenDrawer(false)}
      >
        <Container sx={{ pb: 5, pt: 3 }} maxWidth={'sm'}>
          <Box display={'flex'} mb={2} sx={{ alignItems: 'baseline' }}>
            <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'primary.main', flex: 1 }}>
              Program-Level Requirements
            </Typography>
            <CloseIcon cursor={'pointer'} onClick={() => setOpenDrawer(false)} />
          </Box>
          <Box>
            <Tile sx={{ mt: 2 }}>
              {plrRequirementList.map((PLRRequirement, index) => {
                const hasCompletedPlrCode = completedPlrCodes.some(
                  (completedPlrCode) => completedPlrCode === PLRRequirement.code
                );
                return (
                  <Box
                    key={index}
                    mt={1}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems="center"
                  >
                    <Box display={'flex'} alignItems="center">
                      {hasCompletedPlrCode && (
                        <CheckCircleIcon color="secondary" sx={{ mr: 0.5, fontSize: '16px' }} />
                      )}
                      <Typography variant="body2">{PLRRequirement.description}</Typography>
                    </Box>
                    <Typography variant={'caption'} fontWeight={hasCompletedPlrCode ? 600 : 400}>
                      {hasCompletedPlrCode ? 'Completed' : 'Incomplete'}
                    </Typography>
                  </Box>
                );
              })}
            </Tile>
          </Box>
          <Box mt={3}>
            <Typography mb={0.5} variant={'h2'} sx={{ color: 'primary.main' }}>
              What you need to do annually
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              You should consider inclusion of these CPD program-level requirements into your
              Professional Development Plan (PDP), however, there is no prescribed specific CPD
              hours completion associated with these activities.
            </Typography>
          </Box>
          <Box
            sx={{
              '.MuiAccordion-root': {
                boxShadow: 'none',
                border: '1px solid #D2DCE1',
                borderRadius: 1,
                '&:before': {
                  height: 0
                }
              }
            }}
          >
            <PlrAccordion
              id="cfp"
              title="Culturally Safe Practice"
              content="Cultural safety is defined by Aboriginal and Torres Strait Islander groups. It
                  involves practitioners critically reflecting on their approach to provide
                  racism-free, accessible, and responsive healthcare."
              buttonLink="https://www.racgp.org.au/education/professional-development/cpd/cpd-program-level-requirements/cultural-safety"
            />
            <PlrAccordion
              id="healthInequities"
              title="Health Inequities"
              content="Health inequities vary by specialty and location. Good medical practice
                  acknowledges a practitioner's role in promoting equitable health for Aboriginal
                  and Torres Strait Islander patients."
              buttonLink="https://www.racgp.org.au/education/professional-development/cpd/cpd-program-level-requirements/health-inequities"
            />
            <PlrAccordion
              id="pep"
              title="Professionalism and Ethical Practice"
              content="A GP's social contract with the community entails mutual trust, respecting
                  doctor-patient boundaries, upholding clinical excellence, promoting health, and
                  prioritising patient interests."
              buttonLink="https://www.racgp.org.au/education/professional-development/cpd/cpd-program-level-requirements/professionalism-and-ethical-practice"
            />
          </Box>
          <Box sx={{ mt: 4, width: '100%' }}>
            <Typography mb={2} variant={'h2'} sx={{ color: 'primary.main' }}>
              Completing your Program-Level Requirements
            </Typography>
            <Typography mb={2}>
              Program-level requirements are included in your annual 50 hours and PDP.
            </Typography>
            <FindNewActivityTile
              icon={MenuBookIcon}
              title="Professional Development Plan (PDP)"
              content="Reflect on each of the program-level requirements through your PDP."
              buttonLink={`/pdp/${activeCycle}`}
              buttonText="View and Update PDP"
            />
            <FindNewActivityTile
              icon={AddCircleOutlineIcon}
              title="Quick Log"
              content="Instantly log any activities by ticking the relevant program-level requirements."
              buttonLink="/log"
              buttonText="Quick Log"
            />
            <FindNewActivityTile
              icon={SearchIcon}
              title="Browse Activities"
              content="Filter by CPD program-level requirements to find activities that suit you."
              buttonLink="/browse"
              buttonText="Browse Activities"
            />
          </Box>
          <Box
            sx={{
              '.MuiAccordion-root': {
                boxShadow: 'none',
                border: '1px solid #D2DCE1',
                borderRadius: 1,
                '&:before': {
                  height: 0
                }
              },
              a: {
                textDecoration: 'underline'
              },
              my: 2
            }}
          >
            <Typography mb={2} variant={'h2'} sx={{ color: 'primary.main' }}>
              FAQ's
            </Typography>
            <PlrAccordion
              id="whatplrs"
              title="What are program-level requirements?"
              content="The new <a href='https://www.medicalboard.gov.au/Professional-Performance-Framework/CPD/Professional-Development-Plans.aspx' target='_blank'>MBA CPD Registration Standard</a> requires all registered medical practitioners to engage in CPD activities relevant to: 
                        <ul>
                          <li>culturally safe practice.</li>
                          <li>health inequities.</li>
                          <li>professionalism and ethical practice.</li>
                        </ul>
                        "
            />
            <PlrAccordion
              id="howplrs"
              title="How do program-level requirements fit into my CPD?"
              content="Program-level requirements are <strong>not</strong> additional to, but are part of your 50 hours CPD. Each year, you must include at least one activity in your CPD that addresses each of these areas relevant to your role and scope of practice."
            />
            <PlrAccordion
              id="meetplrs"
              title="How can I meet these requirements?"
              content="We’ve made it simple for you by including a reflection in your PDP that once completed, meets these requirements. We have also added links to activities that may interest you and you can browse activities under these subject areas in myCPD.<br><br>If you use quick log to record a CPD activity relating to one or more of the program level requirements, you’ll be able to simply tick a box and it will be added to your record."
            />
            <PlrAccordion
              id="minhoursplrs"
              title="What is the minimum number of hours that I must complete for program-level requirements?"
              content="You must complete one activity from each program level area each year. This may be just the reflection that is in your PDP or you may choose to do more."
            />
            <PlrAccordion
              id="selectplrs"
              title="Can I select activities that align with my specific needs?"
              content="Yes, you should select activities that meet your professional scope of practice."
            />
            <PlrAccordion
              id="findplrs"
              title="Where can I find more information about program-level requirements?"
              content="Further details are available on the <a href='https://www.racgp.org.au/education/professional-development/cpd/cpd-program-level-requirements' target='_blank'>RACGP website</a>."
            />
            <PlrAccordion
              id="reportplrs"
              title="Is my compliance with the program-level requirements reported to AHPRA?"
              content="The RACGP is required to report on every CPD home members' compliance with the CPD hours, PDP, specialist high-level requirements and <a href='https://www.racgp.org.au/education/professional-development/cpd/cpd-program-level-requirements' target='_blank'>program-level requirements</a>."
            />
          </Box>
        </Container>
      </Drawer>
    </>
  );
};
