import { FormControlLabel, FormControlLabelProps } from '@mui/material';
export const FilterByFormControlLabel = (props: FormControlLabelProps) => (
  <FormControlLabel
    {...props}
    sx={{
      marginRight: '0',
      '& .MuiFormControlLabel-label': { fontSize: '0.875rem', width: '100%' },
      '& .MuiCheckbox-root': { paddingBottom: 0.875 },
      svg: { fontSize: '1.125rem' }
    }}
  />
);
