import { CardActionArea, CardActionAreaProps, Typography, Badge } from '@mui/material';

type HeaderButtonProps = {
  IconComponent: any;
  label?: string;
  paperProps?: CardActionAreaProps;
  hasBadge?: Boolean;
  badgeCount?: number;
};

export const HeaderButton = ({
  IconComponent,
  label,
  paperProps,
  hasBadge,
  badgeCount
}: HeaderButtonProps) => {
  const { sx, ...rest } = paperProps || {};
  return (
    <CardActionArea
      sx={{
        p: 1.5,
        borderColor: '#F0F0F0',
        borderRadius: '8px',
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx
      }}
      {...rest}
    >
      <IconComponent sx={{ maxHeight: 22, maxWidth: 22, color: 'primary.main' }} />
      {label && (
        <Typography ml={1} variant={'body2'} sx={{ fontSize: '1rem' }}>
          {label}
        </Typography>
      )}
      {hasBadge && (
        <Badge
          sx={{
            top: '-22px',
            right: '-12px'
          }}
          badgeContent={badgeCount}
          color="error"
        ></Badge>
      )}
    </CardActionArea>
  );
};
