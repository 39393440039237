import React from 'react';
import { Container, Typography, Divider } from '@mui/material';
import { ContentPageTitle } from '../../ContentPageTitle/ContentPageTitle';
import { ProvidersList } from './ProvidersList';

const MajorCPDProvidersPage = () => {
  return (
    <>
      <ContentPageTitle currentPageTitle="Major CPD Providers" />
      <Container maxWidth="lg">
        <Typography variant="body1" sx={{ my: 3 }}>
          Our major CPD providers offer the highest quality GP education covering key, common and
          important topics delivered in a range of formats across all CPD activity areas. Each major
          CPD provider delivers a unique offering - endorsed and quality assured for you by the
          RACGP. ​
        </Typography>
        <ProvidersList tierType="Tier3" />
        <Divider sx={{ mb: 3 }} />
        <ProvidersList tierType="Tier2" />
        <Divider sx={{ mb: 3 }} />
        <ProvidersList tierType="Tier1" />
      </Container>
    </>
  );
};
export { MajorCPDProvidersPage };
