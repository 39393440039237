import React, { useContext } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Alert
} from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CloseIcon from '@mui/icons-material/Close';

import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import {
  LearningInterest,
  LearningInterestInput,
  useGetLearningInterestsQuery
} from 'src/graphql/generated';
import { EditAreasOfInterestsList } from 'src/components/EditAreasOfInterestsList/EditAreasOfInterestsList';
import { FACET_NAMES } from './constants';

const modalBox = {
  position: 'absolute' as 'absolute',
  top: '5%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: '80%',
  maxWidth: '700px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  height: '90%',
  overflow: 'hidden'
};
const FilterByAreasOfInterests = () => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const { areasOfInterests, setAreasOfInterests, setLastChangeFacet } = useContext(
    BrowseContext
  ) as BrowseContextType;
  const [selectedAreasOfInterest, setSelectedAreasOfInterest] = React.useState<
    LearningInterestInput[]
  >([]);
  const [preSelectedAreasOfInterest, setPreSelectedAreasOfInterest] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { error } = useGetLearningInterestsQuery({
    variables: { memberid: activeMemberId },
    onCompleted: (data) => {
      const areasOfInterestList = (data?.getLearningInterests?.items ?? []) as LearningInterest[];
      const areasOfInterestListSorted = [...areasOfInterestList].sort((a: any, b: any) =>
        a.learning_interest > b.learning_interest ? 1 : -1
      );
      setSelectedAreasOfInterest(areasOfInterestListSorted);
    }
  });

  React.useEffect(() => {
    const InterestsCount = selectedAreasOfInterest?.filter((preSelectedInterests: any) =>
      Boolean(preSelectedInterests.status)
    ).length;
    setPreSelectedAreasOfInterest(InterestsCount);
  }, [selectedAreasOfInterest]);

  /* Filter query variables */
  const handleFilterChange = (event: any) => {
    const isChecked = event.target.checked;
    setLastChangeFacet(FACET_NAMES.CurriculumContextualUnitsName);
    if (isChecked) {
      setAreasOfInterests([...areasOfInterests, event.target.value]);
    } else {
      const newFilterAreasOfInterest = areasOfInterests.filter(
        (value) => value !== event.target.value
      );
      setAreasOfInterests(newFilterAreasOfInterest);
    }
  };

  const isCheckedForFilter = (value: string): boolean => {
    return areasOfInterests.includes(value);
  };

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle2" color="primary">
            Areas of Interests
          </Typography>
          <Alert severity="error">
            Something went wrong, please refresh the page and try again
          </Alert>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle2" color="primary">
          Areas of Interests
        </Typography>
        <Button onClick={handleOpen} sx={{ marginLeft: 'auto', minWidth: 'initial', p: 0 }}>
          <ControlPointIcon sx={{ color: 'secondary.main' }} />
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalBox}>
            <Box sx={{ display: 'flex' }} id="modal-modal-title">
              <Typography variant="h2" sx={{ color: 'primary.main' }}>
                Edit Interests
              </Typography>
              <CloseIcon onClick={handleClose} sx={{ marginLeft: 'auto', cursor: 'pointer' }} />
            </Box>
            <Box id="modal-modal-description">
              <Typography variant="body2" sx={{ mt: 2 }} gutterBottom>
                Select each of your learning interests for a refined search based on your
                preferences.
              </Typography>
              <Typography variant="h4" sx={{ mt: 2 }} gutterBottom>
                Add Learning Interests
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }} gutterBottom>
                Interests are automatically saved to your profile.
              </Typography>
            </Box>
            <Box
              sx={{
                overflowX: 'hidden',
                overflowY: 'scroll',
                height: '70%'
              }}
            >
              <EditAreasOfInterestsList />
            </Box>
          </Box>
        </Modal>
      </Box>
      <Box sx={{ maxWidth: '100%' }}>
        {preSelectedAreasOfInterest > 0 ? (
          <FormGroup sx={{ display: 'inline-flex', flexDirection: 'row', px: 1 }}>
            {selectedAreasOfInterest.map(
              (selectedInterests: any) =>
                selectedInterests.status && (
                  <FormControlLabel
                    key={selectedInterests?.learning_interest_id}
                    sx={{
                      'span~span': {
                        display: 'flex',
                        minHeight: '32px',
                        mb: 1.5,
                        mr: 1,
                        backgroundColor: '#F5F5F5',
                        border: '1px solid #D2DCE1',
                        fontSize: 'small',
                        borderRadius: '16px',
                        alignItems: 'start',
                        textAlign: 'left',
                        px: '12px',
                        py: '6px'
                      },
                      'span.Mui-checked~span': {
                        backgroundColor: 'secondary.main',
                        color: 'white'
                      }
                    }}
                    control={
                      <Checkbox
                        onChange={handleFilterChange}
                        value={selectedInterests.learning_interest}
                        checked={isCheckedForFilter(selectedInterests.learning_interest)}
                        sx={{
                          mb: 1.5,
                          mr: 1,
                          backgroundColor: '#F5F5F5',
                          border: '1px solid #D2DCE1',
                          fontSize: 'small',
                          '&.MuiCheckbox-root': { display: 'none' }
                        }}
                      />
                    }
                    label={selectedInterests.learning_interest}
                  ></FormControlLabel>
                )
            )}
          </FormGroup>
        ) : (
          <Typography variant="body2">
            Add learning interests by clicking on the plus icon. This will help refine your search
            based on your preferences
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export { FilterByAreasOfInterests };
