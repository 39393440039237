import { Box, LinearProgress as MUILinearProgress } from '@mui/material';

type LinearProgressProps = {
  value: number;
  color?: string;
  showCompletedCheckmark?: boolean;
};

export const LinearProgress = (props: LinearProgressProps) => {
  const { value, color } = props;
  return (
    <Box position={'relative'} color={color}>
      <MUILinearProgress
        sx={{
          backgroundColor: '#D2DCE1',
          height: 8,
          borderRadius: 20,
          '& .MuiLinearProgress-bar': {
            backgroundColor: color
          }
        }}
        variant={'determinate'}
        value={value}
      />
    </Box>
  );
};
