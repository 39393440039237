import React, { useState, useEffect } from 'react';
import {
  Box,
  CardActionArea,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { InlineBox } from 'src/components/Layout/InlineBox';
import { colors } from 'src/components/App/colors';
import { Attachment, PdpStatus } from 'src/graphql/generated';
import { pull } from 'lodash';
import { FileErrors } from './FileErrors';
import { ACCEPTED_FILE_TYPES, MAX_UPLOAD_FILES, MAX_UPLOAD_FILE_SIZE } from './constants';
import { formatBytes } from './utils';

type AttachmentsProps = {
  onChangeAttachments: (attachments: Array<Attachment | File>) => void;
  attachments: Array<Attachment | File>;
  isEditing?: boolean;
  pdpSubmissionStatus?: PdpStatus;
  uploadText: string;
};

export const Attachments = ({
  onChangeAttachments,
  attachments,
  isEditing,
  pdpSubmissionStatus,
  uploadText
}: AttachmentsProps) => {
  const [fileError, setFileError] = useState<string | undefined>();

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize: MAX_UPLOAD_FILE_SIZE,
    onDropAccepted: (files) => {
      setFileError(undefined);
      let newAttachments = [...attachments];
      for (let file of files) {
        if (
          !attachments.find((a) => {
            if (!(a instanceof File)) {
              return false;
            }
            return a.name === file.name;
          })
        ) {
          newAttachments = [...newAttachments, file];
        }
      }
      if (newAttachments.length > MAX_UPLOAD_FILES) {
        setFileError(`Maximum of ${MAX_UPLOAD_FILES} files can be uploaded.`);
        newAttachments = newAttachments.slice(0, MAX_UPLOAD_FILES);
      }
      onChangeAttachments(newAttachments);
    },
    accept: ACCEPTED_FILE_TYPES
  });

  const onDeleteAttachment = (attachment: File | Attachment) => {
    onChangeAttachments(pull(attachments, attachment));
  };
  useEffect(() => {
    const errorMessageTimer = setTimeout(() => {
      setFileError(undefined);
    }, 5000);
    return () => {
      clearTimeout(errorMessageTimer);
    };
  }, [fileError]);
  return (
    <Box>
      <CardActionArea
        sx={{
          display: 'flex',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          flexDirection: 'column',
          backgroundColor: '#FAFAFA',
          minHeight: 148,
          alignItems: 'center',
          borderRadius: 1
        }}
        {...getRootProps()}
      >
        <CloudUploadOutlinedIcon sx={{ mb: 1, color: colors['charcoal.700'] }} />

        <input {...getInputProps()} />
        <Typography
          component={'span'}
          color={colors['charcoal.700']}
          mb={0.5}
          variant={'body2'}
          textAlign={'center'}
        >
          UPLOAD {uploadText} <br />
          Drag and drop or{' '}
          <InlineBox
            sx={{ textDecoration: 'underline' }}
            color={colors['charcoal.900']}
            fontWeight={700}
          >
            browse files
          </InlineBox>
        </Typography>
      </CardActionArea>
      <Typography mt={1} variant={'caption'} color={colors['charcoal.700']}>
        Upload a maximum of five (5) files. File size must be under{' '}
        {formatBytes(MAX_UPLOAD_FILE_SIZE)} per file.
      </Typography>

      {attachments && attachments.length > 0 && (
        <Box mt={2}>
          <Typography mb={1} variant={'subtitle2'} color={'primary.main'}>
            Attachments
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  background:
                    'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1'
                }}
              >
                <TableRow>
                  <TableCell>
                    <Typography color={'rgba(0, 0, 0, 0.5)'} variant={'caption'} fontWeight={600}>
                      File name
                    </Typography>
                  </TableCell>
                  {(!isEditing || pdpSubmissionStatus !== PdpStatus.PdpSubmitted) && (
                    <TableCell align="right">
                      <Typography color={'rgba(0, 0, 0, 0.5)'} variant={'caption'} fontWeight={600}>
                        File size
                      </Typography>
                    </TableCell>
                  )}

                  <TableCell align="right">
                    <Typography color={'rgba(0, 0, 0, 0.5)'} variant={'caption'} fontWeight={600}>
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attachments.map((attachment) => (
                  <TableRow
                    key={attachment instanceof File ? attachment.name : attachment.retrieval_key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        sx={{ wordBreak: 'break-all' }}
                        href={
                          attachment instanceof File
                            ? URL.createObjectURL(attachment)
                            : (attachment.url as string)
                        }
                        download={attachment instanceof File ? attachment.name : undefined}
                      >
                        {attachment instanceof File
                          ? attachment.name
                          : decodeURIComponent(attachment.attachment_name!)}
                      </Link>
                    </TableCell>
                    {(!isEditing || pdpSubmissionStatus !== PdpStatus.PdpSubmitted) && (
                      <TableCell align="right">
                        {attachment instanceof File ? formatBytes(attachment.size) : ''}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <IconButton onClick={() => onDeleteAttachment(attachment)}>
                        <DeleteIcon sx={{ color: '#B00020', maxWidth: 20, maxHeight: 20 }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {(fileError || fileRejections?.length > 0) && (
        <Box mt={1}>
          <FileErrors fileRejections={fileRejections} generalError={fileError} />
        </Box>
      )}
    </Box>
  );
};
