import React from 'react';
import LogoImg from 'src/assets/racgp-cpd-logo.png';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/">
      <img src={LogoImg} alt="logo" style={{ maxHeight: 34 }} />
    </Link>
  );
};
export { Logo };
