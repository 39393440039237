import React from 'react';
import { Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';

import { CpdActivitySession, SearchActivityItem, CpdActivityContent } from 'src/graphql/generated';

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
};

const ActivityStartDate: React.FC<Props> = ({ searchActivity }) => {
  const activity = searchActivity?.activity as CpdActivityContent;
  const sessionList = (activity.sessions || []) as CpdActivitySession[];
  const sessionListInFuture = sessionList.filter((session) => session.fromDateTime >= new Date());
  const nextSession = _.minBy(sessionListInFuture, (session) => session.fromDateTime);

  const dateToDisplay = nextSession?.fromDateTime ?? activity?.sessionSeriesStartDate;
  const endDateToDisplay = activity?.sessionSeriesEndDate;

  if (!dateToDisplay) {
    return <></>;
  }
  const startDate = format(parseISO(`${dateToDisplay}`), 'EEE, d MMM yyyy');
  const endDate = format(parseISO(`${endDateToDisplay}`), 'EEE, d MMM yyyy');

  return (
    <Typography variant="body2" color="secondary">
      {startDate}
      {endDate !== startDate && ' - ' + endDate}
    </Typography>
  );
};

export { ActivityStartDate };
