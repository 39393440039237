import { Box, Container, Typography, Paper } from '@mui/material';

import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { EditAreasOfInterestsList } from 'src/components/EditAreasOfInterestsList/EditAreasOfInterestsList';

export const EditInterests = () => {
  return (
    <>
      <ContentPageTitle currentPageTitle="Edit Interests" />

      <Container maxWidth={'lg'} sx={{ my: 2 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box sx={{ display: 'flex' }} id="modal-modal-title">
            <Typography variant="h2" sx={{ color: 'primary.main' }}>
              Edit Interests
            </Typography>
          </Box>
          <Box id="modal-modal-description">
            <Typography variant="body2" gutterBottom>
              Select each of your learning interests for a refined search based on your preferences.
            </Typography>
            <Typography variant="h4" sx={{ mt: 2 }} gutterBottom>
              Add Learning Interests
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }} gutterBottom>
              Interests are automatically saved to your profile.
            </Typography>
          </Box>
          <EditAreasOfInterestsList />
        </Paper>
      </Container>
    </>
  );
};
