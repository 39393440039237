import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material';

type ButtonProps = MUIButtonProps & {
  loading?: boolean;
};

export const Button = ({ loading, children, disabled, ...rest }: ButtonProps) => {
  return (
    <MUIButton disabled={disabled || loading} {...rest}>
      {loading && 'Loading ...'}
      {!loading && children}
    </MUIButton>
  );
};
