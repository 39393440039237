import React from 'react';
import { Box, Skeleton, Paper, Typography } from '@mui/material';
import _ from 'lodash';

import { useGetSearchActivityByIdQuery, SearchActivityItem } from 'src/graphql/generated';
import { ActivityResult } from 'src/components/Pages/Notifications/ActivityResult';
import { Notification } from 'src/graphql/generated';
type Props = {
  activityId: string | undefined;
  notification: Notification | null | undefined;
  sessionId: string | undefined;
};
const SearchActivtyItem: React.FC<Props> = (props) => {
  const { data, loading, error } = useGetSearchActivityByIdQuery({
    variables: { activityid: props.activityId ?? '' }
  });
  if (error) {
    return (
      <>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography>Something went wrong, please refresh the page and try again</Typography>
        </Paper>
      </>
    );
  }
  if (loading) {
    return (
      <>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Skeleton
            variant="rectangular"
            sx={{ mt: 2, width: '100px', borderRadius: '10px', bgcolor: 'grey.100' }}
          />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '30%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 1, width: '40%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '80%', bgcolor: 'grey.100' }} />
          <Skeleton
            sx={{
              width: '100px',
              borderRadius: '10px',
              bgcolor: 'grey.100',
              mt: 4
            }}
          />
        </Paper>
      </>
    );
  }

  const itemList = data?.searchActivity?.items ?? ([] as SearchActivityItem[]);
  const searchActivity = _.first(itemList) as SearchActivityItem;

  // TODO: deal with null or missing scenario. Consider validating an empty activityId string
  if (itemList.length === 0 || !searchActivity) {
    return <Box my={2}>Error finding activity</Box>;
  }

  return (
    <ActivityResult
      searchActivity={searchActivity}
      notification={props.notification}
      sessionId={props.sessionId}
    />
  );
};

export { SearchActivtyItem };
