import { Box, Button, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import { colors } from '../../App/colors';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

export const NoExistingRecords = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        py: 2,
        px: 3,
        my: 2,
        animation: `${fadeInAnimation} 1s`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box sx={{ borderRadius: 200, p: 1.5, bgcolor: colors['charcoal.300'], mb: 1 }}>
        <Box style={{ width: '24px', height: '24px' }}>
          <AddCircleOutlineIcon
            sx={{
              width: '100%',
              height: '100%'
            }}
          />
        </Box>
      </Box>
      <Typography variant="body1" sx={{ color: '#6F6F6F', fontWeight: '500' }}>
        You have no recorded activities
      </Typography>
      <Typography variant="body1" sx={{ pt: 2 }}>
        You can add Self-Recorded Activities using Quick Log.
      </Typography>
      <Button variant="outlined" component={Link} to="/log" sx={{ my: 3, minWidth: 200 }}>
        Quick Log
      </Button>
    </Box>
  );
};
