import React, { useContext } from 'react';
import { Typography, Button, Dialog, DialogContent, Box, CircularProgress } from '@mui/material';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import {
  useGetCpdStatementLazyQuery,
  useGetMemberActivitiesLazyQuery
} from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { ApolloError } from '@apollo/client';

export const DownloadStatementButton = ({
  buttonText,
  cycleName
}: {
  buttonText: string;
  cycleName: string;
}) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const handleError = useErrorHandler();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [getSearchSuggestionsLazy] = useGetMemberActivitiesLazyQuery();

  const [searchCpdStatement] = useGetCpdStatementLazyQuery();

  const handleStatementError = (error: ApolloError) => {
    handleError(error);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDownloadClick = () => {
    setIsLoading(true);
    getCpdStatement();
  };

  const getCpdStatement = () => {
    getSearchSuggestionsLazy({
      variables: { memberid: activeMemberId, cycleName: cycleName },
      onError: handleError,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const activityID = data?.getMemberActivities?.items?.some(
          (activity) => activity?.cpd_id === null
        );
        const currentYear = new Date().getFullYear();
        var dateToUse =
          currentYear.toString() === cycleName
            ? Math.max(new Date().getTime(), new Date('01/02/' + cycleName).getTime())
            : new Date('12/30/' + cycleName).getTime();
        var asAtDateStrig = new Date(dateToUse).toISOString().substring(0, 10);
        searchCpdStatement({
          variables: {
            memberid: activeMemberId,
            asAtDate: asAtDateStrig
          },
          fetchPolicy: 'no-cache',
          onCompleted: (data) => {
            setTimeout(() => {
              setIsLoading(false);
            }, 300);
            const url = data?.getCpdStatement?.url;
            const attachment_name = data?.getCpdStatement?.attachment_name;
            if (url && !activityID) {
              triggerDownload(url, attachment_name || 'certificate');
            } else {
              setIsDialogOpen(true);
            }
          }
        });
      }
    });
  };

  const triggerDownload = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.click();
  };

  return (
    <>
      <Button
        variant="outlined"
        fullWidth
        href=""
        target="_blank"
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'flex-end',
          justifyContent: 'flex-start',
          borderRadius: '8px',
          marginTop: 2,
          marginBottom: 4,
          fontWeight: 600,
          py: 1.5
        }}
        onClick={() => {
          handleDownloadClick();
        }}
      >
        <DescriptionOutlinedIcon sx={{ mr: 1, color: 'secondary.main' }} />
        {buttonText}
        <Box component="span" sx={{ display: 'inline-flex', pl: 1, marginLeft: 'auto' }}>
          {!isLoading && <FileDownloadOutlined />}
          {isLoading && <CircularProgress size="24px" color="inherit" />}
        </Box>
      </Button>

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <CloseIcon onClick={handleDialogClose} sx={{ marginLeft: 'auto', cursor: 'pointer' }} />
          </Box>
          <Typography>
            Your recent submission is being processed and will take up to 5 minutes to appear on
            your CPD statement. You will be able to download the statement once processed.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
