import {
  SaveHighlevelRequirementMutation,
  DeleteHlrActivityMutation
} from '../../../graphql/generated';
import { SuccessAlert } from './SuccessAlert';
import { ApolloError } from '@apollo/client';
import { Alert, AlertTitle, Box } from '@mui/material';

type AlertsProps = {
  submissionResponse: SaveHighlevelRequirementMutation | null | undefined;
  onDismissSuccessfulSubmission: () => void;
  submissionError?: string | ApolloError | undefined;
  deleteResponse?: DeleteHlrActivityMutation | null | undefined;
  deleteError?: ApolloError;
};

export const Alerts = ({
  submissionResponse,
  onDismissSuccessfulSubmission,
  submissionError,
  deleteResponse,
  deleteError
}: AlertsProps) => {
  if (submissionResponse) {
    return (
      <SuccessAlert
        onClickCancel={onDismissSuccessfulSubmission}
        activityTitle={submissionResponse.saveHighlevelRequirement?.title}
      ></SuccessAlert>
    );
  }
  if (submissionError) {
    return (
      <Box my={2}>
        <Alert severity="error">
          <AlertTitle>
            {typeof submissionError === 'string' ? submissionError : submissionError.message}
          </AlertTitle>
        </Alert>
      </Box>
    );
  }
  if (deleteResponse) {
    return (
      <Box my={2}>
        <Alert severity="success">
          <AlertTitle>{deleteResponse.deleteHlrActivity?.title} has been deleted.</AlertTitle>
        </Alert>
      </Box>
    );
  }
  if (deleteError) {
    return (
      <Box my={2}>
        <Alert severity="error">
          <AlertTitle>{deleteError.message}</AlertTitle>
        </Alert>
      </Box>
    );
  }
  return null;
};
