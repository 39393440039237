import { Box, CardActionArea, Skeleton, Typography } from '@mui/material';
import { LoadableText } from 'src/components/Loadable/LoadableText';
import { CircularProgress } from './CircularProgress';
import { HoursRemaining } from '../components/HoursRemaining';
import { getCardTitleForCategoryCode } from '../functions/card-titles';
import { CategoryGroupRequirement } from '../types';
import { colors } from 'src/components/App/colors';
import { CompletedCheckmark } from '../components/CompletedCheckmark';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

type CPDHourRequirementProps = {
  categoryGroupRequirement?: CategoryGroupRequirement;
  color?: string;
  onSelect?: () => void;
};

export const CPDHourRequirement = ({
  categoryGroupRequirement,
  color = 'primary',
  onSelect
}: CPDHourRequirementProps) => {
  const { completed, hours, minimum } = categoryGroupRequirement || {};
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <CardActionArea
        onClick={onSelect}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          width: '100%',
          height: '100%',
          border: `1px solid ${colors['stone.100']}`,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          textAlign: 'start',
          backgroundColor: 'white',
          px: 3,
          py: 2
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {completed ? (
                <Typography
                  variant={'caption'}
                  display={'flex'}
                  fontWeight={600}
                  alignItems={'center'}
                  gap={1}
                >
                  <CompletedCheckmark
                    sx={{ width: 13, height: 13 }}
                    size={'small'}
                    completed={true}
                  />
                  Completed
                </Typography>
              ) : (
                <Typography
                  variant={'caption'}
                  color={colors['charcoal.700']}
                  sx={{ display: 'flex' }}
                >
                  Incomplete
                </Typography>
              )}
              <ChevronRightOutlinedIcon sx={{ color: 'primary.main', ml: 'auto', mr: -1 }} />
            </Box>
            <LoadableText
              variant={'h4'}
              loading={!categoryGroupRequirement}
              whiteSpace={'pre-wrap'}
              sx={{ fontWeight: '600', width: '100%', pt: 0.5 }}
            >
              {getCardTitleForCategoryCode(categoryGroupRequirement?.category_group_code)}
            </LoadableText>
          </Box>
        </Box>

        <Box
          width={'100%'}
          display={'flex'}
          marginTop={'auto'}
          sx={{ mt: 'auto', flexDirection: 'column' }}
        >
          <Box sx={{ py: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Box sx={{ aspectRatio: '1/1', mb: 2 }}>
              {categoryGroupRequirement?.hours != null &&
              categoryGroupRequirement?.minimum != null ? (
                <CircularProgress
                  value={
                    completed
                      ? 100
                      : (categoryGroupRequirement?.hours / categoryGroupRequirement?.minimum) * 100
                  }
                  color={color}
                />
              ) : (
                <Skeleton
                  variant={'rounded'}
                  width={'100%'}
                  height={'100%'}
                  sx={{ borderRadius: '100%' }}
                ></Skeleton>
              )}
            </Box>

            <HoursRemaining minimum={minimum} hours={hours} completed={completed} />
          </Box>
        </Box>
      </CardActionArea>
    </Box>
  );
};
