import React, { useContext, useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
  Box,
  Typography,
  Drawer,
  Button,
  Container,
  Link,
  Alert,
  Collapse,
  Dialog,
  DialogActions,
  AlertColor
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

import {
  HlrActivityTypeDateRange,
  useGetMemberHlrSearchQuery,
  useAddHighlevelRequirementMutation,
  useDeleteHlrActivityMutation
} from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { SearchBar } from 'src/components/SearchBar/SearchBar';
import { LoadableBox } from 'src/components/Loadable/LoadableBox';

type AddRequirementsFlyoutProps = {
  activeCycle: string;
};
const AddRequirementsFlyout = ({ activeCycle }: AddRequirementsFlyoutProps) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const [openRequirementDrawer, setOpenRequirementDrawer] = useState(false);
  const [requirementsList, setRequirementsList] = useState<HlrActivityTypeDateRange[]>([]);
  const [requirementsSearchResults, setRequirementsSearchResults] = useState<
    HlrActivityTypeDateRange[]
  >([]);
  const [clickedHlr, setClickedHlr] = useState<HlrActivityTypeDateRange>();
  const [requirementID, setRequirementID] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [alertBoxDetails, setAlertBoxDetails] = useState({
    title: '' as string,
    severity: 'info' as AlertColor,
    show: false as boolean,
    message: '' as string
  });
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const [disableWhileDeleting, setDisableWhileDeleting] = React.useState<boolean>(false);
  const dateAsAt = activeCycle + '-01-01';

  const handleRequirementDrawerClose = () => {
    setOpenRequirementDrawer(false);
  };
  const handleDialog = () => {
    setDialogOpen(false);
  };
  const { error } = useGetMemberHlrSearchQuery({
    variables: { memberId: activeMemberId, dateAsAt: dateAsAt },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const requirementsListRaw = (data?.getMemberHlrSearch?.items ??
        []) as HlrActivityTypeDateRange[];
      const requirementsListSorted = [...requirementsListRaw].sort((a: any, b: any) =>
        a.title > b.title ? 1 : -1
      );
      setRequirementsList(requirementsListSorted);
      setRequirementsSearchResults(requirementsListSorted);
    }
  });

  const selectedRequirement = requirementsList.find(
    (requirement) => requirement.activity_type_id === requirementID
  ) as HlrActivityTypeDateRange;

  const SearchResults = () => {
    if (searchTerm !== '') {
      setRequirementsSearchResults(
        requirementsList.filter((requirement) => {
          return requirement?.title?.toLowerCase().includes(searchTerm.toLowerCase());
        })
      );
    } else {
      setRequirementsSearchResults(requirementsList);
    }
  };
  const [addHighLevelRequirementMutation] = useAddHighlevelRequirementMutation({
    variables: {
      memberid: activeMemberId,
      asAtDate: dateAsAt,
      hlrdetails: {
        activity_type_code: clickedHlr?.activity_type_code!,
        activity_type_id: clickedHlr?.activity_type_id!,
        title: clickedHlr?.title!,
        start_date: clickedHlr?.start_date!,
        end_date: clickedHlr?.end_date!
      }
    },
    onCompleted: () => {
      setAlertBoxDetails({
        title: clickedHlr?.title!,
        severity: 'success',
        show: true,
        message: 'has been added to your dashboard'
      });
      setTimeout(() => {
        setAlertBoxDetails({
          title: '',
          severity: 'success',
          show: false,
          message: ''
        });
      }, 5000);
    },
    onError: (data) => {
      setAlertBoxDetails({
        title: '',
        severity: 'error',
        show: true,
        message: data.message
      });
      setTimeout(() => {
        setAlertBoxDetails({
          title: '',
          severity: 'error',
          show: false,
          message: ''
        });
      }, 5000);
    },
    refetchQueries: ['getMemberHlrDetails', 'getMemberHlrSearch']
  });

  const [deleteHlrActivityMutation] = useDeleteHlrActivityMutation({
    variables: {
      memberid: activeMemberId,
      activitykey: clickedHlr?.activitykey
    },
    onCompleted: () => {
      setAlertBoxDetails({
        title: clickedHlr?.title!,
        severity: 'warning',
        show: true,
        message:
          'has been deleted from your dashboard, please wait while we process this request and update your records.'
      });
      setConfirmDelete(false);
      setDialogOpen(false);
      setDisableWhileDeleting(true);
      setTimeout(() => {
        setDisableWhileDeleting(false);
        setAlertBoxDetails({
          title: '',
          severity: 'error',
          show: false,
          message: ''
        });
      }, 8000);
    },

    refetchQueries: ['getMemberHlrDetails', 'getMemberHlrSearch']
  });

  React.useEffect(() => {
    if (clickedHlr && clickedHlr.status === null) {
      addHighLevelRequirementMutation();
    }
    if (clickedHlr && clickedHlr.status != null && confirmDelete) {
      deleteHlrActivityMutation();
    }
  }, [clickedHlr, confirmDelete, addHighLevelRequirementMutation, deleteHlrActivityMutation]);

  const alertBox = (
    <Collapse
      in={alertBoxDetails?.show}
      sx={{ position: 'fixed', top: '60px', width: '552px', maxWidth: '100%', zIndex: '99' }}
    >
      <Alert
        icon={<CheckCircleOutlineIcon fontSize="inherit" />}
        severity={alertBoxDetails?.severity}
      >
        {alertBoxDetails.title + ' ' + alertBoxDetails.message}
      </Alert>
    </Collapse>
  );

  if (error) {
    return <Box>{error.message}</Box>;
  }

  return (
    <>
      {alertBox}
      <Box display={'flex'} mb={2} sx={{ alignItems: 'baseline' }}>
        <Typography variant={'h2'} sx={{ color: 'primary.main', flex: 1, fontWeight: '600' }}>
          Add Dual Fellowship Requirements
        </Typography>
      </Box>
      <Typography>
        In some cases, GPs may be required to complete specialist high-level CPD requirements, which
        are designed to provide advanced training in specific areas of medicine. This may be
        necessary to meet regulatory requirements, maintain professional registration, or to enhance
        your skills and knowledge in a particular area of interest.
      </Typography>
      <Typography sx={{ mt: 1 }}>
        Only qualifications with an additional specialist high-level requirement are listed below.
      </Typography>
      <Box sx={{ my: 2, display: 'block' }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Search for Dual Fellowship
        </Typography>
        <Box sx={{ display: 'block', my: 2 }}>
          <SearchBar
            keyword={searchTerm}
            onSelectKeyword={(selectedKeyword) => setSearchTerm(selectedKeyword)}
            onExecuteSearch={() => SearchResults()}
            suggestedKeywordsList={[]}
          />
        </Box>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Showing {requirementsSearchResults?.length} results
        </Typography>
        {requirementsSearchResults?.map((requirement: HlrActivityTypeDateRange) => (
          <Box
            sx={{ border: '1px solid #D2DCE1', p: 2, mb: 2, borderRadius: '8px' }}
            key={requirement?.activity_type_code}
            className={requirement?.status!}
          >
            {requirement?.status != null ? (
              <Button
                disabled={disableWhileDeleting}
                sx={{
                  '&.Mui-disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'auto'
                  },
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginLeft: 'auto',
                  borderRadius: '8px',
                  minWidth: 'auto'
                }}
                onClick={() => {
                  setDialogOpen(true);
                  setClickedHlr(requirement);
                }}
              >
                <CheckCircleIcon fontSize="small" color="secondary" />
              </Button>
            ) : (
              <Button
                disabled={disableWhileDeleting}
                sx={{
                  '&.Mui-disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'auto'
                  },
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginLeft: 'auto',
                  borderRadius: '8px',
                  minWidth: 'auto'
                }}
                onClick={() => {
                  setClickedHlr(requirement);
                }}
              >
                <ControlPointIcon fontSize="small" color="secondary" />
              </Button>
            )}
            <Typography variant="h4" sx={{ fontWeight: '600', mt: -3, mr: 5 }}>
              {requirement?.title}
            </Typography>
            <Typography variant="body1" sx={{ mr: 5 }}>
              {requirement?.activity_type_hlr_specialist_college}
            </Typography>
            <Button
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                marginLeft: 'auto',
                borderRadius: '8px',
                mt: 1
              }}
              onClick={() => {
                setOpenRequirementDrawer(true);
                setRequirementID(requirement?.activity_type_id);
              }}
            >
              View more <ChevronRightOutlinedIcon />
            </Button>
            <Drawer
              variant="temporary"
              open={openRequirementDrawer}
              anchor={'right'}
              onClose={() => handleRequirementDrawerClose()}
              hideBackdrop={true}
              sx={{
                '.MuiPaper-root': {
                  maxWidth: '100%'
                },
                ' *': { maxWidth: '100%' }
              }}
            >
              <Container sx={{ pb: 5, pt: 3, mx: { sm: 1 }, width: '600px', maxWidth: '100%' }}>
                {alertBox}

                <Box sx={{ display: 'flex' }}>
                  <Link
                    component="button"
                    variant="body1"
                    onClick={() => handleRequirementDrawerClose()}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      fontWeight: 600
                    }}
                  >
                    <ChevronLeftOutlinedIcon /> Back
                  </Link>
                  <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                    <CloseIcon
                      cursor={'pointer'}
                      onClick={() => handleRequirementDrawerClose()}
                      sx={{ color: '#9E9FA2' }}
                    />
                  </Box>
                </Box>

                {selectedRequirement ? (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h2" sx={{ fontWeight: 600 }}>
                      {selectedRequirement?.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ display: 'flex', alignItems: 'center', mt: 1.5 }}
                    >
                      <HomeWorkIcon sx={{ fontSize: '14px', mr: 1.5 }} />
                      {selectedRequirement?.activity_type_hlr_specialist_college}
                    </Typography>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <CalendarTodayIcon sx={{ fontSize: '14px', mr: 1.5 }} />
                      Due{' '}
                      {selectedRequirement &&
                        format(parseISO(selectedRequirement?.end_date!), 'MMM yyyy')}
                    </Typography>
                    {selectedRequirement?.activity_type_hlr_specialist_college_phone && (
                      <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocalPhoneOutlinedIcon
                          sx={{
                            fontSize: '14px',
                            mr: 1.5
                          }}
                        />
                        <Link
                          href={`tel: ${selectedRequirement?.activity_type_hlr_specialist_college_phone}`}
                        >
                          {selectedRequirement?.activity_type_hlr_specialist_college_phone}
                        </Link>
                      </Typography>
                    )}
                    {selectedRequirement?.activity_type_hlr_specialist_college_website && (
                      <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        <InsertLinkOutlinedIcon sx={{ fontSize: '14px', mr: 1.5 }} />
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          href={`${selectedRequirement?.activity_type_hlr_specialist_college_website}`}
                        >
                          {selectedRequirement?.activity_type_hlr_specialist_college_website}
                        </Link>
                      </Typography>
                    )}

                    {selectedRequirement?.status != null ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        sx={{ width: '100%', my: 3 }}
                        onClick={() => {
                          setDialogOpen(true);
                          setClickedHlr(selectedRequirement);
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={disableWhileDeleting}
                        sx={{
                          '&.Mui-disabled': {
                            cursor: 'not-allowed',
                            pointerEvents: 'auto'
                          },
                          width: '100%',
                          my: 3
                        }}
                        onClick={() => setClickedHlr(selectedRequirement)}
                      >
                        Add requirement to myCPD
                      </Button>
                    )}
                    <Typography variant="h4">
                      {selectedRequirement?.activity_type_hlr_heading}
                    </Typography>
                    <Box
                      sx={{
                        table: {
                          border: 'none',
                          textAlign: 'left',
                          borderCollapse: 'collapse'
                        },
                        thead: {
                          backgroundColor: '#D2DCE1',
                          th: {
                            border: 'none',
                            py: 1,
                            px: 2
                          }
                        },
                        tbody: {
                          border: 'none',
                          tr: {
                            border: 'none',
                            '&:nth-child(odd)': { backgroundColor: '#F8F8F8' }
                          },
                          td: {
                            border: 'none',
                            py: 1,
                            px: 2,
                            verticalAlign: 'top'
                          }
                        }
                      }}
                      dangerouslySetInnerHTML={{
                        __html: selectedRequirement?.activity_type_hlr_content!
                      }}
                    ></Box>
                  </Box>
                ) : (
                  <LoadableBox loading={!selectedRequirement}></LoadableBox>
                )}
              </Container>
            </Drawer>
          </Box>
        ))}
      </Box>

      <Dialog open={dialogOpen}>
        <Box sx={{ p: 2, pb: 0 }}>
          <Typography variant="h3">Are you sure you want to delete?</Typography>
          <Typography sx={{ mt: 1 }}>
            All CPD hours and files you may have uploaded will permanently be deleted from this
            requirement.
          </Typography>
        </Box>
        <DialogActions sx={{ py: 0, pt: 1, pb: 2 }}>
          <Button
            onClick={handleDialog}
            sx={{ color: '#6F6F6F', minWidth: 'auto', borderRadius: '8px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setConfirmDelete(true);
              setAlertBoxDetails({
                title: clickedHlr?.title!,
                severity: 'success',
                show: false,
                message: ''
              });
            }}
            autoFocus
            sx={{ minWidth: 'auto', borderRadius: '8px' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export { AddRequirementsFlyout };
