import { Box, Divider } from '@mui/material';

import { AddRequirements } from './AddRequirements';
import { GetMemberHlrDetailsQuery } from 'src/graphql/generated';

type AddRequirementsProps = {
  activeCycle: string;
  hlrDetailsData?: GetMemberHlrDetailsQuery;
};

const HighLevelRequirements = ({ activeCycle, hlrDetailsData }: AddRequirementsProps) => {
  return (
    <Box sx={{ mt: 3, width: '100%' }}>
      <Divider sx={{ mb: 3 }} />
      <AddRequirements activeCycle={activeCycle} hlrDetailsData={hlrDetailsData} />
    </Box>
  );
};
export { HighLevelRequirements };
