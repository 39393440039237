import { Box, Typography } from '@mui/material';
import { colors } from '../../App/colors';

type CPDTagsProps = {
  tags: Array<string>;
};

export const CPDTags = ({ tags }: CPDTagsProps) => {
  return (
    <Box display={'flex'} flexWrap={'wrap'} gap={1}>
      {tags.map((tag, id) => {
        return (
          <Box
            key={id}
            py={0.5}
            px={1}
            border={'1px solid #C2C9D1'}
            borderRadius={0.5}
            sx={{
              background:
                'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1'
            }}
          >
            <Typography
              variant={'caption'}
              sx={{
                fontWeight: 600,
                color: colors['charcoal.700']
              }}
            >
              {tag}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
