import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Box, Typography, Drawer, Button, CardActionArea, Container, Paper } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { GetMemberHlrDetailsQuery, HighLevelRequirementDetail } from 'src/graphql/generated';
import { colors } from 'src/components/App/colors';
import { CompletedCheckmark } from '../components/CompletedCheckmark';
import { LoadableText } from 'src/components/Loadable/LoadableText';
import { AddRequirementsFlyout } from './AddRequirementsFlyout';
import { TooltipContent } from './TooltipContent';

type AddRequirementsProps = {
  activeCycle: string;
  hlrDetailsData?: GetMemberHlrDetailsQuery;
};
const AddRequirements = ({ activeCycle, hlrDetailsData }: AddRequirementsProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const loading = !hlrDetailsData;
  const hlrDashboardList = !hlrDetailsData
    ? ([] as HighLevelRequirementDetail[])
    : (((hlrDetailsData?.getMemberHlrDetails as HighLevelRequirementDetail[]) ?? []).toSorted(
        (a, b) => ((a?.title ?? '') > (b?.title ?? '') ? 1 : -1)
      ) as HighLevelRequirementDetail[]);

  return (
    <>
      {hlrDashboardList?.length === 0 ? (
        <Paper
          sx={{ p: 2, width: '100%', border: `1px solid ${colors['stone.100']}` }}
          elevation={0}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'center' }
            }}
          >
            <Box sx={{ mb: { xs: 2, sm: 0 } }}>
              <Typography
                color={'primary'}
                mb={2}
                sx={{
                  fontWeight: 600,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row'
                }}
                variant={'body1'}
              >
                Dual Fellowship Requirements <TooltipContent />
              </Typography>
              <Typography>
                If you have an additional fellowship/s from an AMC-accredited specialist medical
                college that leads to specialist registration, you may be required to complete their
                specialist high-level CPD requirement/s. Select Add Dual Fellowship to explore and
                manage additional specialist high-level requirements.
              </Typography>
            </Box>
            <Box sx={{ ml: { xs: 0, sm: 2 } }}>
              <Button
                onClick={() => setOpenDrawer(true)}
                variant="outlined"
                sx={{ minWidth: 'max-content' }}
              >
                <AddIcon fontSize="small" /> Add Dual Fellowship
              </Button>
            </Box>
          </Box>
        </Paper>
      ) : (
        <Box>
          <Typography
            color={'primary'}
            mb={2}
            sx={{
              fontWeight: 600,
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row'
            }}
            variant={'body1'}
          >
            Dual Fellowship Requirements <TooltipContent />
          </Typography>
          <Box
            sx={{
              mt: 2,
              display: 'grid',
              gridTemplateColumns: { xs: `1fr`, sm: '1fr 1fr 1fr' },
              gap: 2
            }}
          >
            {hlrDashboardList?.map((hlrCard) => (
              <CardActionArea
                component={NavLink}
                key={hlrCard.activity_type_id}
                to={{
                  pathname: '/hlr/' + encodeURIComponent(hlrCard?.sortkey!),
                  state: {
                    selectedHlrCard: hlrCard
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '8px',
                    width: '100%',
                    height: '100%',
                    border: `1px solid ${colors['stone.100']}`,
                    backgroundColor: 'white',
                    px: 3,
                    py: 2
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LoadableText loading={loading} variant={'caption'}>
                      {hlrCard.hlr_status !== 'hlr_draft' ? (
                        <Box display={'flex'} fontWeight={600} alignItems={'center'} gap={1}>
                          <CompletedCheckmark
                            sx={{ width: 13, height: 13 }}
                            size={'small'}
                            completed={true}
                          />
                          Completed
                        </Box>
                      ) : (
                        <Box color={colors['charcoal.700']}>Incomplete</Box>
                      )}
                    </LoadableText>
                    <ChevronRightOutlinedIcon sx={{ color: 'primary.main', ml: 'auto', mr: -1 }} />
                  </Box>
                  <LoadableText
                    loading={loading}
                    variant={'h4'}
                    sx={{ fontWeight: '600', width: '100%', pt: 0.5 }}
                  >
                    {hlrCard?.title}
                  </LoadableText>
                  <LoadableText loading={loading} variant={'caption'}>
                    Complete by {format(parseISO(`${hlrCard.end_date}`), 'dd MMM yyyy')}
                  </LoadableText>
                </Box>
              </CardActionArea>
            ))}
            {hlrDashboardList?.length! < 3 && (
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  maxWidth: '99.5%',
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%239E9FA2' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='15' stroke-linecap='round'/%3e%3c/svg%3e")`
                }}
              >
                <Button
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    borderRadius: 0,
                    py: 4
                  }}
                  onClick={() => setOpenDrawer(true)}
                >
                  <ControlPointIcon sx={{ color: '#9E9FA2', mb: 1 }} />
                  <Typography sx={{ color: '#9E9FA2' }}>Add Requirement(s)</Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 2 }}>
        <Drawer
          variant="temporary"
          open={openDrawer}
          anchor={'right'}
          onClose={() => setOpenDrawer(false)}
        >
          <Container sx={{ pb: 5, pt: 3 }} maxWidth={'sm'}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseIcon
                cursor={'pointer'}
                onClick={() => setOpenDrawer(false)}
                sx={{ color: '#9E9FA2' }}
              />
            </Box>

            <AddRequirementsFlyout activeCycle={activeCycle} />
          </Container>
        </Drawer>
      </Box>
    </>
  );
};
export { AddRequirements };
