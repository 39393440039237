import { useEffect } from 'react';

const Document = (props?: any) => {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return props.children;
};

export { Document };
