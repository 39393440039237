import React, { useRef } from 'react';
import { useLocation, useParams, NavLink, useHistory } from 'react-router-dom';
import { Box, Container, Button } from '@mui/material';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

import { ContentPageTitle } from '../../ContentPageTitle/ContentPageTitle';
import { PDPForm } from './PDPForm';
import { PDPUpload } from './PDPUpload';
import { HomeTemplate } from './HomeTemplate';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';

const PdpPage = () => {
  const history = useHistory();
  const { year } = useParams<{ year: string }>();
  const pageTitle = year + ' Professional Development Plan (PDP)';
  const scrollRef = useRef<HTMLElement>(null);
  const location = useLocation<{ selectedTemplate: String }>();
  let { selectedTemplate = 'home' } = location?.state || {};
  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;
  React.useEffect(() => {
    history.push(`/pdp/${year}`, { selectedTemplate: 'home' }); // eslint-disable-next-line
  }, [activeMemberId]);
  return (
    <>
      <Box ref={scrollRef}>
        <ContentPageTitle currentPageTitle={pageTitle} showImpersonate={true} />
      </Box>
      {selectedTemplate === 'home' && <HomeTemplate />}
      {selectedTemplate === 'template' && (
        <Container maxWidth="lg">
          <Button
            size="small"
            component={NavLink}
            to={{ pathname: `/pdp/${year}`, state: { selectedTemplate: 'home' } }}
            sx={{ alignItems: 'center', justifyContent: 'flex-start', mt: 2 }}
          >
            <KeyboardArrowLeftOutlinedIcon /> Back
          </Button>
          <PDPForm />
        </Container>
      )}
      {selectedTemplate === 'upload' && (
        <Container maxWidth="lg">
          <Button
            size="small"
            component={NavLink}
            to={{ pathname: `/pdp/${year}`, state: { selectedTemplate: 'home' } }}
            sx={{ alignItems: 'center', justifyContent: 'flex-start', mt: 2 }}
          >
            <KeyboardArrowLeftOutlinedIcon /> Back
          </Button>
          <PDPUpload />
        </Container>
      )}
    </>
  );
};
export { PdpPage };
