import React from 'react';
import { Typography } from '@mui/material';
import { SearchActivityItem } from 'src/graphql/generated';

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
};

const Provider: React.FC<Props> = (props) => {
  return (
    <Typography variant="body2" sx={{ fontWeight: 600, mt: 1 }}>
      {props?.searchActivity?.activity?.providerName}
    </Typography>
  );
};

export { Provider };
