import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { Log } from './Log';
import { QuickLogFormData } from './types';
import { nanToZero, roundHalf } from 'src/components/Attachments/utils';
import { ALLOW_PLR_CODES } from './constants';

export const NewQuickLog = () => {
  const { search } = useLocation();

  if (!search) {
    return <Log />;
  }

  const {
    logName,
    notes: urlNotes,
    hours: urlHours,
    ea_hours: ea_hours_url,
    rp_hours: rp_hours_url,
    mo_hours: mo_hours_url,
    date,
    program_levels: program_levels_url
  } = queryString.parse(search);

  const activity_title = (logName as string) ?? '';

  const urlHoursFloat = nanToZero(parseFloat((urlHours as string) ?? '0'));
  const ea_hours_url_float = nanToZero(parseFloat((ea_hours_url as string) ?? '0'));
  const rp_hours_url_float = nanToZero(parseFloat((rp_hours_url as string) ?? '0'));
  const mo_hours_url_float = nanToZero(parseFloat((mo_hours_url as string) ?? '0'));
  const ea_hours = roundHalf(ea_hours_url_float) || roundHalf(urlHoursFloat);
  const rp_hours = roundHalf(rp_hours_url_float);
  const mo_hours = roundHalf(mo_hours_url_float);
  const total_hours = ea_hours + rp_hours + mo_hours; // keep total in sync ea_hours

  var dateTimestamp = Date.parse(decodeURIComponent(date as string));
  const activity_date = isNaN(dateTimestamp) ? new Date() : new Date(dateTimestamp);

  let program_levels = (program_levels_url as string)?.split('|') ?? [];
  program_levels = program_levels.filter((plr) => ALLOW_PLR_CODES.includes(plr));

  const notes = (urlNotes as string) ?? '';

  const urlFormData: QuickLogFormData = {
    activitykey: null,
    activity_title: activity_title,
    activity_date: activity_date,
    ea_hours: ea_hours,
    rp_hours: rp_hours,
    mo_hours: mo_hours,
    total_hours: total_hours,
    program_levels: program_levels,
    notes: notes,
    attachments: []
  };

  return <Log urlFormData={urlFormData} />;
};
