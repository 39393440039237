import React, { useContext } from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox, Alert } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import {
  LearningInterest,
  LearningInterestInput,
  useGetLearningInterestsQuery,
  useSaveLearningInterestsMutation
} from 'src/graphql/generated';

const EditAreasOfInterestsList = () => {
  const handleError = useErrorHandler();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const [updateAreasOfInterest, setUpdateAreasOfInterest] = React.useState<LearningInterestInput[]>(
    []
  );
  const { loading, error: errorQuery } = useGetLearningInterestsQuery({
    variables: { memberid: activeMemberId },
    onCompleted: (data) => {
      const areasOfInterestList = (data?.getLearningInterests?.items ?? []) as LearningInterest[];
      const areasOfInterestInputList = areasOfInterestList.map((interests) => {
        const { __typename, ...others } = interests;
        return { ...others };
      });
      setUpdateAreasOfInterest(areasOfInterestInputList);
    }
  });

  const [saveLearningInterestsMutation, { error }] = useSaveLearningInterestsMutation({
    variables: {
      memberid: activeMemberId,
      learning_interests: updateAreasOfInterest
    },
    refetchQueries: ['getLearningInterests'],
    onError: handleError
  });

  React.useEffect(() => {
    saveLearningInterestsMutation();
  }, [saveLearningInterestsMutation, updateAreasOfInterest]);

  if (loading) {
    return <>....loading</>;
  }

  if (errorQuery) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">Something went wrong please try again</Alert>
      </Box>
    );
  }
  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">Something went wrong, please refresh the page and try again</Alert>
      </Box>
    );
  }
  const handleChange = (event: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const newAreasOfInterest = updateAreasOfInterest?.map((newInterest: any) =>
        newInterest.learning_interest_id === event.target.value
          ? { ...newInterest, status: isChecked }
          : newInterest
      );
      setUpdateAreasOfInterest(newAreasOfInterest);
    } else {
      const newAreasOfInterest = updateAreasOfInterest?.map((newInterest: any) =>
        newInterest.learning_interest_id === event.target.value
          ? { ...newInterest, status: isChecked }
          : newInterest
      );
      setUpdateAreasOfInterest(newAreasOfInterest);
    }
  };

  return (
    <FormGroup>
      {[...updateAreasOfInterest]
        .sort((a: any, b: any) => (a.learning_interest > b.learning_interest ? 1 : -1))
        ?.map((availableInterest: any) => (
          <FormControlLabel
            key={availableInterest?.learning_interest_id}
            sx={{
              width: '100%',
              '&.MuiFormControlLabel-root': {
                marginLeft: '0',
                borderTop: '1px solid #F0F0F0'
              }
            }}
            labelPlacement="start"
            label={
              <Box sx={{ display: 'flex', width: '100%', fontSize: '0.875rem' }}>
                {availableInterest?.learning_interest}
              </Box>
            }
            control={
              <Checkbox
                onChange={handleChange}
                value={availableInterest?.learning_interest_id}
                checked={availableInterest?.status}
                icon={<RadioButtonUncheckedIcon fontSize="small" color="secondary" />}
                checkedIcon={<CheckCircleIcon fontSize="small" color="secondary" />}
                sx={{ marginLeft: 'auto' }}
              />
            }
          />
        ))}
    </FormGroup>
  );
};

export { EditAreasOfInterestsList };
