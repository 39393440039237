import * as React from 'react';

import {
  Attachment,
  GetMemberActivitiesDocument,
  GetMemberActivityByKeyDocument,
  useDeleteMemberAttachmentsMutation,
  useDeleteQuickLogActivityMutation,
  useGetAttendanceCertificateUrlLazyQuery,
  useGetMemberAttachmentUrlsLazyQuery,
  useSaveMemberAttachmentsMutation,
  useBackdateActivityMutation,
  AttType,
  useDeleteHlrActivityMutation
} from '../graphql/generated';
import { AuthContext, AuthContextType } from './AuthContext';
import { usePopupAlert } from '../components/Pages/History/usePopupAlert';
import { useUploadAttachments } from '../hooks/useUploadAttachments';
import { pick } from 'lodash';
import { PopupAlertProps } from '../components/Pages/History/types';

const triggerDownload = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();
};

export type HistoryContextType = {
  deleteQuickLogMutation: ReturnType<typeof useDeleteQuickLogActivityMutation>;
  deleteHlrMutation: ReturnType<typeof useDeleteHlrActivityMutation>;
  deleteMemberAttachmentsMutation: ReturnType<typeof useDeleteMemberAttachmentsMutation>;
  backdateMutation: ReturnType<typeof useBackdateActivityMutation>;
  downloadAttendanceCertificate: (activitykey: string) => void;
  uploadAttendanceCertificate: (activitykey: string, file: File) => void;
  downloadExternalAttendanceCertificate: (attachment: Attachment) => void;
  showPopup: (props: PopupAlertProps) => void;
};
const HistoryContext = React.createContext<HistoryContextType | null>(null);

const HistoryContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;
  const { showPopupAlert, popupAlert } = usePopupAlert();
  const [saveMemberAttachments] = useSaveMemberAttachmentsMutation();
  const deleteMemberAttachmentsMutation = useDeleteMemberAttachmentsMutation({
    refetchQueries: [GetMemberActivitiesDocument],
    onError: (error) => {
      showPopupAlert({
        title: 'Unable to delete attachment',
        message: error.message
      });
    },
    onCompleted: () => {
      showPopupAlert({
        title: 'Attachment deleted.',
        message: 'Attendance certificate attachment deleted succesfully'
      });
    }
  });

  const [getMemberAttachmentUrls] = useGetMemberAttachmentUrlsLazyQuery({});

  const deleteQuickLogMutation = useDeleteQuickLogActivityMutation({
    refetchQueries: [GetMemberActivitiesDocument, GetMemberActivityByKeyDocument],
    onError: (error) => {
      showPopupAlert({ title: 'Unable to delete at this time', message: error.message });
    }
  });
  const deleteHlrMutation = useDeleteHlrActivityMutation({
    refetchQueries: [GetMemberActivitiesDocument, GetMemberActivityByKeyDocument],
    onError: (error) => {
      showPopupAlert({ title: 'Unable to delete at this time', message: error.message });
    }
  });

  const uploadFiles = useUploadAttachments(AttType.Quicklogattachments);

  const [startAttendanceCertDownload] = useGetAttendanceCertificateUrlLazyQuery();

  const downloadExternalAttendanceCertificate = (attachment: Attachment) => {
    getMemberAttachmentUrls({
      variables: {
        memberid: activeMemberId,
        attachments: [pick(attachment, ['attachment_name', 'attachment_type', 'retrieval_key'])]
      },
      onCompleted: (data) => {
        const { url, attachment_name } = data?.getMemberAttachmentsUrls?.[0] || {};

        if (url) {
          triggerDownload(url, attachment_name || 'certificate');
        }
      },
      onError: (error) => {
        console.error(error);
      }
    });
  };

  const backdateMutation = useBackdateActivityMutation({
    refetchQueries: [GetMemberActivitiesDocument, GetMemberActivityByKeyDocument],
    onError: (error) => {
      showPopupAlert({ title: 'Unable to Backdate at this time', message: error.message });
    }
  });

  const downloadAttendanceCertificate = (activitykey: string) => {
    startAttendanceCertDownload({
      variables: {
        memberid: activeMemberId,
        activitykey
      },
      onCompleted: (data) => {
        if (!data.getAttendanceCertificateUrl?.items?.[0]?.url) {
          showPopupAlert({
            title: 'Unable to download certificate at this time',
            message: 'URL not found.'
          });
          return;
        }

        triggerDownload(
          data.getAttendanceCertificateUrl.items[0].url,
          data.getAttendanceCertificateUrl?.items[0]?.attachment_name || 'certificate'
        );
      },
      onError: (error) => {
        console.error(error);
        showPopupAlert({
          title: 'Unable to download certificate at this time',
          message: error.message
        });
      }
    });
  };

  const uploadAttendanceCertificate = async (activitykey: string, file: File) => {
    let attachments: Array<Attachment> = [];

    try {
      attachments = (await uploadFiles([file])) as Array<Attachment>;
    } catch (error) {
      console.error(error);
      showPopupAlert({ title: 'Certificate upload failed', message: 'Please try again later.' });
      return;
    }

    const prepareAttachments = (attachments: Array<Attachment>) => {
      return attachments.map(({ url, __typename, ...rest }) => {
        return {
          ...rest
        };
      });
    };

    attachments = prepareAttachments(attachments);

    saveMemberAttachments({
      refetchQueries: [GetMemberActivitiesDocument, GetMemberActivityByKeyDocument],
      variables: {
        memberid: activeMemberId,
        attachmentKey: activitykey,
        attachments
      },
      onCompleted: () => {
        showPopupAlert({
          title: 'Certificate uploaded',
          message: 'Attendance certificate was uploaded successfully.'
        });
      },
      onError: (error) => {
        console.error(error);
        showPopupAlert({ title: 'Certificate upload failed', message: error.message });
      }
    });
  };

  return (
    <HistoryContext.Provider
      value={{
        deleteQuickLogMutation,
        deleteHlrMutation,
        deleteMemberAttachmentsMutation,
        backdateMutation: backdateMutation,
        downloadAttendanceCertificate,
        uploadAttendanceCertificate,
        downloadExternalAttendanceCertificate,
        showPopup: showPopupAlert
      }}
    >
      {popupAlert}
      {children}
    </HistoryContext.Provider>
  );
};

export { HistoryContext, HistoryContextProvider };
