import { Attachment } from 'src/graphql/generated';

export const formatBytes = (bytes: number) => {
  const marker = 1024;
  const kiloBytes = marker;
  const megaBytes = marker * marker;
  if (bytes < kiloBytes) return bytes + ' Bytes';
  else if (bytes < megaBytes) return Math.round(bytes / kiloBytes) + ' KB';
  else return Math.round(bytes / megaBytes) + ' MB';
};

export const prepareAttachments = (attachments: Array<Attachment>) => {
  return attachments.map(({ url, __typename, ...rest }) => {
    return {
      ...rest
    };
  });
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
};

export const nanToZero = (num: number) => {
  if (Number.isNaN(num)) {
    return 0;
  } else return num;
};

export const roundHalf = (num: number) => {
  return Math.round(num * 2) / 2;
};
