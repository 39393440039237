import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridEnrichedColDef
} from '@mui/x-data-grid-pro';
import { Box, Button, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { format } from 'date-fns';
import { colors } from 'src/components/App/colors';
import { CPDTags } from './CPDTags';
import { MemberActivity } from './types';
import { arrayIntersectionOperator, cycleOperator, getSearchOperator } from './filter-model';
import { ActivityActions } from './Actions/ActivityActions';
import { isActivitySelfRecorded, reorderCPDTypes } from './utils';
import { BackdateButton } from './Actions/BackdateButton';

const renderHeaderLabel = (label: string) => () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      <Typography fontWeight={600} variant={'body2'} sx={{ color: colors['charcoal.900'] }}>
        {label}
      </Typography>
    </Box>
  );
};

export const getColumns = (
  mobile: boolean,
  isBackdateOpenForCycle: boolean
): Array<GridEnrichedColDef> => [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF, // Already contains the right field
    hide: mobile,
    renderCell: (row: GridCellParams) => (row.value ? <ExpandLess /> : <ExpandMore />)
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 1.5,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    sortable: true,
    type: 'date',
    hide: mobile,
    filterOperators: [cycleOperator],
    renderCell: ({ value }) => {
      return (
        <Typography variant={'body2'} sx={{ color: colors['charcoal.700'] }}>
          {format(value, 'dd/MM/yyyy')}
        </Typography>
      );
    },
    renderHeader: renderHeaderLabel('Date')
  },
  {
    field: 'activity',
    headerName: 'Activity',
    flex: 4.5,
    sortable: false,
    editable: false,
    renderHeader: renderHeaderLabel('Activity'),
    filterOperators: [getSearchOperator(['cpd_id', 'title', 'provider_name'])],
    type: 'string',
    renderCell: (params) => {
      const activity = params.value as MemberActivity;
      const subtitle = isActivitySelfRecorded(params.value)
        ? 'Self-Recorded Log'
        : params.value?.history_record_type === 'PDP'
        ? 'PDP'
        : params.value?.history_record_type === 'HLR'
        ? 'Self-Recorded Log'
        : params.value.provider_name;

      if (mobile) {
        const isExpanded = params?.api?.getExpandedDetailPanels().includes(params.id);
        return (
          <Box py={2} sx={{ width: '100%' }}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant={'body2'} color={colors['charcoal.700']}>
                {activity?.date && format(new Date(activity?.date), 'dd/MM/yyyy')}
              </Typography>
              <ActivityActions activity={params.value} />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Typography mt={0.5} variant={'body2'} fontWeight={600}>
                  {params.value.title}
                </Typography>
                <Typography mt={0.5} variant={'body2'}>
                  {subtitle}
                </Typography>
              </Box>
              {isBackdateOpenForCycle && (
                <Box sx={{ marginLeft: 'auto' }}>
                  <BackdateButton activity={activity} />
                </Box>
              )}
            </Box>
            <Box mt={2} display={'flex'} alignItems={'center'}>
              <Button
                disableRipple
                sx={{ minWidth: 0, p: 0 }}
                onClick={() => params?.api?.toggleDetailPanel(params.id)}
              >
                <Typography variant={'body2'} color={colors['charcoal.900']}>
                  {isExpanded ? 'View Less' : 'View More'}
                </Typography>
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Box ml={'auto'} display={'flex'}>
                <CPDTags tags={params.row.cpd_type}></CPDTags>
              </Box>
              <Typography ml={2} variant={'body2'}>
                {params.row.total_hours} hrs
              </Typography>
            </Box>
          </Box>
        );
      }
      return (
        <Box>
          <Typography mb={0.5} variant={'body2'} fontWeight={600}>
            {params.value.title}
          </Typography>
          <Typography variant={'body2'}>{subtitle}</Typography>
        </Box>
      );
    }
  },
  {
    field: 'cpd_type',
    headerName: 'Type of CPD',
    type: 'array',
    flex: 1.5,
    sortable: false,
    editable: false,
    renderHeader: renderHeaderLabel('Type of CPD'),
    headerAlign: 'left',
    hide: mobile,
    align: 'left',
    filterOperators: [arrayIntersectionOperator],
    renderCell: (params: GridCellParams) => {
      return <CPDTags tags={params.value} />;
    }
  },
  {
    field: 'total_hours',
    headerName: 'Total Hours',
    sortable: false,
    headerAlign: 'left',
    align: 'left',
    editable: false,
    renderHeader: renderHeaderLabel('Total Hours'),
    type: 'number',
    hide: mobile,
    flex: 1.5
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    renderHeader: () => null,
    hide: mobile,
    flex: 0.5,
    renderCell: ({ row }: GridCellParams) => <ActivityActions activity={row.activity} />
  },
  {
    field: 'backdateAction',
    type: 'actions',
    headerName: '',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    renderHeader: () => null,
    hide: mobile || !isBackdateOpenForCycle,
    flex: 1.5,
    renderCell: ({ row }: GridCellParams) => <BackdateButton activity={row.activity} />
  },
  {
    field: 'history_record_type',
    type: 'string',
    hide: true
  }
];

export const getRow = (activity: MemberActivity) => {
  if (!activity) {
    return null;
  }
  return {
    id: activity.activitykey,
    date: activity.date ? new Date(activity.date) : null,
    activity: activity,
    cpd_type:
      (activity?.cpd_types &&
        reorderCPDTypes(activity?.cpd_types)
          .filter((ct) => ct?.hours! > 0)
          .map((ct) => ct?.type)) ||
      [],
    total_hours: activity.hours,
    actions: activity,
    history_record_type: activity.history_record_type
  };
};
