import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

const PermissionDeniedPage = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ textAlign: 'center', py: 2, my: 2, animation: `${fadeInAnimation} 1s` }}>
        <IconButton>
          <InfoIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        <Typography variant="body1" color="primary" sx={{ pt: 4, fontWeight: '500' }}>
          Access denied
        </Typography>
        <Typography variant="body1" sx={{ color: '#6F6F6F' }}>
          Oops, looks like you are not authorised to access that page.
        </Typography>
        <Box sx={{ my: 3 }}>
          <Button variant="outlined" component={Link} to="/">
            Go to myCPD home page
          </Button>
          <Button variant="outlined" component={Link} to="/contact-us" sx={{ ml: 2 }}>
            Contact us
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export { PermissionDeniedPage };
