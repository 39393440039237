import { Typography, Divider } from '@mui/material';
const PDPFormText = () => {
  return (
    <>
      <Typography variant="h2" color="primary">
        RACGP PDP Tool
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Use this RACGP tool to plan your annual CPD and reflect on its impact on your practise.
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        You can track your progress and edit your PDP throughout the year using this tool. You need
        to complete the required fields including your reflection before submitting your PDP. You
        can record the time you spent on your PDP which will display as Reviewing Performance hours
        in your myCPD account.
      </Typography>
      <Divider sx={{ mt: 2, mb: 4 }} />
    </>
  );
};
export { PDPFormText };
