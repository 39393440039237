import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';

import { ActivityActionsProps, MemberActivity } from '../types';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { HistoryContext, HistoryContextType } from 'src/contexts/HistoryContext';

const isBackdateEnabledForActivity = (activity: MemberActivity) => {
  const cycle = Number.parseInt(activity?.cycle ?? '0');
  const hours = activity?.hours ?? 0;
  const history_record_type = activity?.history_record_type ?? '';

  const hasBackdateOption =
    cycle > 2023 && // cycle is greater than 2024
    hours > 0 && // is not a dummy activity
    !activity?.is_backdated && // is not a previously backdated activity
    !activity?.exempt && // is not an exemption activity
    // TODO: fix this logic when backend confirms what data is available
    ['SELF_DIR_LOG', 'QUICK_LOG', 'PROV_ACT'].includes(history_record_type); // is allowed activity type
  return hasBackdateOption;
};

export const BackdateButton = ({ activity }: ActivityActionsProps) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const {
    backdateMutation: [backdateMutation]
  } = useContext(HistoryContext) as HistoryContextType;

  const onModalConfirm = () => {
    backdateMutation({
      variables: { memberId: activeMemberId, activitykey: activity?.activitykey ?? '' }
    });
    handleModalClose();
  };

  const cycle = Number.parseInt(activity?.cycle ?? '0');
  const backdateTargetYear = cycle - 1;

  const hasBackdateOption = isBackdateEnabledForActivity(activity);
  if (!hasBackdateOption) return null;

  return (
    <>
      <Button color="primary" variant="outlined" size="medium" onClick={handleModalOpen}>
        Backdate
      </Button>
      <Dialog open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle variant={'h4'} fontWeight={500}>
          Backdate to {backdateTargetYear}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant={'body1'}>
            Please confirm you would like this activity moved from {cycle} to {backdateTargetYear}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ borderRadius: '8px' }} color="inherit" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button sx={{ borderRadius: '8px' }} color="primary" onClick={onModalConfirm}>
            Backdate to {backdateTargetYear}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
