import { LoadableText } from 'src/components/Loadable/LoadableText';
import { isNil } from 'lodash';
import { Typography } from '@mui/material';
import { colors } from 'src/components/App/colors';

type HoursRemainingProps = {
  minimum?: number;
  hours?: number;
  completed?: boolean;
};

export const HoursRemaining = ({ hours, minimum, completed }: HoursRemainingProps) => {
  return (
    <LoadableText variant={'body1'} loading={isNil(hours)}>
      {hours != null && minimum != null && (
        <Typography variant={'caption'} sx={{ display: 'flex', fontSize: '14px', mt: 1 }}>
          <span style={{ fontWeight: 600 }}>{hours > minimum ? minimum : hours} </span>
          &nbsp;/&nbsp;
          <Typography variant={'caption'} sx={{ color: colors['charcoal.700'], fontSize: '14px' }}>
            {minimum} hours
          </Typography>
        </Typography>
      )}
    </LoadableText>
  );
};
