import React, { useState } from 'react';
import { Box, Drawer, Tabs, Menu, MenuItem, Divider } from '@mui/material';
import Tab from '@mui/material/Tab';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useLocation } from 'react-router-dom';

import { theme } from '../App/theme';
import { HeaderButton } from './HeaderButton';
import { DrawerMenu } from './DrawerMenu';

const pathNames = ['/mycpd', '/browse', '/log', '/history'];

const MainNavigation = () => {
  const [drawerContent, setDrawerContent] = useState<JSX.Element>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const closeDrawer = () => {
    setDrawerContent(undefined);
  };

  const isLogDropdownOpen = Boolean(anchorEl);
  const handleLogDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleLogDropdownClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  React.useEffect(() => {
    handleLogDropdownClose();
  }, [location]);

  const activeTabIndex = pathNames.indexOf(location.pathname);

  return (
    <>
      <Box component="nav" sx={{ height: '100%' }}>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <HeaderButton
            paperProps={{
              onClick: () => {
                setDrawerContent(<DrawerMenu onClose={closeDrawer} />);
              },
              sx: { borderWidth: 0 }
            }}
            IconComponent={MenuIcon}
          />
        </Box>
        <Tabs
          value={activeTabIndex === -1 ? false : activeTabIndex}
          sx={{
            display: { xs: 'none', md: 'flex' },
            height: '100%',
            alignItems: 'center',
            color: 'primary',
            '& .MuiTab-root': {
              display: 'flex',
              alignItems: 'center',
              ...theme.typography.body2,
              p: 0,
              minHeight: 36,
              fontSize: '1rem',
              textTransform: 'none',
              '&.active': {
                color: 'primary.main',
                fontWeight: 600
              },
              '&:hover': {
                fontWeight: 600
              }
            }
          }}
        >
          <Tab label="myCPD" component={NavLink} to="/mycpd" />
          <Tab label="Browse" component={NavLink} to="/browse" />
          <Tab
            label="Log"
            component={NavLink}
            to="/log"
            id="dropdown-log-button"
            onClick={handleLogDropdownClick}
          />
          <Tab label="History" component={NavLink} to="/history" />
        </Tabs>
        <Menu
          id="dropdown-log"
          aria-labelledby="dropdown-log-button"
          anchorEl={anchorEl}
          open={isLogDropdownOpen}
          onClose={handleLogDropdownClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <MenuItem component={NavLink} to="/log" onClick={handleLogDropdownClose}>
            Quick log
          </MenuItem>
          <MenuItem
            component="a"
            href={import.meta.env.VITE_RECORD_CPR}
            onClick={handleLogDropdownClose}
          >
            CPR
          </MenuItem>
          <MenuItem
            component="a"
            href={import.meta.env.VITE_RECORD_ALS}
            onClick={handleLogDropdownClose}
          >
            Advanced life support
          </MenuItem>
          <MenuItem
            component="a"
            href={import.meta.env.VITE_RECORD_GLA}
            onClick={handleLogDropdownClose}
          >
            GP-led activity
          </MenuItem>
          <MenuItem
            component="a"
            href={import.meta.env.VITE_QUALITY_IMPROVEMENT_TOOL}
            onClick={handleLogDropdownClose}
          >
            Measuring outcomes tool
          </MenuItem>
          <MenuItem
            component="a"
            href={import.meta.env.VITE_REFLECTION_TOOL}
            onClick={handleLogDropdownClose}
          >
            Reviewing performance tool
          </MenuItem>
          <MenuItem
            component="a"
            href={import.meta.env.VITE_RECORD_SR}
            onClick={handleLogDropdownClose}
          >
            Specific requirement
          </MenuItem>
          <Divider />
          <MenuItem
            component="a"
            href={import.meta.env.VITE_PENDING_ACTIVITIES_URL}
            onClick={handleLogDropdownClose}
          >
            Activities in progress
          </MenuItem>
        </Menu>
      </Box>

      <Drawer open={!!drawerContent} anchor={'left'} onClose={closeDrawer}>
        {drawerContent}
      </Drawer>
    </>
  );
};

export { MainNavigation };
