import {
  Attachment,
  CpdTypeInput,
  useSaveQuickLogActivityMutation,
  AttType
} from 'src/graphql/generated';
import { AttachmentsArray, QuickLogFormData } from './types';
import { useContext, useState } from 'react';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { prepareAttachments } from 'src/components/Attachments/utils';
import { useUploadAttachments } from 'src/hooks/useUploadAttachments';
import { ALLOW_PLR_CODES } from './constants';

export const useSubmitQuickLog = (options?: any) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const [fileUploadError, setFileUploadError] = useState<string | undefined>();
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const uploadFiles = useUploadAttachments(AttType.Quicklogattachments);

  const [
    saveQuickLogActivityMutation,
    { loading: saveQuickLoadLoading, error: saveQuickLogActivityError, ...rest }
  ] = useSaveQuickLogActivityMutation(options);

  return {
    loading: saveQuickLoadLoading || uploadingFiles,
    error: saveQuickLogActivityError || fileUploadError,
    ...rest,
    submit: async (data: QuickLogFormData) => {
      setFileUploadError(undefined);
      const localFiles = data.attachments.filter((a): a is File => a instanceof File);
      const previousAttachments = data.attachments.filter(
        (a): a is Attachment => !(a instanceof File)
      );

      let newAttachments: Attachment[] = [];
      if (localFiles?.length > 0) {
        try {
          setUploadingFiles(true);
          newAttachments = (await uploadFiles(localFiles)) as AttachmentsArray;
        } catch (e) {
          setFileUploadError('Failed to upload files, please try again later.');
          return;
        } finally {
          setUploadingFiles(false);
        }
      }

      const aggregatedAttachments = [
        ...prepareAttachments(previousAttachments),
        ...prepareAttachments(newAttachments)
      ];

      const {
        mo_hours,
        ea_hours,
        rp_hours,
        total_hours,
        activity_date,
        activity_title,
        notes,
        activitykey,
        program_levels: program_levels_unfiltered
      } = data;

      let cpd_types: Array<CpdTypeInput> = [
        { type: 'MO', hours: mo_hours },
        { type: 'RP', hours: rp_hours },
        { type: 'EA', hours: ea_hours }
      ];
      cpd_types = cpd_types.filter((cpdTypeInput) => cpdTypeInput.hours && cpdTypeInput.hours > 0);

      const program_levels = program_levels_unfiltered.filter((plr) =>
        ALLOW_PLR_CODES.includes(plr)
      );

      return saveQuickLogActivityMutation({
        variables: {
          activitykey,
          memberid: activeMemberId,
          hours: total_hours,
          cpd_types,
          title: activity_title,
          date: activity_date?.toISOString(),
          description: notes,
          attachments: aggregatedAttachments,
          program_levels
        }
      });
    }
  };
};
