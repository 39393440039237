import { Box, Typography, IconButton, Button } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import { keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';

import { NotificationSettingsButton } from 'src/components/NotificationSettings/NotificationSettings';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

const SearchResultsEmpty = () => {
  return (
    <Box sx={{ textAlign: 'center', py: 2, my: 2, animation: `${fadeInAnimation} 1s` }}>
      <IconButton>
        <Notifications sx={{ fontSize: '24px' }} />
      </IconButton>
      <Typography variant="body1" sx={{ color: '#6F6F6F', fontWeight: '500' }}>
        You have no notifications
      </Typography>
      <Typography variant="body1" sx={{ pt: 2 }}>
        You can set notifications on activities you are interested in. You can manage your
        notifications in the notifications settings.
      </Typography>
      <Button
        variant="outlined"
        component={Link}
        to="/browse"
        sx={{ my: 3, mr: 2, minWidth: '190px' }}
      >
        Browse activities
      </Button>
      <NotificationSettingsButton
        buttonProps={{ variant: 'outlined', sx: { my: 3, minWidth: '190px' } }}
      />
    </Box>
  );
};

export { SearchResultsEmpty };
