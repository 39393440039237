import React, { useContext } from 'react';
import { IconButton, Box, Button, Modal, Typography, Paper, Divider } from '@mui/material';
import { BookmarkBorder, Bookmark } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';

import {
  CpdActivityContent,
  useGetBookmarksQuery,
  useSaveBookmarkMutation,
  useDeleteBookmarkMutation,
  CpdActivitySession
} from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { getUniqueSessionStatesString } from 'src/components/Pages/Browse/getUniqueSessionStates';
import { useErrorHandler } from 'src/hooks/useErrorHandler';

type Props = {
  activity: CpdActivityContent | null | undefined;
  bookmarkButtonType: 'listing' | 'detail';
  hasDeleteConfirmation: Boolean;
  refreshResults?: () => void;
};

const BookmarkButton: React.FC<Props> = (props) => {
  const handleError = useErrorHandler();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const { data } = useGetBookmarksQuery({
    variables: {
      memberid: activeMemberId,
      bookmarkkey: '23-50#bookmark',
      limit: 100,
      triennium: '23-50'
    }
  });
  const thisBookmark = _.find(data?.getBookmarks?.items, { internal_id: props.activity?.id });

  const [saveBookmark] = useSaveBookmarkMutation({
    variables: {
      memberid: activeMemberId,
      internal_id: props.activity?.id,
      title: props.activity?.title,
      internal_type: 'cpd_activity',
      notes: '',
      url: ''
    },
    onError: handleError,
    refetchQueries: ['getBookmarks']
  });

  const handleSave = () => {
    if (data?.getBookmarks?.items && data?.getBookmarks?.items.length >= 100) {
      setMaxBookmarksModalOpen(true);
    } else {
      saveBookmark();
    }
  };

  const handleDelete = () => {
    if (!props.hasDeleteConfirmation) {
      deleteBookmark();
    } else {
      handleConfirmationModalOpen();
    }
  };

  const [deleteBookmark] = useDeleteBookmarkMutation({
    variables: {
      memberid: activeMemberId,
      bookmarkkey: thisBookmark?.bookmarkkey
    },
    onError: handleError,
    refetchQueries: ['getBookmarks', 'searchBookmarks']
  });
  function deleteBookmarkAndRefreshResults() {
    deleteBookmark();
    handleConfirmationModalClose();
  }

  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const [maxBookmarksModalOpen, setMaxBookmarksModalOpen] = React.useState(false);
  const handleConfirmationModalOpen = () => setConfirmationModalOpen(true);
  const handleConfirmationModalClose = () => setConfirmationModalOpen(false);
  const handleMaxBookmsrksModalClose = () => setMaxBookmarksModalOpen(false);

  const startDate = format(
    parseISO(`${props.activity?.sessionSeriesStartDate}`),
    'eee do MMM Y - p'
  );

  const sessionList = (props.activity?.sessions ?? []) as CpdActivitySession[];
  const uniqueStatesString = getUniqueSessionStatesString(sessionList);

  return (
    <Box>
      <Modal
        open={maxBookmarksModalOpen}
        onClose={handleMaxBookmsrksModalClose}
        aria-labelledby="Maximum number of bookmarks reached"
        aria-describedby="Maximum number of bookmarks reached"
      >
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            width: '90%',
            maxWidth: '600px'
          }}
        >
          <Typography variant="h3">Unable to add bookmark at this time</Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            You have reached the maximum of 100 bookmarks. To bookmark this activity, please delete
            a currently bookmarked activity.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => handleMaxBookmsrksModalClose()}
              variant="text"
              sx={{ color: '#6F6F6F' }}
            >
              Close
            </Button>
          </Box>
        </Paper>
      </Modal>
      {props.bookmarkButtonType === 'listing' && (
        <Box>
          {!thisBookmark && (
            <IconButton sx={{ p: 1 }} onClick={() => handleSave()}>
              <BookmarkBorder color="secondary" sx={{ fontSize: '24px' }} />
            </IconButton>
          )}
          {thisBookmark && (
            <IconButton sx={{ p: 1 }} onClick={() => handleDelete()}>
              <Bookmark color="secondary" sx={{ fontSize: '24px' }} />
            </IconButton>
          )}
        </Box>
      )}

      {props.bookmarkButtonType === 'detail' && (
        <Box>
          {!thisBookmark && (
            <Button
              onClick={() => handleSave()}
              size="large"
              variant="bordered"
              sx={{ backgroundColor: '#fff' }}
            >
              <BookmarkBorder sx={{ fontSize: '22px' }} />
            </Button>
          )}
          {thisBookmark && (
            <Button
              onClick={() => handleDelete()}
              size="large"
              variant="bordered"
              sx={{ backgroundColor: '#fff' }}
            >
              <Bookmark sx={{ fontSize: '22px', color: 'primary.main' }} />
            </Button>
          )}
        </Box>
      )}
      <Modal
        open={confirmationModalOpen}
        onClose={handleConfirmationModalClose}
        aria-labelledby="delete bookmark"
        aria-describedby="delete bookmark"
      >
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            width: '90%',
            maxWidth: '600px'
          }}
        >
          <Typography variant="h3">Remove Bookmark</Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            Are you sure you wish to
            <span style={{ fontWeight: 600 }}> Remove Bookmark?</span> <br />
            You cannot undo this operation.
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body2" color="secondary">
            {startDate}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {props.activity?.title}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 600, mt: 2 }}>
            {props.activity?.targetAudience}
          </Typography>
          <Typography variant="body2">{uniqueStatesString}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => handleConfirmationModalClose()}
              variant="text"
              sx={{ color: '#6F6F6F' }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleteBookmarkAndRefreshResults()}
              variant="text"
              sx={{ color: 'primary.main' }}
            >
              Remove Bookmark
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export { BookmarkButton };
