import arterialeducation from 'src/assets/provider-logos/arterialeducation.png';
import cfep from 'src/assets/provider-logos/cfep.png';
import agpal from 'src/assets/provider-logos/agpal.png';
import familyDoctorLogo from 'src/assets/provider-logos/FamilyDoctor.png';
import healthed from 'src/assets/provider-logos/healthed.png';
import healthcert from 'src/assets/provider-logos/healthcert.png';
import myhealth from 'src/assets/provider-logos/myhealth.png';
import gpce from 'src/assets/provider-logos/gpce.png';
import thinkgp from 'src/assets/provider-logos/thinkgp.png';
import smc from 'src/assets/provider-logos/smc.png';
import sonic from 'src/assets/provider-logos/sonic.png';
import ausdoc from 'src/assets/provider-logos/ausdoc.png';
import avant from 'src/assets/provider-logos/avant.png';
import healthscope from 'src/assets/provider-logos/healthscope.png';
import mips from 'src/assets/provider-logos/mips.png';
import ochrehealth from 'src/assets/provider-logos/ochrehealth.png';
import ramsay from 'src/assets/provider-logos/rhc.png';
import svha from 'src/assets/provider-logos/svha.png';
import medcast from 'src/assets/provider-logos/medcast.png';
import epworth from 'src/assets/provider-logos/epworth.png';
import praxhub from 'src/assets/provider-logos/praxhub.png';

export const ProvidersData = [
  /* Tier 3 Providers */

  {
    tier: 'Tier1',
    name: 'Epworth',
    description:
      'Epworth is Victoria’s largest not-for-profit private hospital group, renowned for excellence in diagnosis, treatment, care and rehabilitation. Our highly trained and dedicated staff deliver specialist services across Melbourne and Geelong. At Epworth, our patients are at the heart of everything we do.',
    providerLogoUrlList: [
      {
        logoTitle: 'Epworth',
        logo: epworth,
        link: 'https://www.epworth.org.au/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier3',
    name: 'HealthCert',
    description:
      "HealthCert Education is Australia's leading provider of university quality-assured and CPD-accredited education for medical professionals. Our online courses and practical workshops provide foundation to advanced knowledge at professional certificate, advanced certificate, and professional diploma levels in various medical fields.",
    providerLogoUrlList: [
      {
        logoTitle: 'HealthCert',
        logo: healthcert,
        link: 'https://healthcert.com/?utm_source=racgp&utm_medium=referral&utm_campaign=racgp',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier3',
    name: 'Healthed',
    description:
      'Healthed deliver high-quality CPD education by clinicians, for clinicians. Podcasts, webcasts, online learning modules and articles PLUS Australia’s most popular one-day seminars for GPs.',
    providerLogoUrlList: [
      {
        logoTitle: 'Healthed',
        logo: healthed,
        link: 'https://www.healthed.com.au/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier2',
    name: 'Medcast',
    description:
      'Medcast is an Australian leader in the delivery of CPD, delivering excellence and innovation in healthcare education. Activities are tailored to the evolving needs of GPs and presented as webinars, eLearning courses, podcasts, audits and more. Medcast also delivers resuscitation training, online CPR and face-to-face courses.',
    providerLogoUrlList: [
      {
        logoTitle: 'Medcast',
        logo: medcast,
        link: 'https://medcast.com.au/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier3',
    name: 'Myhealth',
    description:
      'Myhealth is a GP-led community that aims to provide relevant and up-to-date training opportunities to all GPs.',
    providerLogoUrlList: [
      {
        logoTitle: 'Myhealth',
        logo: myhealth,
        link: 'https://academy.myhealth.net.au/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier1',
    name: 'Praxhub',
    description:
      'Redefining innovation and quality in Australian CPD, Praxhub offers a unique blend of top-quality, in-house education production and curated content from diverse providers and health institutions. This one-stop CPD destination provides healthcare professionals a wide range of learning opportunities, all on a free, user-friendly platform.',
    providerLogoUrlList: [
      {
        logoTitle: 'Praxhub',
        logo: praxhub,
        link: 'https://praxhub.com/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier3',
    name: 'Reed Medical Education',
    description:
      'Providing highly practical, independent, clinical CPD education. Interact and learn with colleagues and peers at our conferences, or complete webinars, clinical audits, and advanced courses at your own pace online.',
    providerLogoUrlList: [
      {
        logoTitle: 'Reed Medical Education - GPCE',
        logo: gpce,
        link: 'https://www.gpce.com.au/',
        linkText: 'View GPCE'
      },
      {
        logoTitle: 'Reed Medical Education - ThinkGp',
        logo: thinkgp,
        link: 'https://www.thinkgp.com.au/',
        linkText: 'View ThinkGP'
      }
    ]
  },
  {
    tier: 'Tier3',
    name: 'Scale My Clinic',
    description:
      'Scale My Clinic is Australia’s only business coaching service exclusively dedicated to helping private GP Owners improve their businesses.',
    providerLogoUrlList: [
      {
        logoTitle: 'Scale My Clinic',
        logo: smc,
        link: 'https://www.scalemyclinic.com.au/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier3',
    name: 'Sonic Healthcare',
    description:
      'Sonic Healthcare recognises and supports the importance of medical education, research and professional advocacy. We are committed to resource and promote education at both an individual and organisational level.',
    providerLogoUrlList: [
      {
        logoTitle: 'Sonic Healthcare',
        logo: sonic,
        link: 'https://www.sonichealthcare.com/',
        linkText: 'View more'
      }
    ]
  },
  /* Tier 2 Providers */
  /* Tier 1 Providers */
  {
    tier: 'Tier1',
    name: 'Arterial Education',
    description:
      'Arterial Education offers inspiring video-rich medical education. Our bespoke approach ensures innovative programmes underpinned by neuroscience and adult learning principles.',
    providerLogoUrlList: [
      {
        logoTitle: 'Arterial Education',
        logo: arterialeducation,
        link: ' https://www.arterialeducation.com/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier1',
    name: 'Australian Doctor',
    description:
      'How to Treat CPD approved articles are written specifically for Australian GPs by local specialists and represent current clinical best practice.',
    providerLogoUrlList: [
      {
        logoTitle: 'Australian Doctor',
        logo: ausdoc,
        link: 'https://www.ausdoc.com.au/how-to-treat',
        linkText: 'Register to Login'
      }
    ]
  },
  {
    tier: 'Tier1',
    name: 'Avant',
    description:
      'Avant is a member-owned organisation that protects and supports over half of doctors in Australia throughout their careers and lives.',
    providerLogoUrlList: [
      { logoTitle: 'Avant', logo: avant, link: 'https://www.avant.org.au/', linkText: 'View more' }
    ]
  },
  {
    tier: 'Tier1',
    name: 'CFEP Surveys',
    description:
      'As a leading expert in healthcare surveys, discover how CFEP Surveys’ offerings can support RACGP Members to meet their CPD, accreditation and quality improvement requirements.',
    providerLogoUrlList: [
      {
        logoTitle: 'CFEP Surveys',
        logo: cfep,
        link: 'https://cfepsurveys.com.au/',
        linkText: 'View CFEP'
      },
      {
        logoTitle: 'AGPAL Surveys',
        logo: agpal,
        link: 'https://www.agpal.com.au/',
        linkText: 'View AGPAL'
      }
    ]
  },
  {
    tier: 'Tier1',
    name: 'Family Doctor',
    description:
      'We are a network of GP owned and operated medical practices committed to the provision of comprehensive patient care by experienced General Practitioners who choose to work in our facilities.',
    providerLogoUrlList: [
      {
        logoTitle: 'Family Doctor',
        logo: familyDoctorLogo,
        link: 'https://www.familydoctor.com.au ',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier1',
    name: 'Healthscope',
    description:
      'Healthscope offers RACGP approved, high-quality, online and face-to-face GP educational activities delivered by Australia’s leading specialists and doctors.',
    providerLogoUrlList: [
      {
        logoTitle: 'Healthscope',
        logo: healthscope,
        link: 'https://healthscope.com.au/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier1',
    name: 'Medical Indemnity Protection Society',
    description:
      'As specialist providers of medical indemnity, the MIPS risk education library covers everything you need to minimise your risk.',
    providerLogoUrlList: [
      { logoTitle: 'MIPS', logo: mips, link: 'https://www.mips.com.au/', linkText: 'View more' }
    ]
  },
  {
    tier: 'Tier1',
    name: 'Ochre Health',
    description:
      'An Australian GP-owned practice network committed to education, training and delivering quality patient care.',
    providerLogoUrlList: [
      {
        logoTitle: 'Ochre Health',
        logo: ochrehealth,
        link: 'https://ochrehealth.com.au/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier1',
    name: 'Ramsay Health Care',
    description:
      'As Australia’s largest private health care provider, Ramsay Health Care is committed to the ongoing education of general practitioners and the wider medical community.',
    providerLogoUrlList: [
      {
        logoTitle: 'Ramsay Health Care',
        logo: ramsay,
        link: 'https://www.ramsayhealth.com.au/',
        linkText: 'View more'
      }
    ]
  },
  {
    tier: 'Tier2',
    name: "St Vincent's Health Australia",
    description:
      'St Vincent’s Health Australia, experts in education, underpinned by innovation and informed by current research.',
    providerLogoUrlList: [
      {
        logoTitle: "St Vincent's Health Australia",
        logo: svha,
        link: 'https://www.svha.org.au/',
        linkText: 'View more'
      }
    ]
  }
];
