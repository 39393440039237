import { Box, LinearProgress } from '@mui/material';
import LogoImg from 'src/assets/racgp-cpd-logo.png';

export const Loading = () => {
  return (
    <Box
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <img src={LogoImg} alt="logo" style={{ maxHeight: 48 }} />
      <LinearProgress
        variant={'indeterminate'}
        color={'primary'}
        sx={{ height: 6, width: 270, borderRadius: 8, mt: 2 }}
      />
    </Box>
  );
};
