import React from 'react';
import { Box } from '@mui/material';
import GooglePlay from 'src/assets/google-play.png';
import AppStore from 'src/assets/app-store.png';

const AppPromote = () => {
  return (
    <Box sx={{ display: 'flex', mt: 2, '& a:last-of-type': { ml: 2 }, img: { maxHeight: '36px' } }}>
      <a
        href="https://play.google.com/store/apps/details?id=com.racgpapp"
        target="_blank"
        rel="noreferrer"
      >
        <img src={GooglePlay} alt="Google Play" />
      </a>
      <a href="https://apps.apple.com/au/app/mycpd/id1632140770" target="_blank" rel="noreferrer">
        <img src={AppStore} alt="App Store" />
      </a>
    </Box>
  );
};
export { AppPromote };
