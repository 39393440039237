import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

type SectionProps = {
  title: string;
  required: boolean;
  children: ReactNode;
};

export const Section = ({ title, children, required }: SectionProps) => {
  return (
    <Box
      sx={{
        gridTemplateAreas: {
          md: `
            "title content"
            `,
          xs: `
            "title"
            "content"
              `
        },
        gridTemplateColumns: { md: `1fr 2fr`, xs: `1fr` }
      }}
      display={'grid'}
      gap={2}
    >
      <Box gridArea={'title'}>
        <Typography noWrap={true} variant={'body1'} color={'primary'} fontWeight={600}>
          {title}{' '}
          <Typography variant={'caption'} fontWeight={400}>
            {required ? '(Required*)' : '(Optional)'}
          </Typography>
        </Typography>
      </Box>
      <Box gridArea={'content'}>{children}</Box>
    </Box>
  );
};
