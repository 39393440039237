import { useContext, useMemo } from 'react';
import { Container, Box, MenuItem, Select, Button } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { useCycles } from 'src/hooks/useCycles';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { useGetRequirementsSummaryQuery, useGetPdpDetailsQuery } from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { extractRequirements } from 'src/components/Pages/MyCPD/functions/extract-requirements';

const RequirementsPage = () => {
  const handleError = useErrorHandler();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const { activeCycle, setActiveCycle, cyclesList } = useCycles();

  const { data: requirementsSummaryData } = useGetRequirementsSummaryQuery({
    variables: { memberid: activeMemberId, date: activeCycle + '-01-01' },
    fetchPolicy: 'cache-and-network',
    onError: handleError
  });

  const { data: pdpDetailsData } = useGetPdpDetailsQuery({
    variables: { memberid: activeMemberId, pdp_year: activeCycle },
    fetchPolicy: 'cache-and-network',
    onError: handleError
  });

  const {
    requirementMBA,
    categoryGroupEA,
    categoryGroupPDP,
    categoryGroupBLS,
    categoryGroupRPMO,
    categoryGroupRPMOEU,
    categoryGroupPLR,
    requirementRACGP
  } = useMemo(() => {
    return extractRequirements(requirementsSummaryData);
  }, [requirementsSummaryData]);

  const eaHours =
    requirementMBA?.category_code_totals?.find((code) => code?.type === 'EA')?.hours ?? 0;
  const rpHours =
    requirementMBA?.category_code_totals?.find((code) => code?.type === 'RP')?.hours ?? 0;
  const moHours =
    requirementMBA?.category_code_totals?.find((code) => code?.type === 'MO')?.hours ?? 0;
  const flatRequirements = {
    partitionkey: requirementsSummaryData?.getRequirementsSummary?.cycle,
    sortkey: activeMemberId,
    id: `${requirementsSummaryData?.getRequirementsSummary?.cycle}_${activeMemberId}`,
    ea_total_hours: eaHours,
    rp_total_hours: rpHours,
    mo_total_hours: moHours,
    all_total_hours: requirementMBA?.hours,
    ea_metreq: categoryGroupEA?.completed,
    rpmo_metreq: categoryGroupRPMO?.completed,
    any_metreq: categoryGroupRPMOEU?.completed,
    total_hours_metreq: (requirementMBA?.hours ?? 0) >= (requirementMBA?.minimum ?? 50),
    pdp_status: pdpDetailsData?.getPdpDetails?.pdp_status,
    pdp_metreq: categoryGroupPDP?.completed,
    csp_metreq: categoryGroupPLR?.program_levels?.includes('CSP'),
    hi_metreq: categoryGroupPLR?.program_levels?.includes('HI'),
    ep_metreq: categoryGroupPLR?.program_levels?.includes('EP'),
    plr_metreq: categoryGroupPLR?.completed,
    mba_metreq: requirementMBA?.completed,
    triennium: '23-25',
    cpr_metreq: categoryGroupBLS?.completed,
    calculation_time: new Date()
  };
  const requirementsData = { ...flatRequirements, ...(requirementsSummaryData ?? {}) };

  const requirementsDataString = JSON.stringify(requirementsData, null, 2);

  return (
    <>
      <ContentPageTitle currentPageTitle="Requirements" />

      <Container maxWidth="lg">
        <Box
          sx={{
            gridArea: 'cycle-selector',
            justifySelf: 'flex-end',
            display: 'flex',
            alignItems: 'end'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigator.clipboard.writeText(requirementsDataString)}
          >
            Copy
          </Button>
          <Select
            value={activeCycle}
            onChange={(event) => setActiveCycle(event.target.value)}
            sx={{
              ml: 2,
              borderRadius: '8px',
              backgroundColor: 'white'
            }}
            size="small"
            renderValue={(value) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', color: '#323232' }}>
                  <CalendarTodayIcon sx={{ pr: 1, fontSize: '15px' }} />
                  Year: {`  `}
                  {value}
                </Box>
              );
            }}
          >
            {cyclesList.map((cycle) => {
              return (
                <MenuItem value={cycle} key={cycle}>
                  {cycle}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box>
          <pre>{requirementsDataString}</pre>
        </Box>
      </Container>
    </>
  );
};
export { RequirementsPage };
