import { useContext } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { last } from 'lodash';

import {
  useGetHighlevelRequirementListByEndDateQuery,
  useGetMemberHlrDetailsQuery,
  HighLevelRequirementDetail,
  useGetMemberHlrSearchQuery,
  HlrActivityTypeDateRangeInput
} from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { HLRForm } from './HLRForm';
import { LoadableBox } from 'src/components/Loadable/LoadableBox';

const HlrPage = () => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const { activitykey } = useParams<{ activitykey: string }>();

  const decodedActivityKey = decodeURIComponent(activitykey);
  const hlrEndDateUTCString = last(decodedActivityKey.split('_')) ?? '';

  // const { data, loading: isLoadingMemberHlrDetails } = useGetMemberHlrDetailsQuery({
  //   variables: { memberId: activeMemberId, asAtDate: asAtDate },
  //   fetchPolicy: 'cache-and-network'
  // });

  // const { data: HLRData, loading: isLoadingMemberHlrSearch } = useGetMemberHlrSearchQuery({
  //   variables: { memberId: activeMemberId, dateAsAt: asAtDate },
  //   fetchPolicy: 'cache-and-network'
  // });

  const { data, loading } = useGetHighlevelRequirementListByEndDateQuery({
    variables: { memberId: activeMemberId, endDate: hlrEndDateUTCString },
    fetchPolicy: 'no-cache'
  });

  const activeHlrDetails = data?.getMemberHlrDetails?.find(
    (currentHlr) => currentHlr?.sortkey === decodedActivityKey
  ) as HighLevelRequirementDetail;

  const activeHlrActivity_type_id = activeHlrDetails?.activity_type_id;

  const activeHLRActivityTypeDateRange = data?.getMemberHlrSearch?.items?.find(
    (HLRDetails) => HLRDetails?.activity_type_id === activeHlrActivity_type_id
  ) as HlrActivityTypeDateRangeInput;

  return (
    <>
      <ContentPageTitle currentPageTitle={activeHlrDetails?.title!} showImpersonate={true} />
      <Container maxWidth="lg">
        <LoadableBox loading={loading}>
          <HLRForm
            hlrCardDetails={activeHlrDetails}
            activeHLRActivityTypeDateRange={activeHLRActivityTypeDateRange}
          />
        </LoadableBox>
      </Container>
    </>
  );
};
export { HlrPage };
