import { MemberActivity, PageViewport } from './types';
import { CpdType } from '../../../graphql/generated';
import sortBy from 'lodash/sortBy';

export const getPageViewportText = ({ pageSize, pageNumber, visibleRows }: PageViewport) => {
  const start = Math.min(pageNumber * pageSize + 1, visibleRows);
  const end = Math.min(pageSize * (pageNumber + 1), visibleRows);

  return visibleRows > 0
    ? `Showing ${start}-${end} of ${visibleRows} results`
    : `Showing 0 results`;
};

export const isActivitySelfRecorded = (activity: MemberActivity) =>
  activity?.history_record_type === 'SELF_DIR_LOG' || activity?.history_record_type === 'QUICK_LOG';

export const reorderCPDTypes = (cpdTypes: Array<CpdType | null>) => {
  const order: { [key: string]: number } = { EA: 1, RP: 2, MO: 3 };
  return sortBy(cpdTypes, (a) => {
    return (!!a?.type && order[a.type]) || 4;
  });
};
