import React from 'react';
import { Box, Chip, useTheme } from '@mui/material';
import { SearchActivityItem } from 'src/graphql/generated';

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
};

const CPDHours: React.FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <Box>
      <Chip
        label={props?.searchActivity?.activity?.hours + ' CPD hour(s)'}
        size="small"
        color="secondary"
        sx={{ ...theme.typography.body2, color: '#fff', mr: 1 }}
      />
    </Box>
  );
};

export { CPDHours };
