import {
  GridCellParams,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridLinkOperator
} from '@mui/x-data-grid-pro';
import { intersection } from 'lodash';
import { format } from 'date-fns';

export const arrayIncludesOperator: GridFilterOperator = {
  label: 'Includes',
  value: 'includes',
  getApplyFilterFn:
    (filterItem: GridFilterItem) =>
    (params: GridCellParams): boolean => {
      return params.value.includes(filterItem.value);
    }
};

export const arrayIntersectionOperator: GridFilterOperator = {
  label: 'Intersects',
  value: 'intersects',
  getApplyFilterFn:
    (filterItem: GridFilterItem) =>
    (params: GridCellParams): boolean => {
      return intersection(params.value, filterItem.value).length > 0;
    }
};

export const getSearchOperator = (columnsToSearchNameList: string[]): GridFilterOperator => ({
  label: 'Search',
  value: 'search',
  getApplyFilterFn:
    (filterItem: GridFilterItem) =>
    (params: GridCellParams): boolean => {
      const valueToMatch = filterItem.value.toLowerCase();
      return columnsToSearchNameList.reduce((previousValue, columnToSearchName) => {
        if (previousValue) {
          return previousValue;
        }
        const valueToSearch = params.value[columnToSearchName];
        const hasMatchInColumn = valueToSearch?.toLowerCase()?.includes(valueToMatch);
        return hasMatchInColumn;
      }, false);
    }
});

export const cycleOperator = {
  label: 'Cycle',
  value: 'cycle',
  getApplyFilterFn:
    (filterItem: GridFilterItem) =>
    (params: GridCellParams): boolean => {
      return parseInt(format(params.value, 'yyyy')) === filterItem.value;
    }
};

const getCPDTypeFilterItems = (cpdTypes: string[]) => {
  return [
    {
      id: 0,
      columnField: 'cpd_type',
      operatorValue: 'intersects',
      value: cpdTypes
    }
  ];
};

const getSearchKeywordFilterItems = (keyword: string) => {
  return [
    {
      id: 1,
      columnField: 'activity',
      operatorValue: 'search',
      value: keyword
    }
  ];
};

const getCycleFilterItems = (cycle: number) => {
  return [
    {
      id: 2,
      columnField: 'cycle',
      operatorValue: 'cycle',
      value: cycle
    }
  ];
};

const getSelfRecordedFilterItems = () => {
  return [
    {
      id: 3,
      columnField: 'history_record_type',
      operatorValue: 'isAnyOf',
      value: ['SELF_DIR_LOG', 'QUICK_LOG']
    }
  ];
};

export const getFilterModel = ({
  cpdTypes,
  searchKeyword,
  cycle,
  selfRecordedOnly
}: {
  cpdTypes?: string[];
  cycle?: number;
  searchKeyword?: string;
  selfRecordedOnly: boolean;
}): GridFilterModel => {
  let filters = [];
  if (cpdTypes && cpdTypes?.length > 0) {
    filters.push(...getCPDTypeFilterItems(cpdTypes));
  }

  if (searchKeyword) {
    filters.push(...getSearchKeywordFilterItems(searchKeyword));
  }

  if (cycle) {
    filters.push(...getCycleFilterItems(cycle));
  }

  if (selfRecordedOnly) {
    filters.push(...getSelfRecordedFilterItems());
  }

  return {
    items: filters,
    linkOperator: GridLinkOperator.And
  };
};
