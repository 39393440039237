import { Box, CircularProgress as MUICircularProgress } from '@mui/material';
type CircularProgressProps = {
  value: number;
  color?: string;
  size?: number;
};

export const CircularProgress = ({
  color = '#11364D',
  size = 130,
  value
}: CircularProgressProps) => {
  return (
    <Box position={'relative'} width={size} height={size}>
      <MUICircularProgress
        thickness={6}
        size={'100%'}
        sx={{
          position: 'relative',
          left: 0,
          top: 0,
          color: '#D2DCE1',
          backgroundColor: 'transparent'
        }}
        variant={'determinate'}
        value={100}
      />
      <MUICircularProgress
        thickness={6}
        size={'100%'}
        sx={{
          strokeLinecap: 'round',
          position: 'absolute',
          left: 0,
          top: 0,
          color,
          backgroundColor: 'transparent',
          filter: 'drop-shadow(-4px 0px 4px rgba(0, 0, 0, .15))'
        }}
        variant={'determinate'}
        value={value === 0 ? 1 : value}
      />
    </Box>
  );
};
