import _ from 'lodash';

import { CpdActivitySession } from 'src/graphql/generated';

export const getUniqueSessionStates = (sessionList: CpdActivitySession[]) => {
  const sessionStateList = sessionList.map((session) => session.state ?? '');
  const uniqueSessionStateList = _.uniqBy(sessionStateList, (sessionString) => sessionString);
  const sortedSessionStateList = uniqueSessionStateList.sort((a, b) => a.localeCompare(b));
  return sortedSessionStateList;
};

export const getUniqueSessionStatesString = (sessionList: CpdActivitySession[]) => {
  const sortedSessionStateList = getUniqueSessionStates(sessionList);
  const uniqueSessionStatesString = sortedSessionStateList.join(', ');
  return uniqueSessionStatesString;
};
