import React, { useMemo } from 'react';
import { Box, Divider, Fade, Slide, Typography, useMediaQuery, useTheme } from '@mui/material';
import { colors } from 'src/components/App/colors';
import { CpdType, GetReferencesQuery, useGetReferencesQuery } from 'src/graphql/generated';

import { MemberActivity } from './types';
import { reorderCPDTypes } from './utils';
import { truncate } from 'lodash';

type ActivityDetailsProps = {
  activity: MemberActivity;
};

export const getActivityTypeLabel = (activity: MemberActivity, references: GetReferencesQuery) => {
  if (activity?.activity_type) {
    const item = references.activity_type?.items?.find(
      (activityType) => activityType?.key === activity.activity_type
    );

    if (!item) {
      console.warn(
        'activity_type exists on activity record but does not exist on key-value references lookup'
      );

      return activity?.activity_type;
    }

    return item.value;
  } else {
    if (activity?.history_record_type === 'QUICK_LOG') {
      return 'Quick Log';
    }
    if (activity?.history_record_type === 'SELF_DIR_LOG') {
      return 'Self Directed Log';
    }

    console.warn(
      'activity_type does not exist on record and history_record_type is not one of QUICK_LOG or SELF_DIR_LOG, label could not be determined'
    );
    return undefined;
  }
};

export const getCPDTypeLabel = (requirementCode: string) => {
  switch (requirementCode) {
    case 'RP':
      return 'Reviewing Performance';
    case 'MO':
      return 'Measuring Outcomes';
    case 'EA':
      return 'Educational Activities';
    default:
      return undefined;
  }
};

export const ActivityDetails = ({ activity }: ActivityDetailsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data } = useGetReferencesQuery();

  const activityTypeLabel = useMemo(() => {
    if (data && activity) {
      return getActivityTypeLabel(activity, data);
    }
    return undefined;
  }, [activity, data]);

  const reorderedCpdTypes = useMemo(() => {
    if (activity?.cpd_types == null) {
      return undefined;
    }
    return reorderCPDTypes(activity.cpd_types);
  }, [activity?.cpd_types]);

  const truncatedDescription = useMemo(() => {
    if (!activity?.description) {
      return undefined;
    }
    return truncate(activity.description, { length: 250 });
  }, [activity?.description]);

  if (isMobile) {
    return (
      <Slide in={true}>
        <Box
          sx={{
            bgcolor: 'white',
            p: 2,
            borderWidth: 0,
            borderBottomColor: `${colors['stone.100']} !important`,
            borderBottomWidth: 1,
            borderStyle: 'solid'
          }}
        >
          {truncatedDescription && (
            <Box mb={2}>
              <Typography mb={0.5} variant={'body2'} fontWeight={600}>
                Reflection Notes:
              </Typography>
              <Typography variant={'body2'}>{truncatedDescription}</Typography>
            </Box>
          )}
          {reorderedCpdTypes && (
            <Box flex={1}>
              <CPDTypesDetails cpdTypes={reorderedCpdTypes}></CPDTypesDetails>
            </Box>
          )}
          {activityTypeLabel && (
            <Typography mt={2} variant={'body2'}>
              Activity Type: {activityTypeLabel}
            </Typography>
          )}
          {activity?.req_eligibility!.map((specificReqCode) => (
            <Typography variant={'body2'} key={specificReqCode}>
              {
                data?.specific_req_label?.items!.find(
                  (specificReqLabel) => specificReqLabel?.key === specificReqCode
                )?.value
              }
            </Typography>
          ))}

          <Typography mt={2} variant={'body2'}>
            Activity ID: {activity?.cpd_id || 'Pending'}
          </Typography>
        </Box>
      </Slide>
    );
  }

  return (
    <Fade in={true} timeout={500}>
      <Slide in={true} timeout={300}>
        <Box
          sx={{
            bgcolor: 'white',
            p: 2,
            gap: 4,
            display: 'flex',
            borderWidth: 0,
            borderBottomColor: `${colors['stone.100']} !important`,
            borderBottomWidth: 1,
            borderStyle: 'solid'
          }}
        >
          <Box flex={2} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
            {truncatedDescription && activity?.history_record_type !== 'HLR' && (
              <Box mb={2}>
                <Typography mb={0.5} variant={'body2'} fontWeight={600}>
                  Reflection Notes:
                </Typography>
                <Typography variant={'body2'}>{truncatedDescription}</Typography>
              </Box>
            )}
            <Box display={'flex'}>
              <Box>
                {activityTypeLabel && (
                  <Typography mr={4} variant={'body2'}>
                    Activity Type:{' '}
                    {activity?.history_record_type !== 'HLR'
                      ? activityTypeLabel
                      : 'Specialist High Level Requirement'}
                  </Typography>
                )}

                {activity?.req_eligibility!.map((specificReqCode) => (
                  <Typography variant={'body2'} key={specificReqCode}>
                    {data?.specific_req_label?.items!.find(
                      (specificReqLabel) => specificReqLabel?.key === specificReqCode
                    )?.value ?? specificReqCode}
                  </Typography>
                ))}
              </Box>
              <Typography variant={'body2'}>
                Activity ID: {activity?.cpd_id || 'Pending'}
              </Typography>
            </Box>
          </Box>
          {reorderedCpdTypes && (
            <Box flex={1}>
              <CPDTypesDetails cpdTypes={reorderedCpdTypes}></CPDTypesDetails>
            </Box>
          )}
        </Box>
      </Slide>
    </Fade>
  );
};

type CPDTypesDetailsProps = {
  cpdTypes: Array<CpdType | null>;
};

const CPDTypesDetails = ({ cpdTypes }: CPDTypesDetailsProps) => {
  return (
    <>
      {cpdTypes.map((cpdType) => {
        return (
          <Box key={cpdType?.type}>
            {cpdType?.type && cpdType?.hours! > 0 && (
              <Box>
                <Box py={2} display={'flex'} justifyContent={'space-between'}>
                  <Typography variant={'caption'} fontWeight={600}>
                    {getCPDTypeLabel(cpdType?.type)}
                  </Typography>
                  <Typography variant={'caption'}>{cpdType?.hours} CPD hours</Typography>
                </Box>
                <Divider />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};
