import { useContext } from 'react';
import { Paper, Button, useTheme, Box } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  SearchActivityItem,
  useUpdateNotificationsMutation,
  NotificationType,
  NotificationStatus,
  CpdActivitySession
} from 'src/graphql/generated';
import { keyframes } from '@emotion/react';
import _ from 'lodash';
import { Notification } from 'src/graphql/generated';
import { RemoveNotificationButton } from './RemoveNotificationButton';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { Requirements } from '../Browse/Atoms/Requirements';
import { CPDHours } from '../Browse/Atoms/CPDHours';
import { States } from '../Browse/Atoms/States';
import { Title } from '../Browse/Atoms/Title';
import { Provider } from '../Browse/Atoms/Provider';
import { DeliveryMode } from '../Browse/Atoms/DeliveryMode';
import { SessionTime } from '../Activity/SessionTime';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
  notification: Notification | null | undefined;
  sessionId: string | undefined;
};

const ActivityResult: React.FC<Props> = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const { searchActivity } = props;
  const session = _.find(props?.searchActivity?.activity?.sessions, function (obj) {
    // eslint-disable-next-line
    return obj?.eventId == props.sessionId;
  }) as CpdActivitySession;

  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    updateNotifications();
    history.push(`/activity/${props?.searchActivity?.activity?.id}`, {
      state: { backTo: '/notifications' }
    });
  };

  var notification_type = NotificationType.CpdUpdate;
  if (props.notification?.type) {
    notification_type = props.notification?.type;
  }
  var notification_status = NotificationStatus.Read;

  const [updateNotifications] = useUpdateNotificationsMutation({
    variables: {
      memberid: activeMemberId,
      notifications: [
        {
          type: notification_type,
          reference_id: props.notification?.reference_id,
          notification_status: notification_status
        }
      ]
    },
    refetchQueries: ['getNotifications']
  });
  return (
    <Paper
      sx={{
        p: 2,
        mt: 2,
        animation: `${fadeInAnimation} 0.5s`,
        border:
          props.notification?.notification_status === 'unread'
            ? '3px solid' + theme.palette.secondary.main
            : 'transparent'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Requirements searchActivity={props.searchActivity} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1
          }}
        >
          <CPDHours searchActivity={props.searchActivity} />

          <RemoveNotificationButton
            notification={props.notification}
            activity={props?.searchActivity?.activity}
          />
        </Box>
      </Box>
      <SessionTime session={session} />
      <Title searchActivity={props.searchActivity} />
      <Provider searchActivity={props.searchActivity} />
      <States searchActivity={searchActivity} />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 4
        }}
      >
        <DeliveryMode searchActivity={props.searchActivity} />
        <Button onClick={handleClick}>
          View Activity <ChevronRight />
        </Button>
      </Box>
    </Paper>
  );
};

export { ActivityResult };
