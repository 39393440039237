import React from 'react';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const autocompleteCustomStyles = {
  mr: { sm: 2 },
  'input#search-keyword': { paddingLeft: { xs: 4, sm: 1 } },
  'label#search-keyword-label': { paddingLeft: { xs: 4, sm: 'initial' } },
  'label#search-keyword-label.Mui-focused': { paddingLeft: { xs: 'initial' } }
};

type SearchBarProps = {
  keyword?: string | null;
  onSelectKeyword: (keyword: string) => void;
  onExecuteSearch: () => void;
  suggestedKeywordsList: string[];
};

export const SearchBar = ({
  onSelectKeyword,
  onExecuteSearch,
  suggestedKeywordsList,
  keyword = null
}: SearchBarProps) => {
  function handleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    onSelectKeyword(event?.currentTarget.value ?? '');
  }
  return (
    <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: 'center' }}>
      <Autocomplete
        sx={autocompleteCustomStyles}
        fullWidth
        id="search-keyword"
        freeSolo
        disableClearable
        filterOptions={(x) => x}
        value={keyword ?? ''}
        onChange={(event: any, selectedKeyword: string) => {
          onSelectKeyword(selectedKeyword);
        }}
        options={suggestedKeywordsList}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option, inputValue, { insideWords: true });
          const parts = parse(option, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part: any, index: any) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        renderInput={(params) => (
          <Box sx={{ display: 'flex', alignItems: 'flex-start', position: 'relative' }}>
            <SearchIcon
              onClick={onExecuteSearch}
              sx={{
                cursor: 'pointer',
                display: { xs: 'flex', sm: 'none' },
                position: 'absolute',
                alignItems: 'center',
                height: '100%',
                left: '10px',
                zIndex: '9',
                color: '#49454F'
              }}
            />
            <TextField
              {...params}
              label="Search"
              type="search"
              autoFocus
              onChange={handleChange}
              onKeyPress={(event) => event.key === 'Enter' && onExecuteSearch()}
              sx={{}}
            />
          </Box>
        )}
      />
      <Box sx={{ mt: { xs: 1, sm: 0 } }}>
        <Button
          variant="contained"
          onClick={onExecuteSearch}
          sx={{ px: 6, width: { xs: '100%', sm: 'initial' } }}
        >
          Search
        </Button>
      </Box>
    </Grid>
  );
};
