import { Button, ButtonProps, Icon, SxProps, Theme, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { colors } from 'src/components/App/colors';

type NavLinkButtonType = ButtonProps & {
  buttonText: String;
  icon: React.ElementType;
  component: React.ElementType;
  to?: String | null;
  linkTarget?: String | null;
  sx?: SxProps<Theme>;
};

export const NavLinkButton = ({ buttonText, icon, sx, ...otherProps }: NavLinkButtonType) => {
  return (
    <Button
      size="large"
      fullWidth
      variant="outlined"
      sx={{
        paddingY: '14px',
        marginBottom: 1,
        borderRadius: '8px',
        borderColor: colors['stone.100'],
        '&:hover, &:active': {
          borderColor: colors['stone.100']
        },
        '& svg': {
          fontSize: 28
        },
        ...sx
      }}
      startIcon={
        <Icon
          component={icon}
          fontSize="large"
          style={{ color: colors['darkTeal.700'], fontSize: 28 }}
        />
      }
      endIcon={<ChevronRightIcon style={{ fontSize: 28 }} />}
      {...otherProps}
    >
      <Typography variant="body1" flex={1} sx={{ fontWeight: 600, color: 'inherit' }}>
        {buttonText}
      </Typography>
    </Button>
  );
};
