import React, { useEffect, useCallback } from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, useOktaAuth, LoginCallback } from '@okta/okta-react';

import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { Loading } from 'src/components/Pages/Loading';
import { CorsErrorModal } from 'src/components/Authentication/CorsErrorModal';
import { AuthRequiredModal } from 'src/components/Authentication/AuthRequiredModal';
import { ErrorBoundaryFallback } from 'src/components/ErrorBoundary/ErrorBoundaryFallback';

const oktaAuth = new OktaAuth({
  issuer: import.meta.env.VITE_OKTA_ISSUER_URI,
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + import.meta.env.VITE_OKTA_REDIRECT_URI,
  scopes: ['openid', 'mycpd', 'profile', 'email'],
  storageManager: {
    token: { storageTypes: ['cookie', 'localStorage', 'sessionStorage'] },
    cache: { storageTypes: ['cookie', 'localStorage', 'sessionStorage'] },
    transaction: { storageTypes: ['cookie', 'localStorage', 'sessionStorage'] }
  },
  tokenManager: {
    expireEarlySeconds: 30
  },
  services: {
    autoRenew: false,
    autoRemove: true,
    syncStorage: true
  },
  cookies: { secure: true },
  pkce: true,
  ignoreLifetime: true // added to avoid client invalid datetime support
});

// This component ensured that Okta has loaded correctly and the user has authenticated before the applicatiuon loads
// https://github.com/okta/okta-react/issues/178#issuecomment-1202678755
const ConfiguredOktaSecurity: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);

  const handleError = useErrorHandler();
  const history = useHistory();

  useEffect(() => {
    console.warn('STARTING oktaAuth');
    oktaAuth.start().catch(handleError);
  }, [handleError]);

  const triggerLogin = useCallback(async () => {
    const overrideParams = {
      authorizeUrl: import.meta.env.VITE_OKTA_AUTHORIZE_URL_OVERRIDE, // override the authorizeUrl because we want to see the Kentico login page
      sessionToken: '' // attempt to prevent iFrame by hiding the sessionToken
    };
    await oktaAuth.signInWithRedirect(overrideParams).catch((error) => {
      handleError(error);
    });
  }, [handleError]);

  const restoreOriginalUri = useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    },
    [history]
  );

  const customAuthHandler = useCallback(async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin().catch(handleError);
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  }, [handleError, triggerLogin]);

  oktaAuth.tokenManager.on('expired', function (key, expiredToken) {
    console.log('Token with key', key, ' has EXPIRED:', new Date());
    console.log(expiredToken);
    setAuthRequiredModalOpen(true);
  });
  // Triggered when a token has been renewed
  oktaAuth.tokenManager.on('renewed', function (key, newToken, oldToken) {
    console.log('Token with key', key, 'has been RENEWED', new Date());
    console.log('Old token:', oldToken);
    console.log('New token:', newToken);
    setAuthRequiredModalOpen(false);
  });
  // Triggered when a token has been renewed
  oktaAuth.tokenManager.on('added', function (key, newToken) {
    console.log('Token with key', key, 'has been ADDED', new Date());
    setAuthRequiredModalOpen(false);
  });
  // Triggered when an OAuthError is returned via the API (typically during token renew)
  oktaAuth.tokenManager.on('error', function (error) {
    console.log('Token error', new Date());
    handleError(error, { errorBoundary: false, sentry: true });
  });

  // make sure users don't get stuck on the '/implicit/callback' path after a refresh
  const hasCallbackWithMissingParams =
    window.location.pathname.startsWith(import.meta.env.VITE_OKTA_REDIRECT_URI ?? 'null') &&
    !oktaAuth.isLoginRedirect();
  if (hasCallbackWithMissingParams) {
    history.replace(toRelativeUrl('/', window.location.origin));
    return <></>;
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
      onError={handleError}
    >
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
      <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />

      <Switch>
        <Route
          path={import.meta.env.VITE_OKTA_REDIRECT_URI}
          component={() => (
            <LoginCallback
              errorComponent={({ error }) => <ErrorBoundaryFallback error={error} />}
            />
          )}
        />
        <Route path="/logout" component={() => <LogoutComponent />} />
        <Route path="/signout" component={() => <LogoutComponent />} />
        <SecureRoute
          path="*"
          component={() => <React.Fragment>{children}</React.Fragment>}
          errorComponent={({ error }) => <ErrorBoundaryFallback error={error} />}
        />
      </Switch>
    </Security>
  );
};

const postSignoutRedirectUri = import.meta.env.VITE_OKTA_POST_SIGNOUT_REDIRECT_URI;
const signoutOptions = {
  postLogoutRedirectUri: postSignoutRedirectUri || window.location.origin
};

const LogoutComponent: React.FC<React.PropsWithChildren> = (props) => {
  const { oktaAuth } = useOktaAuth();
  const logout = () =>
    oktaAuth.signOut(signoutOptions).catch((error) => console.error(error.message));

  React.useEffect(() => {
    logout();
  });

  return <Loading />;
};

export { ConfiguredOktaSecurity };
