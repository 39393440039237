import React from 'react';
import { keyframes } from '@emotion/react';
import { Typography, Box, Container, useTheme, useMediaQuery } from '@mui/material';

import BannerImage from 'src/assets/banner-img-graphic.png';
import { Impersonate } from 'src/components/Impersonate/Impersonate';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

export const ContentPageTitle: React.FC<{
  currentPageTitle: string;
  showImpersonate?: boolean;
}> = ({ currentPageTitle, showImpersonate = true }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: 'primary.main',
          display: 'flex',
          p: 2,
          px: { xs: 0, sm: 2 },
          minHeight: 150,
          [theme.breakpoints.up('md')]: {
            minHeight: 150,
            backgroundImage: `url(${BannerImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'right',
            backgroundPositionY: 'bottom'
          }
        })}
      >
        <Container maxWidth="lg">
          <Typography
            variant={isMobile ? 'h2' : 'h1'}
            component="h1"
            sx={(theme) => ({
              //border: '2px solid red',
              // ^ debug size using this
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              animation: `${fadeInAnimation} 0.75s`,
              [theme.breakpoints.only('md')]: {
                maxWidth: 'calc(100vw - 360px)'
              },
              // sorry tried not to use custom break points but asset not fun
              '@media (min-width: 1200px) and (max-width: 1835.95px)': {
                maxWidth: 'calc(100vw - 360px - (100vw - 100%) / 2);' //(page width) - (image width) - (margin width / 2)
              }
            })}
          >
            {currentPageTitle}
          </Typography>
        </Container>
      </Box>
      {showImpersonate && <Impersonate />}
    </>
  );
};
