import React, { useState } from 'react';
import { PopupAlert } from './PopupAlert';
import { PopupAlertProps } from './types';

export const usePopupAlert = () => {
  const [popupAlert, setPopupAlert] = useState<JSX.Element | undefined>(undefined);

  const showPopupAlert = (props: PopupAlertProps) => {
    setPopupAlert(<PopupAlert onClose={() => setPopupAlert(undefined)} isOpen={true} {...props} />);
  };

  return { showPopupAlert, popupAlert };
};
