import React from 'react';
import { IconButton, Menu } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { QuickLogActions } from './QuickLogActions';
import { HlrActions } from './HlrActions';
import { ProviderRACGPActions } from './ProviderRACGPActions';
import { LongFormActions } from './LongFormActions';
import { PDPActions } from './PDPActions';
import { ProviderNonRACGPActions } from './ProviderNonRACGPActions';
import { ActivityActionsProps } from '../types';

export const ActivityActions = ({ activity }: ActivityActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isProviderNonRACGP =
    activity?.history_record_type === 'PROV_ACT' && activity.is_racgp_content === false;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shouldAutoClose = !isProviderNonRACGP;

  const isBackdatedActivity = !((activity?.hours ?? 0) > 0);
  if (isBackdatedActivity) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={shouldAutoClose ? handleClose : undefined}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            minWidth: 274,
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {activity?.history_record_type === 'QUICK_LOG' && <QuickLogActions activity={activity} />}
        {activity?.history_record_type === 'HLR' && <HlrActions activity={activity} />}
        {activity?.history_record_type === 'PROV_ACT' && activity.is_racgp_content && (
          <ProviderRACGPActions activity={activity} />
        )}
        {activity?.history_record_type === 'PROV_ACT' && activity.is_racgp_content === false && (
          <ProviderNonRACGPActions onClose={handleClose} activity={activity} />
        )}
        {activity?.history_record_type === 'SELF_DIR_LOG' && (
          <LongFormActions activity={activity} />
        )}
        {activity?.history_record_type === 'PDP' && <PDPActions activity={activity} />}
      </Menu>
    </>
  );
};
