import React from 'react';
import { Box, Typography, Paper, useTheme } from '@mui/material';
import { Phone, PrintOutlined, EmailOutlined } from '@mui/icons-material';

interface Props {
  location: string;
  phone: string;
  phone2?: string;
  fax?: string;
  email: string;
  openingHours: string;
  openingDays: string;
}

const ContactCard: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        p: 3,
        mt: 3,
        borderTop: '8px solid ' + theme.palette.secondary.main
      }}
    >
      <Typography
        color="primary.main"
        variant="body1"
        component={'h3'}
        sx={{ mb: 2, fontWeight: 600 }}
      >
        {props.location}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Phone sx={{ mr: 1, fontSize: '1.4rem', color: 'primary.main' }} />
        <Typography sx={{ flexGrow: 1 }} variant="body2">
          <a href={'tel:' + props.phone} style={{ textDecoration: 'underline' }}>
            {' '}
            {props.phone}
          </a>
        </Typography>
      </Box>
      {props.phone2 && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Phone sx={{ mr: 1, fontSize: '1.4rem', color: 'primary.main' }} />
          <Typography sx={{ flexGrow: 1 }} variant="body2">
            <a href={'tel:' + props.phone2} style={{ textDecoration: 'underline' }}>
              {' '}
              {props.phone2}
            </a>
          </Typography>
        </Box>
      )}
      {props.fax && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <PrintOutlined sx={{ mr: 1, fontSize: '1.4rem', color: 'primary.main' }} />
          <Typography sx={{ flexGrow: 1 }} variant="body2">
            {props.fax}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <EmailOutlined sx={{ mr: 1, fontSize: '1.4rem', color: 'primary.main' }} />
        <Typography sx={{ flexGrow: 1 }} variant="body2">
          <a href={'mailto:' + props.email} style={{ textDecoration: 'underline' }}>
            {' '}
            {props.email}
          </a>
        </Typography>
      </Box>
      <Typography
        color="primary.main"
        variant="body1"
        component={'h4'}
        sx={{ mt: 1, mb: 1, fontWeight: 600 }}
      >
        Opening Hours
      </Typography>
      <Box>
        <Typography variant="body2">{props.openingHours}</Typography>
      </Box>
      <Box>
        <Typography variant="body2">{props.openingDays}</Typography>
      </Box>
    </Paper>
  );
};

export { ContactCard };
