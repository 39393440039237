import React from 'react';
import { Box, Skeleton, Paper } from '@mui/material';
import _ from 'lodash';

import { useGetSearchActivityByIdQuery, SearchActivityItem } from 'src/graphql/generated';
import { SearchResult } from 'src/components/Pages/Browse/SearchResult';
import { useErrorHandler } from 'src/hooks/useErrorHandler';

type Props = {
  activityId: string | undefined;
};
const SearchActivtyItem: React.FC<Props> = (props) => {
  const handleError = useErrorHandler();
  const { data, loading } = useGetSearchActivityByIdQuery({
    variables: { activityid: props.activityId ?? '' },
    onError: handleError
  });

  if (loading) {
    return (
      <>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Skeleton
            variant="rectangular"
            sx={{ mt: 2, width: '100px', borderRadius: '10px', bgcolor: 'grey.100' }}
          />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '30%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 1, width: '40%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '80%', bgcolor: 'grey.100' }} />
          <Skeleton
            sx={{
              width: '100px',
              borderRadius: '10px',
              bgcolor: 'grey.100',
              mt: 4
            }}
          />
        </Paper>
      </>
    );
  }

  const itemList = data?.searchActivity?.items ?? ([] as SearchActivityItem[]);
  const searchActivity = _.first(itemList) as SearchActivityItem;

  // TODO: deal with null or missing scenario. Consider validating an empty activityId string
  if (itemList.length === 0 || !searchActivity) {
    return <Box my={2}>Error finding activity</Box>;
  }

  return <SearchResult searchActivity={searchActivity} confirmDeleteBookmark={true} />;
};

export { SearchActivtyItem };
