import React, { useContext } from 'react';
import { Box, Button, Modal, Typography, Paper } from '@mui/material';

import { useDeleteNotificationsMutation } from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';

type Props = { totalResults: number | undefined };

const RemoveAllNotificationsButton: React.FC<Props> = (props) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const [deleteNotification] = useDeleteNotificationsMutation({
    variables: {
      memberid: activeMemberId,
      deleteAll: true,
      notifications: []
    },
    refetchQueries: ['getNotifications']
  });
  function deleteNotificationAndRefreshResults() {
    deleteNotification();
    handleConfirmationModalClose();
  }

  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const handleConfirmationModalOpen = () => setConfirmationModalOpen(true);
  const handleConfirmationModalClose = () => setConfirmationModalOpen(false);

  return (
    <Box>
      <Button variant="outlined" onClick={() => handleConfirmationModalOpen()}>
        Dismiss all
      </Button>

      <Modal
        open={confirmationModalOpen}
        onClose={handleConfirmationModalClose}
        aria-labelledby="delete notification"
        aria-describedby="delete notification"
      >
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            width: '90%',
            maxWidth: '600px'
          }}
        >
          <Typography variant="h3">Dismiss all notifications</Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            Are you sure you wish to{' '}
            <span style={{ fontWeight: 600 }}>
              dismiss all ({props.totalResults}) notifications?
            </span>
            <br />
            You cannot undo this operation.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => handleConfirmationModalClose()}
              variant="text"
              sx={{ color: '#6F6F6F' }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleteNotificationAndRefreshResults()}
              variant="text"
              sx={{ color: 'primary.main' }}
            >
              Dismiss all notifications
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export { RemoveAllNotificationsButton };
