import { Attachment, useSavePdpDetailsMutation, PdpType, AttType } from 'src/graphql/generated';
import { AttachmentsArray, PdpDetailData } from 'src/components/Pages/PDP/types';
import { useContext, useState } from 'react';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { prepareAttachments } from 'src/components/Attachments/utils';
import { useUploadAttachments } from 'src/hooks/useUploadAttachments';
import { useParams } from 'react-router-dom';

export const useSubmitPDPUpload = (options?: any) => {
  const { year } = useParams<{ year: string }>();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const [fileUploadError, setFileUploadError] = useState<string | undefined>();
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const uploadFiles = useUploadAttachments(AttType.PdpAttachment);

  const [
    savePdpDetailsMutation,
    { loading: saveQuickLoadLoading, error: saveQuickLogActivityError, ...rest }
  ] = useSavePdpDetailsMutation(options);

  return {
    loading: saveQuickLoadLoading || uploadingFiles,
    error: saveQuickLogActivityError || fileUploadError,
    ...rest,
    submit: async (data: PdpDetailData) => {
      setFileUploadError(undefined);
      const localFiles = data.attachments.filter((a): a is File => a instanceof File);
      const previousAttachments = data.attachments.filter(
        (a): a is Attachment => !(a instanceof File)
      );

      let newAttachments: Attachment[] = [];
      if (localFiles?.length > 0) {
        try {
          setUploadingFiles(true);
          newAttachments = (await uploadFiles(localFiles)) as AttachmentsArray;
        } catch (e) {
          setFileUploadError('Failed to upload files, please try again later.');
          return;
        } finally {
          setUploadingFiles(false);
        }
      }

      const aggregatedAttachments = [
        ...prepareAttachments(previousAttachments),
        ...prepareAttachments(newAttachments)
      ];

      const { hours_spent } = data;

      return savePdpDetailsMutation({
        variables: {
          pdp_year: year,
          memberid: activeMemberId,
          pdp_details: {
            scope_of_practice: '',
            goals: '',
            general_comments: '',
            quick_log_reflection_comments: '',
            annual_review_and_reflection: '',
            commented_activities: [],
            cpd_activity_key: '',
            cpdActivityPlans: [],
            pdp_type: PdpType.Upload,
            exempt: false,
            current_situation: '',
            reflection_culturally_save_practice: '',
            reflection_health_inequities: '',
            reflection_professionalism_ethical_practice: '',
            hours_spent: hours_spent,
            attachments: aggregatedAttachments
          }
        }
      });
    }
  };
};
