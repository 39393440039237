import React from 'react';
import { Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';
import { SearchActivityItem } from 'src/graphql/generated';

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
};

const ViewButton: React.FC<Props> = (props) => {
  const location = useLocation();
  return (
    <Button
      component={NavLink}
      to={{
        pathname: `/activity/${props?.searchActivity?.activity?.id}`,
        state: { backTo: location.pathname }
      }}
    >
      View Activity <ChevronRight />
    </Button>
  );
};

export { ViewButton };
