import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { HistoryContext, HistoryContextType } from 'src/contexts/HistoryContext';
import { ActionMenuItem } from './ActionMenuItem';
import { ActivityActionsProps } from '../types';

export const ProviderRACGPActions = ({ activity }: ActivityActionsProps) => {
  const activitykeyUrlEncoded = encodeURIComponent(activity?.cpd_id ?? 'null');
  const { downloadAttendanceCertificate } = useContext(HistoryContext) as HistoryContextType;

  return (
    <>
      <NavLink
        to={{
          pathname: '/activity/' + activitykeyUrlEncoded,
          state: { backTo: '/history' }
        }}
      >
        <ActionMenuItem title={'View'} icon={'view'} />
      </NavLink>
      <ActionMenuItem
        title={'Download Attendance Certificate'}
        icon={'download'}
        onClick={() =>
          activity?.activitykey ? downloadAttendanceCertificate(activity.activitykey) : {}
        }
      />
    </>
  );
};
