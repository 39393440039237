import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

type ConfirmDeleteProps = {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
};

export const ConfirmDelete = ({ onConfirm, isOpen, onClose }: ConfirmDeleteProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle id="draggable-dialog-title">Delete Quick Log</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this Quick Log?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};
