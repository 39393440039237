import { useContext, useState } from 'react';
import {
  Box,
  Container,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useGetMemberQuery, useGetRequirementsSummaryQuery } from '../../../graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { Dashboard } from './RequirementsSummaryDashboard/Dashboard';
import {
  CPDHourRequirementDetails,
  getTitleForCategoryCode
} from './DetailsDrawer/CPDHourRequirementDetails';
import { useCycles } from 'src/hooks/useCycles';
import { Impersonate } from 'src/components/Impersonate/Impersonate';
import { LightbulbOutlined, ListAlt, ChevronRight, SchoolOutlined } from '@mui/icons-material';
import { MultiColumnCTALinkCard } from 'src/components/ContentPageCards/MultiColumnCTALinkCard';
import { ProvidersOnDashboard } from 'src/components/Pages/MajorCPDProviders/ProvidersOnDashboard';
import { AdBanner } from './AdBanner';

export const MyCPD = () => {
  const handleError = useErrorHandler();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const { activeCycle, setActiveCycle } = useCycles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data: requirementsSummaryData } = useGetRequirementsSummaryQuery({
    variables: { memberid: activeMemberId, date: activeCycle + '-01-01' },
    fetchPolicy: 'cache-and-network',
    onError: handleError
  });

  const { data: memberInfo } = useGetMemberQuery({
    variables: { memberid: activeMemberId },
    onError: handleError
  });

  const [drawerContent, setDrawerContent] = useState<JSX.Element>();

  const closeDrawer = () => {
    setDrawerContent(undefined);
  };

  const onPressCategoryGroup = (categoryGroup: any) => {
    setDrawerContent(
      <Container sx={{ pb: 5, pt: 3 }} maxWidth={'sm'}>
        <Box display={'flex'} mb={2} sx={{ alignItems: 'baseline' }}>
          <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'primary.main', flex: 1 }}>
            {getTitleForCategoryCode(categoryGroup?.category_group_code)}
          </Typography>
          <CloseIcon cursor={'pointer'} onClick={closeDrawer} />
        </Box>
        <CPDHourRequirementDetails
          categoryGroupRequirement={categoryGroup}
          showCategoryRequirements={categoryGroup.category_group_code === 'RPMO'}
        ></CPDHourRequirementDetails>
      </Container>
    );
  };
  return (
    <>
      <Impersonate />

      <Container
        maxWidth={'lg'}
        sx={{
          textAlign: 'start',
          paddingTop: 3
        }}
      >
        <Dashboard
          onPressItem={onPressCategoryGroup}
          requirementsSummary={requirementsSummaryData}
          memberInfo={memberInfo}
          activeCycle={activeCycle}
          onChangeActiveCycle={setActiveCycle}
        />
        <AdBanner
          heading="Looking for CPD options?"
          description="Discover a library of self-paced courses that you can do online, included in full membership."
          buttons={[
            {
              cta: 'Explore now',
              link: {
                url: 'https://www.racgp.org.au/education/professional-development/online-learning/gplearning/whats-new-in-gplearning',
                target: '_blank'
              }
            }
          ]}
          image={{
            portrait: { position: 'center 100%', size: '100%' },
            square: { position: 'center 80%', size: '100%' },
            landscape: { position: 'right 85%', size: '54%' }
          }}
        />
        <Grid container spacing={2}>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              button={{
                cta: 'Explore Resources',
                link: {
                  url: '/resources',
                  target: ''
                }
              }}
              heading="Resources"
              description="CPD resources across all activity types including access to gplearning, RACGP CPD Solutions and RACGP events."
              CardIcon={SchoolOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              button={{
                cta: 'View',
                link: {
                  url: 'https://www.racgp.org.au/education/professional-development/cpd/faqs-for-gps',
                  target: '_blank'
                }
              }}
              heading="Frequently asked questions"
              description="Answers to your frequently asked questions on CPD."
              CardIcon={LightbulbOutlined}
              ButtonIcon={ChevronRight}
              IsNew={true}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              button={{
                cta: 'Learn more',
                link: {
                  url: 'https://www.racgp.org.au/education/professional-development/cpd/mycpd-home-member-resources/your-cpd-solutions',
                  target: '_blank'
                }
              }}
              heading="CPD solution by clinical topic"
              description="Access CPD options with all three CPD types on a range of clinical topics."
              CardIcon={ListAlt}
              ButtonIcon={ChevronRight}
              IsNew={true}
            />
          </Grid>
        </Grid>
        <Drawer open={!!drawerContent} anchor={'right'} onClose={closeDrawer}>
          {drawerContent}
        </Drawer>
        <Divider sx={{ mt: 4 }} />
        <ProvidersOnDashboard />
      </Container>
    </>
  );
};
