export const getCategoryCodeAccentColor = (categoryCode?: string) => {
  switch (categoryCode) {
    case 'EA-BLA':
      return '#11364D';
    case 'RPMO':
      return `#F06000`;
    case 'RPMOEU':
      return `#1D9F90`;

    default:
      return undefined;
  }
};
