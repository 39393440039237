import React, { useState } from 'react';
import { Button, Box, Alert } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {
  CpdActivitySession,
  useAddNotificationMutation,
  useDeleteNotificationsMutation,
  useGetNotificationSubscriptionsQuery,
  NotificationType,
  Notification,
  useGetNotificationPreferencesQuery,
  useSaveNotificationPreferencesMutation,
  Preference
} from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { useErrorHandler } from 'src/hooks/useErrorHandler';

type SessionNotificationButtonType = {
  activityId: string;
  session: CpdActivitySession;
};

const SessionNotificationButton = ({ activityId, session }: SessionNotificationButtonType) => {
  const handleError = useErrorHandler();
  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;
  const [sessionNotifications, setSessionNotifications] = useState<boolean>(false);
  const [sessionSubscription, setSessionSubscription] = React.useState<Notification | null>(null);
  const [remind_before_days, setRemind_before_days] = useState<number>(7);
  const [cpd_updates, setCpd_updates] = useState<boolean>(false);
  const history = useHistory();
  const { error: preferencesError } = useGetNotificationPreferencesQuery({
    variables: { memberid: activeMemberId },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const preferences = data?.getNotificationPreferences as Preference;
      setSessionNotifications(preferences?.session_reminders?.enabled ?? true);
      setRemind_before_days(preferences?.session_reminders?.remind_before_days ?? 7);
      setCpd_updates(preferences?.cpd_updates ?? true);
    }
  });

  const [savePreferences, { error: saveError }] = useSaveNotificationPreferencesMutation({
    variables: {
      memberid: activeMemberId,
      notification_preferences: {
        cpd_updates: cpd_updates,
        pdp_alerts: false,
        session_reminders: { enabled: true, remind_before_days: remind_before_days }
      }
    },
    onCompleted: () => {
      addNotificationMutation();
      history.push('/activity/' + activityId, { onSessionSettingsUpdated: true });
    }
  });
  const [addNotificationMutation] = useAddNotificationMutation({
    variables: {
      memberid: activeMemberId,
      notification: {
        type: NotificationType.SessionReminder,
        activity_id: activityId,
        event_id: `${session.eventId}`
      }
    },
    onError: handleError,
    refetchQueries: ['getNotificationSubscriptions', 'getNotifications']
  });

  const [deleteNotificationMutation] = useDeleteNotificationsMutation({
    refetchQueries: ['getNotificationSubscriptions', 'getNotifications']
  });

  const { error: subscriptionError } = useGetNotificationSubscriptionsQuery({
    variables: {
      memberid: activeMemberId
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (!data) return; // onCompleted still runs when query is skipped. Need extra exit condition...
      const notificationsSubscriptionItems = data?.getNotificationSubscriptions?.items || [];
      const matchingSessionSubscription = notificationsSubscriptionItems.find(
        (subscription) => subscription?.reference_id === `${activityId}_${session.eventId}`
      );
      setSessionSubscription(matchingSessionSubscription as Notification);
    }
  });

  const handleClick = () => {
    const isExistingSubscription = sessionSubscription != null;

    if (sessionNotifications) {
      if (isExistingSubscription) {
        deleteNotificationMutation({
          variables: {
            memberid: activeMemberId,
            notifications: [
              {
                type: NotificationType.SessionReminder,
                reference_id: sessionSubscription?.reference_id,
                notification_status: sessionSubscription?.notification_status
              }
            ]
          },
          onError: handleError
        });
      } else {
        addNotificationMutation();
      }
    } else {
      savePreferences();
    }
  };

  if (subscriptionError) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{subscriptionError.message}</Alert>
      </Box>
    );
  }
  if (preferencesError) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{preferencesError.message}</Alert>
      </Box>
    );
  }
  if (saveError) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{saveError.message}</Alert>
      </Box>
    );
  }
  return (
    <>
      <Button
        size="large"
        variant="bordered"
        color="primary"
        sx={{ ml: 2, color: 'primary.main' }}
        onClick={handleClick}
      >
        {sessionSubscription != null && (
          <NotificationsActiveIcon style={{ color: 'inherit', fontSize: '22px' }} />
        )}
        {sessionSubscription == null && (
          <NotificationsNoneOutlinedIcon style={{ color: 'inherit', fontSize: '22px' }} />
        )}
      </Button>
    </>
  );
};

export { SessionNotificationButton };
