import React from 'react';
import LogoImg from 'src/assets/racgp-cpd-logo-white.svg';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
      <img src={LogoImg} alt="logo" style={{ maxHeight: 34 }} />
    </Link>
  );
};
export { Logo };
