import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useOktaAuth } from '@okta/okta-react';

const inMemoryCache = new InMemoryCache();
const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URI
});

const ConfiguredApolloProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { authState } = useOktaAuth();

  const authLink = setContext((_, { headers }) => {
    const token = authState?.accessToken?.accessToken;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const authErrorLink = onError((error) => {
    console.error(error);
    const { networkError } = error;
    const statusCode = networkError && 'statusCode' in networkError && networkError.statusCode;
    if (statusCode === 401) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(authErrorLink).concat(httpLink),
    cache: inMemoryCache
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { ConfiguredApolloProvider };
