import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const Loading = () => (
  <Box
    sx={{
      height: 'max-content'
    }}
  >
    {[...Array(5)].map((_, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        sx={{ my: 4, mx: 2, minHeight: 30, borderRadius: 1 }}
      />
    ))}
  </Box>
);
