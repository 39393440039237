import React, { useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  TextField
} from '@mui/material';
import { startOfDay } from 'date-fns';
import { LocalizationProvider, DateRangePicker, DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { FACET_NAMES } from 'src/components/Pages/Browse/constants';

const FilterByDate = () => {
  const { dateDateArray, setDateDateArray, setLastChangeFacet } = useContext(
    BrowseContext
  ) as BrowseContextType;

  const handleChange = (dateDateArray: DateRange<Date>) => {
    setLastChangeFacet(FACET_NAMES.SessionsToDateTime);
    setDateDateArray(dateDateArray);
  };

  return (
    <Box>
      <Accordion elevation={0} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="provider-content"
          id="provider-header"
          sx={{ minHeight: 'initial' }}
        >
          <Typography variant="subtitle2" color="primary">
            Date Range
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <FormGroup>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={{ start: 'Start date', end: 'End date' }}
            >
              <DateRangePicker
                value={dateDateArray}
                onChange={handleChange}
                minDate={startOfDay(new Date())}
                inputFormat="dd/MM/yyyy"
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: { sm: 'wrap', lg: 'nowrap' }
                      }}
                    >
                      <TextField {...startProps} sx={{ maxWidth: 120, my: 1 }} />
                      <Box
                        sx={{
                          mx: 2,
                          '@media screen and (min-width: 900px) and (max-width: 1083px) ': {
                            opacity: 0
                          }
                        }}
                      >
                        <Typography variant="body1"> to </Typography>
                      </Box>
                      <TextField {...endProps} sx={{ maxWidth: 120, my: 1 }} />
                    </Box>
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { FilterByDate };
