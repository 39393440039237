export const colors = {
  'darkTeal.800': '#007064',
  'darkTeal.700': '#008074',
  'charcoal.900': '#2D2D2D',
  'charcoal.800': '#4F4F4F',
  'charcoal.700': '#6F6F6F',
  'charcoal.300': '#EDEDED',
  'stone.100': '#D2DCE1',
  'red.700': '#B00020',
  'green.50': '#E8F5E9',
  'white.300': '#F0F0F0'
};
