import React, { useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FilterByFormControlLabel } from '../../Layout/FilterByCheckBox';
import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { FacetCount } from './FacetCount';
import { FACET_NAMES } from './constants';

const FilterByActivityCriteria = () => {
  const { activityCriteriaFilterCount, setActivityCriteriaFilterCount, setLastChangeFacet } =
    useContext(BrowseContext) as BrowseContextType;
  const { ruralCpdYesNo, setRuralCpdYesNo } = useContext(BrowseContext) as BrowseContextType;
  const handleChangeRuralCpdYesNo = (event: any) => {
    const isChecked = event.target.checked;
    setLastChangeFacet(FACET_NAMES.RuralCpdYesNo);
    if (isChecked) {
      setRuralCpdYesNo(event.target.value);
      setActivityCriteriaFilterCount(activityCriteriaFilterCount + 1);
    } else {
      setRuralCpdYesNo('');
      setActivityCriteriaFilterCount(activityCriteriaFilterCount - 1);
    }
  };
  const isCheckedRuralCpdYesNo = (value: string) => {
    return ruralCpdYesNo.includes(value);
  };

  const { eligibleForGrantsYesNo, setEligibleForGrantsYesNo } = useContext(
    BrowseContext
  ) as BrowseContextType;
  const handleChangeEligibleForGrantsYesNo = (event: any) => {
    const isChecked = event.target.checked;
    setLastChangeFacet(FACET_NAMES.EligibleForGrantsYesNo);
    if (isChecked) {
      setEligibleForGrantsYesNo(event.target.value);
      setActivityCriteriaFilterCount(activityCriteriaFilterCount + 1);
    } else {
      setEligibleForGrantsYesNo('');
      setActivityCriteriaFilterCount(activityCriteriaFilterCount - 1);
    }
  };
  const isCheckedEligibleForGrantsYesNo = (value: string) => {
    return eligibleForGrantsYesNo.includes(value);
  };
  return (
    <Box>
      <Accordion elevation={0} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="activitycriteria-content"
          id="activitycriteria-header"
          sx={{ minHeight: 'initial' }}
        >
          <Typography variant="subtitle2" color="primary">
            Activity Criteria{' '}
            {activityCriteriaFilterCount > 0 && '(' + activityCriteriaFilterCount + ')'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <FormGroup>
            <FilterByFormControlLabel
              sx={{ width: '100%' }}
              control={
                <Checkbox
                  onChange={handleChangeRuralCpdYesNo}
                  value="Yes"
                  checked={isCheckedRuralCpdYesNo('Yes')}
                />
              }
              label={
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Box>Rural CPD activity</Box>
                  <FacetCount facetName={FACET_NAMES.RuralCpdYesNo} facetValueString="Yes" />
                </Box>
              }
            />

            <FilterByFormControlLabel
              sx={{ width: '100%' }}
              control={
                <Checkbox
                  onChange={handleChangeEligibleForGrantsYesNo}
                  value="Yes"
                  checked={isCheckedEligibleForGrantsYesNo('Yes')}
                />
              }
              label={
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Box>Rural Procedural Grants eligible</Box>{' '}
                  <FacetCount
                    facetName={FACET_NAMES.EligibleForGrantsYesNo}
                    facetValueString="Yes"
                  />
                </Box>
              }
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { FilterByActivityCriteria };
