import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { PermissionDeniedPage } from 'src/components/Error/PermissionDeniedPage';

export type Roles = 'member' | 'staff' | 'provider' | 'hideForProvider';
type RolesAuthRouteProps = React.PropsWithChildren & {
  roles: Roles[];
  fallbackRoute?: string;
  hideChildComponent?: boolean;
};

const RolesRestricted = ({
  roles,
  fallbackRoute,
  children,
  hideChildComponent
}: RolesAuthRouteProps) => {
  const { cpdRole } = useContext(AuthContext) as AuthContextType;

  const canAccess = roles.some((allowedRole) => cpdRole === allowedRole);

  if (!canAccess && !hideChildComponent) {
    if (fallbackRoute) {
      return <Redirect to={fallbackRoute} />;
    }
    return <PermissionDeniedPage />;
  }

  if (!canAccess && hideChildComponent) {
    return <></>;
  }

  if (canAccess) return <>{children}</>;

  return <Redirect to="/dashboard/login" />;
};

export { RolesRestricted };
