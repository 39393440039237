import { useContext, useState } from 'react';
import { format } from 'date-fns';
import _ from 'lodash';

import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';

const FALLBACK_CYCLE = '2024';
const VALID_CYCLES = ['2024', '2023'];
const VALID_CYCLES_PLIOT = ['2025', '2024', '2023'];

const getInitialActiveCycle = (cycles: string[]) => {
  const currentYear = format(new Date(), 'yyyy');
  const cycle = cycles.find((s) => s === currentYear);
  return cycle || FALLBACK_CYCLE;
};

const getInitialActiveCycle_Pilot = (cycles: string[]) => {
  const cycle = _.first(cycles);
  return cycle || FALLBACK_CYCLE;
};

export const useCycles = (): {
  cyclesList: string[];
  activeCycle: string;
  setActiveCycle: (newCycle: string) => void;
} => {
  const { hasPilotAccess } = useContext(AuthContext) as AuthContextType;
  const cyclesList = hasPilotAccess ? VALID_CYCLES_PLIOT : VALID_CYCLES;
  const [activeCycle, setActiveCycle] = useState(
    hasPilotAccess ? getInitialActiveCycle_Pilot(cyclesList) : getInitialActiveCycle(cyclesList)
  );

  return { cyclesList: cyclesList, activeCycle, setActiveCycle };
};
