import { Typography, Divider, Box } from '@mui/material';
import { FilterByProvider } from './FilterByProvider';
import { FilterByCPDRequirements } from './FilterByCPDRequirements';
import { FilterByDeliveryMode } from './FilterByDeliveryMode';
import { FilterBySpecificRequirements } from './FilterBySpecificRequirements';
import { FilterByState } from './FilterByState';
import { FilterByActivityCriteria } from './FilterByActivityCriteria';
import { FilterByDuration } from './FilterByDuration';
import { ClearFilters } from './ClearFilters';
import { FilterByDate } from './FilterByDate';
import { FilterByAreasOfInterests } from './FilterByAreasOfInterests';
import { FilterByProgramLevels } from './FilterByProgramLevels';

const FilterBy = () => {
  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h2" color="primary" sx={{ paddingLeft: 2 }}>
            Filter by
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 'auto' }}>
          <ClearFilters />
        </Box>
      </Box>
      <FilterByProvider />
      <Divider sx={{ mx: 2 }} />
      <FilterByCPDRequirements />
      <Divider sx={{ mx: 2 }} />
      <FilterByProgramLevels />
      <Divider sx={{ mx: 2 }} />
      <FilterByDeliveryMode />
      <Divider sx={{ mx: 2 }} />
      <FilterBySpecificRequirements />
      <Divider sx={{ mx: 2 }} />
      <FilterByActivityCriteria />
      <Divider sx={{ mx: 2 }} />
      <FilterByDuration />
      <Divider sx={{ mx: 2 }} />
      <FilterByState />
      <Divider sx={{ mx: 2 }} />
      <FilterByDate />
      <Divider sx={{ mx: 2 }} />
      <FilterByAreasOfInterests />
    </>
  );
};

export { FilterBy };
