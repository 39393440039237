import React, { useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BrowseContext, BrowseContextType } from 'src/contexts/BrowseContext';
import { FilterByFormControlLabel } from '../../Layout/FilterByCheckBox';
import { FacetCount } from './FacetCount';
import { FACET_NAMES, FACET_VALUES_DELIVERY_MODE_LIST } from './constants';

const FilterByDeliveryMode = () => {
  const { deliveryMode, setDeliveryMode, setLastChangeFacet } = useContext(
    BrowseContext
  ) as BrowseContextType;

  const handleChange = (event: any) => {
    setLastChangeFacet(FACET_NAMES.DeliveryMode);
    const isChecked = event.target.checked;
    if (isChecked) {
      setDeliveryMode([...deliveryMode, event.target.value]);
    } else {
      const newDeliveryMode = deliveryMode.filter((value) => value !== event.target.value);
      setDeliveryMode(newDeliveryMode);
    }
  };

  const isChecked = (value: string): boolean => {
    return deliveryMode.includes(value);
  };

  return (
    <Box>
      <Accordion defaultExpanded={true} elevation={0} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="delivery-mode-content"
          id="delivery-mode-header"
          sx={{ minHeight: 'initial' }}
        >
          <Typography variant="subtitle2" color="primary">
            Delivery Mode {deliveryMode.length > 0 && '(' + deliveryMode.length + ')'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <FormGroup>
            {FACET_VALUES_DELIVERY_MODE_LIST.map((deliveryMode) => (
              <FilterByFormControlLabel
                key={deliveryMode.value}
                sx={{ width: '100%' }}
                control={
                  <Checkbox
                    onChange={handleChange}
                    value={deliveryMode.value}
                    checked={isChecked(deliveryMode.value)}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box>{deliveryMode.label}</Box>
                    <FacetCount
                      facetName={FACET_NAMES.DeliveryMode}
                      facetValueString={deliveryMode.value}
                    />
                  </Box>
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { FilterByDeliveryMode };
