export const FACET_NAMES = {
  ProviderType: 'ProviderType',
  RequirementsName: 'Requirements/Name',
  DeliveryMode: 'DeliveryMode',
  SpecificRequirements: 'SpecificRequirements',
  CurriculumContextualUnitsName: 'CurriculumContextualUnits/Name',
  RuralCpdYesNo: 'RuralCpdYesNo',
  EligibleForGrantsYesNo: 'EligibleForGrantsYesNo',
  SessionsState: 'Sessions/State',
  SessionsFromDayOfWeek: 'Sessions/FromDayOfWeek',
  HoursGroup: 'HoursGroup',
  SessionsFromDateTime: 'Sessions/FromDateTime',
  SessionsToDateTime: 'Sessions/ToDateTime',
  ProgramLevels: 'ProgramLevels'
};

export const FACET_VALUES_CONTENT_TYPE_LIST = [
  { label: 'RACGP-Led Activities', value: 'Racgp' },
  { label: 'External Provider Activities', value: 'External' }
];

export const FACET_VALUES_CPD_REQUIREMENTS = [
  { label: 'Educational Activities', value: 'EA' },
  { label: 'Reviewing Performance', value: 'RP' },
  { label: 'Measuring Outcomes', value: 'MO' },
  { label: 'CPR', value: 'CPR' }
];

export const FACET_VALUES_PROGRAM_LEVELS = [
  { label: 'Culturally Safe Practice', value: 'Culturally Safe Practice' },
  { label: 'Health Inequities', value: 'Health Inequities' },
  { label: 'Professionalism and Ethical Practice', value: 'Professionalism and Ethical Practice' }
];

export const DELIVERY_MODE_BLENDED = 'Blended';
export const DELIVERY_MODE_ELEARNING = 'E-learning';
export const DELIVERY_MODE_FACE_TOFACE = 'Face to face';
export const DELIVERY_MODE_VIRTUAL = 'Virtual';

export const FACET_VALUES_DELIVERY_MODE_LIST = [
  { label: 'Blended', value: DELIVERY_MODE_BLENDED },
  { label: 'E-Learning', value: DELIVERY_MODE_ELEARNING },
  { label: 'Face-to-Face', value: DELIVERY_MODE_FACE_TOFACE },
  { label: 'Virtual', value: DELIVERY_MODE_VIRTUAL }
];

export const FACET_VALUES_SPECIFIC_REQUIREMENTS_LIST = [
  {
    label: 'Cultural awareness training',
    value: 'Cultural awareness training'
  },
  {
    label: 'Cultural safety training',
    value: 'Cultural safety training'
  },
  {
    label: 'Diagnostic radiology',
    value: 'Diagnostic radiology'
  },
  {
    label: 'Focussed psychological strategy CPD',
    value: 'Focussed psychological strategy CPD'
  },
  {
    label: 'Focussed psychological strategy skills training',
    value: 'Focussed psychological strategy skills training'
  },
  {
    label: 'General practitioner providing surgical skills',
    value: 'General practitioner providing surgical skills'
  },
  {
    label: 'Medical acupuncture',
    value: 'Medical acupuncture'
  },
  {
    label: 'Mental health clinical enhancement module',
    value: 'Mental health clinical enhancement module'
  },
  {
    label: 'Mental health core module',
    value: 'Mental health core module'
  },
  {
    label: 'Mental health CPD',
    value: 'Mental health CPD'
  },
  {
    label: 'Mental health skills training',
    value: 'Mental health skills training'
  },
  {
    label: "Women's reproductive health",
    value: "Women's reproductive health"
  }
];

export const FACET_VALUES_HOURS_GROUP = [
  { label: '0 - 2 hours', value: '0-2' },
  { label: '2 - 6 hours', value: '2-6' },
  { label: '6 - 20 hours', value: '6-20' },
  { label: '20+ hours', value: '20+' }
];
