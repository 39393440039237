import React, { useEffect, useState } from 'react';
import { Box, Typography, Skeleton, Paper, Pagination } from '@mui/material';
import { Bookmark, SearchBookmarksQuery } from 'src/graphql/generated';
import { SearchResultsEmpty } from './SearchResultsEmpty';
import { SearchActivtyItem } from '../Browse/SearchResultWithActivityId';
import { RemoveAllBookmarksButton } from './RemoveAllBookmarksButton';

type BookmarkSearchResultsProps = {
  keyword: string;
  getBookmarksLoading: boolean;
  getBookmarksData: SearchBookmarksQuery | undefined;
  pageNumber: number;
  setPageNumber: any;
  rowsPerPage: number;
};

const BookmarkSearchResults: React.FC<BookmarkSearchResultsProps> = ({
  keyword,
  getBookmarksLoading,
  getBookmarksData,
  pageNumber,
  setPageNumber,
  rowsPerPage
}) => {
  const [bookmarksToDisplay, setBookmarksToDisplay] = useState<Bookmark[]>([]);
  const bookmarkItems = getBookmarksData?.searchBookmarks?.items ?? ([] as Bookmark[]);
  const totalPages = Math.ceil(bookmarkItems.length / rowsPerPage);
  const firstItemDisplayed = (pageNumber - 1) * rowsPerPage;
  const lastItemDisplayed = Math.min(firstItemDisplayed + rowsPerPage, bookmarkItems.length);

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  useEffect(() => {
    const bookmarkItems = getBookmarksData?.searchBookmarks?.items ?? ([] as Bookmark[]);
    const firstItemDisplayed = (pageNumber - 1) * rowsPerPage;
    const lastItemDisplayed = Math.min(firstItemDisplayed + rowsPerPage, bookmarkItems.length);
    const tempBookmarksToDisplay = bookmarkItems.slice(firstItemDisplayed, lastItemDisplayed);
    if (tempBookmarksToDisplay.length > 0) {
      setBookmarksToDisplay(tempBookmarksToDisplay as Bookmark[]);
    } else {
      setPageNumber(totalPages); // set to last page
    }
  }, [getBookmarksData, pageNumber, totalPages, setPageNumber, rowsPerPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber, rowsPerPage]);

  if (getBookmarksLoading) {
    return (
      <>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Skeleton
            variant="rectangular"
            sx={{ mt: 2, width: '100px', borderRadius: '10px', bgcolor: 'grey.100' }}
          />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '30%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 1, width: '40%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '80%', bgcolor: 'grey.100' }} />
          <Skeleton
            sx={{
              width: '100px',
              borderRadius: '10px',
              bgcolor: 'grey.100',
              mt: 4
            }}
          />
        </Paper>
      </>
    );
  }

  if (bookmarkItems.length === 0) {
    return <SearchResultsEmpty></SearchResultsEmpty>;
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="body2">{`Showing ${firstItemDisplayed + 1}-${lastItemDisplayed} of ${
            bookmarkItems.length
          } Bookmarks`}</Typography>
        </Box>
        <RemoveAllBookmarksButton></RemoveAllBookmarksButton>
      </Box>
      <Box>
        {bookmarksToDisplay.map((item: Bookmark | null | undefined, index) => {
          return <SearchActivtyItem key={index} activityId={item?.internal_id ?? ''} />;
        })}
      </Box>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Pagination count={totalPages} page={pageNumber} onChange={onPageChange} />
      </Box>
    </Box>
  );
};

export { BookmarkSearchResults };
