import React from 'react';
import { Box, Container, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { SearchCard } from './SearchCard';
import { FilterBy } from './FilterBy';
import { SearchResults } from './SearchResults';
import { ResultsSummary } from './ResultsSummary';
import { OrderBy } from './OrderBy';

const BrowsePage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <ContentPageTitle currentPageTitle="Browse" />
      <Container maxWidth="lg">
        <SearchCard />
        <Grid container sx={{ mt: 2 }} spacing={2}>
          {matches && (
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ width: '100%', px: 1, py: 3 }}>
                <FilterBy />
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} md={8}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <ResultsSummary />
              {matches && <OrderBy />}
            </Box>
            <SearchResults />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export { BrowsePage };
