import { useContext, useState, useEffect } from 'react';
import { Container, Paper } from '@mui/material';

import { useSearchBookmarksLazyQuery } from 'src/graphql/generated';
import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { SearchBar } from 'src/components/SearchBar/SearchBar';
import { BookmarkSearchResults } from './BookmarkSearchResults';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';

const BookmarksPage = () => {
  const [keyword, setKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage] = useState(5);
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const [searchBookmarksLazy, { loading: getBookmarksLoading, data: getBookmarksData }] =
    useSearchBookmarksLazyQuery({
      fetchPolicy: 'cache-and-network'
    });

  const executeSearch = () => {
    setPageNumber(1);

    searchBookmarksLazy({
      variables: {
        memberid: activeMemberId,
        keyword: keyword,
        triennium: '23-50',
        limit: 100
      }
    });
  };

  // Effect to do initial load
  useEffect(() => {
    executeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentPageTitle currentPageTitle="Bookmarks" />
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ width: '100%', p: { xs: 2, md: 2 }, my: 2 }}>
          <SearchBar
            keyword={keyword}
            onSelectKeyword={(selectedKeyword) => setKeyword(selectedKeyword)}
            onExecuteSearch={() => executeSearch()}
            suggestedKeywordsList={[]}
          />
        </Paper>

        <BookmarkSearchResults
          getBookmarksLoading={getBookmarksLoading}
          getBookmarksData={getBookmarksData}
          keyword={keyword}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
        />
      </Container>
    </>
  );
};

export { BookmarksPage };
