import { useContext, useState } from 'react';
import { Container, Box, Alert } from '@mui/material';

import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { useGetNotificationsQuery } from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { NotificationsSearchResults } from './NotificationsSearchResults';
import { useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const NotificationsPage = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage] = useState(5);
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const updateSettings = useLocation<{ onUpdateSettings: Boolean }>();
  const { onUpdateSettings = false } = updateSettings?.state || {};
  const {
    loading: getNotificationsLoading,
    data: getNotificationsData,
    error
  } = useGetNotificationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      memberid: activeMemberId,
      from: pageNumber,
      limit: rowsPerPage
    }
  });
  if (error) {
    return (
      <>
        <ContentPageTitle currentPageTitle="Notifications" />
        <Container maxWidth="lg">
          <Box sx={{ p: 2 }}>
            <Alert severity="error">
              Something went wrong, please refresh the page and try again
            </Alert>
          </Box>
        </Container>
      </>
    );
  }
  return (
    <>
      <ContentPageTitle currentPageTitle="Notifications" />
      <Container maxWidth="lg">
        {onUpdateSettings && (
          <Alert
            sx={{ my: 2 }}
            icon={<CheckCircleOutlineIcon fontSize="inherit" />}
            severity="success"
          >
            Your notification settings have successfully been updated.
          </Alert>
        )}
        <NotificationsSearchResults
          getNotificationsLoading={getNotificationsLoading}
          getNotificationsData={getNotificationsData}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
        />
      </Container>
    </>
  );
};

export { NotificationsPage };
