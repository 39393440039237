import React from 'react';
import { Typography } from '@mui/material';
import { SearchActivityItem } from 'src/graphql/generated';

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
};

const Title: React.FC<Props> = (props) => {
  return (
    <Typography variant="body1" sx={{ mt: 1 }}>
      {props?.searchActivity?.activity?.title}
    </Typography>
  );
};

export { Title };
