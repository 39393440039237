import React from 'react';
import { Paper, Box } from '@mui/material';
import { SearchActivityItem } from 'src/graphql/generated';
import { BookmarkButton } from '../Bookmarks/BookmarkButton';
import { keyframes } from '@emotion/react';
import { ActivityStartDate } from './Atoms/ActivityStartDate';
import { Requirements } from './Atoms/Requirements';
import { CPDHours } from './Atoms/CPDHours';
import { States } from './Atoms/States';
import { Title } from './Atoms/Title';
import { Provider } from './Atoms/Provider';
import { DeliveryMode } from './Atoms/DeliveryMode';
import { ViewButton } from './Atoms/ViewButton';

const fadeInAnimation = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

type Props = {
  searchActivity: SearchActivityItem | null | undefined;
  confirmDeleteBookmark: Boolean;
  refreshResults?: () => void;
};

const SearchResult: React.FC<Props> = (props) => {
  const { searchActivity } = props;

  return (
    <Paper sx={{ p: { xs: 2, md: 3 }, mt: 2, animation: `${fadeInAnimation} 0.5s` }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Requirements searchActivity={props.searchActivity} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 1
          }}
        >
          <CPDHours searchActivity={props.searchActivity} />

          <Box sx={{ transform: 'translateY(-8px)' }}>
            <BookmarkButton
              hasDeleteConfirmation={props.confirmDeleteBookmark}
              refreshResults={props.refreshResults}
              bookmarkButtonType="listing"
              activity={searchActivity?.activity}
            />
          </Box>
        </Box>
      </Box>
      <ActivityStartDate searchActivity={props.searchActivity} />
      <Title searchActivity={props.searchActivity} />
      <Provider searchActivity={props.searchActivity} />
      <States searchActivity={searchActivity} />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3
        }}
      >
        <DeliveryMode searchActivity={props.searchActivity} />
        <ViewButton searchActivity={props.searchActivity} />
      </Box>
    </Paper>
  );
};

export { SearchResult };
