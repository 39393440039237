import { Box, Typography, Paper, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { ProvidersData } from './ProvidersData';

const ProvidersOnDashboard = () => {
  const tier3ProviderLogos = ProvidersData.filter((provider) => provider.tier === 'Tier3').map(
    (providerData) => providerData.providerLogoUrlList.map((providerInfo) => providerInfo)
  );
  const providerLogos = tier3ProviderLogos.flat(1).sort(function (a, b) {
    return a.logoTitle.localeCompare(b.logoTitle);
  });

  return (
    <>
      <Paper elevation={0} sx={{ px: { xs: 0, sm: 3 }, backgroundColor: 'initial' }}>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={6} sx={{ pl: 2 }}>
            <Typography sx={{ color: 'primary.main', fontWeight: '600' }}>
              Major CPD Providers
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ a: { display: 'flex', fontWeight: '500', alignItems: 'center' } }}>
              <NavLink to="/major-cpd-providers">
                Learn more
                <ChevronRight />
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        spacing={2}
        rowSpacing={0}
        sx={{
          mt: 2,
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          img: { maxHeight: '40px' }
        }}
      >
        {providerLogos.map((provider) => (
          <Grid item xs={6} sm={12 / 5} key={provider.logoTitle}>
            <a href={provider.link} target="_blank" rel="noreferrer">
              <img src={provider.logo} loading="lazy" alt={provider.logoTitle} />
            </a>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export { ProvidersOnDashboard };
