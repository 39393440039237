import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  SelectChangeEvent,
  Divider,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  ButtonProps as MUIButtonProps,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useHistory } from 'react-router-dom';

import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import {
  useGetNotificationPreferencesQuery,
  useSaveNotificationPreferencesMutation,
  Preference
} from 'src/graphql/generated/index';

type NotificationSettingsButtonProps = {
  buttonText?: string | null;
  buttonProps?: MUIButtonProps | null;
};

type NotificationRouterState = {
  openSettings: boolean;
};

const defaultOptions = [1, 2, 3, 4, 5, 6, 7];

const NotificationSettingsButton = ({
  buttonText = 'Settings',
  buttonProps
}: NotificationSettingsButtonProps) => {
  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;
  const [sessionNotifications, setSessionNotifications] = useState<boolean>(false);
  const [remind_before_days, setRemind_before_days] = useState<number>(7);
  const [cpd_updates, setCpd_updates] = useState<boolean>(false);
  const [options, setOptions] = useState<number[]>(defaultOptions);
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event: SelectChangeEvent) => {
    const stringValue = event.target.value as string;
    let numberValue = parseInt(stringValue);
    setRemind_before_days(numberValue);
  };

  useEffect(() => {
    const { openSettings } = (location.state || {}) as NotificationRouterState;
    if ((openSettings ?? false) as boolean) {
      setOpen(true);
    }
  }, [location]);

  const { error } = useGetNotificationPreferencesQuery({
    variables: { memberid: activeMemberId },
    skip: !open,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const preferences = data?.getNotificationPreferences as Preference;
      setSessionNotifications(preferences?.session_reminders?.enabled ?? true);
      const tempRemind_before_days = preferences?.session_reminders?.remind_before_days ?? 7;
      setRemind_before_days(tempRemind_before_days);
      const hasOption = options.find((option) => option === tempRemind_before_days);
      if (!hasOption) {
        setOptions([...options, tempRemind_before_days]);
      }
      setCpd_updates(preferences?.cpd_updates ?? true);
    }
  });

  const [savePreferences, { error: saveError }] = useSaveNotificationPreferencesMutation({
    variables: {
      memberid: activeMemberId,
      notification_preferences: {
        cpd_updates: cpd_updates,
        pdp_alerts: false,
        session_reminders: { enabled: sessionNotifications, remind_before_days: remind_before_days }
      }
    },
    onCompleted: () => {
      setOpen(false);
      history.push('/notifications/', { onUpdateSettings: true });
    },
    refetchQueries: ['getNotificationPreferencesQuery', 'getNotifications']
  });

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{ marginLeft: 'auto', minWidth: 'initial', p: 0 }}
        {...buttonProps}
      >
        {buttonText}
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          Notification settings{' '}
          <IconButton onClick={handleClose} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>{' '}
        <DialogContent>
          {error && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="error">{error?.message}</Alert>
            </Box>
          )}
          {saveError && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="error">{saveError?.message}</Alert>
            </Box>
          )}
          <DialogContentText sx={{ fontSize: '14px', fontWeight: '700' }}>
            Notify me for
          </DialogContentText>
          <Box>
            <Box>
              <FormControlLabel
                sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: 0 }}
                control={
                  <Switch
                    color="secondary"
                    checked={sessionNotifications}
                    onChange={(_, checked) => setSessionNotifications(checked)}
                  />
                }
                labelPlacement="start"
                label={<Typography variant="body2">Session Event Reminders</Typography>}
              />
            </Box>
            <Box mt={3}>
              <FormControl fullWidth>
                <InputLabel id="session-days-before-label">Remind me before</InputLabel>
                <Select
                  labelId="session-days-before-label"
                  label="Remind me before Type"
                  id="session-days-before-select"
                  value={`${remind_before_days}`}
                  onChange={handleChange}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option} day{option > 1 ? 's' : ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Divider sx={{ mt: 4, mb: 2 }} />
            <FormControlLabel
              sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: 0 }}
              control={
                <Switch
                  color="secondary"
                  checked={cpd_updates}
                  onChange={(_, checked) => setCpd_updates(checked)}
                />
              }
              labelPlacement="start"
              label={
                <Typography variant="body2">Continuing Professional Development Updates</Typography>
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => savePreferences()}>Update settings</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { NotificationSettingsButton };
