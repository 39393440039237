import { Box, CardActionArea } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { LoadableText } from 'src/components/Loadable/LoadableText';
import { getCardTitleForCategoryCode } from '../functions/card-titles';
import { CategoryGroupRequirement } from '../types';
import { colors } from 'src/components/App/colors';
import { CompletedCheckmark } from '../components/CompletedCheckmark';
import { format, parseISO } from 'date-fns';

type CPRRequirementProps = {
  categoryGroupRequirement?: CategoryGroupRequirement;
  color?: string;
  onSelect?: () => void;
  cprToDate: string;
};
export const CPRRequirement = ({
  cprToDate,
  categoryGroupRequirement,
  color = 'primary',
  onSelect
}: CPRRequirementProps) => {
  const CompletedByText = () => {
    var dateWithOutTime = cprToDate.slice(0, 10);
    var localDateWithOutTime = parseISO(dateWithOutTime);
    return <span>Complete by {format(localDateWithOutTime, 'dd MMM yyyy')}</span>;
  };

  return (
    <CardActionArea onClick={onSelect} sx={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          width: '100%',
          height: '100%',
          border: `1px solid ${colors['stone.100']}`,
          backgroundColor: 'white',
          px: 3,
          py: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LoadableText loading={!categoryGroupRequirement} variant={'caption'}>
            {categoryGroupRequirement?.completed ? (
              <Box display={'flex'} fontWeight={600} alignItems={'center'} gap={1}>
                <CompletedCheckmark
                  sx={{ width: 13, height: 13 }}
                  size={'small'}
                  completed={true}
                />
                Completed
              </Box>
            ) : (
              <Box color={colors['charcoal.700']}>Incomplete</Box>
            )}
          </LoadableText>
          <ChevronRightOutlinedIcon sx={{ color: 'primary.main', ml: 'auto', mr: -1 }} />
        </Box>
        <LoadableText
          loading={!categoryGroupRequirement}
          variant={'h4'}
          sx={{ fontWeight: '600', width: '100%', pt: 0.5 }}
        >
          {getCardTitleForCategoryCode(categoryGroupRequirement?.category_group_code)}
        </LoadableText>
        <LoadableText loading={!categoryGroupRequirement} mb={3} variant={'caption'}>
          {categoryGroupRequirement?.category_group_code === 'BLS' && (
            <Box>
              <CompletedByText />
            </Box>
          )}
        </LoadableText>
      </Box>
    </CardActionArea>
  );
};
