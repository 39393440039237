import React, { useContext } from 'react';
import { Button, Box, Modal, Typography, Paper } from '@mui/material';
import { useDeleteAllBookmarksMutation } from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { useErrorHandler } from 'src/hooks/useErrorHandler';

type Props = {
  refreshResults?: () => void;
};

const RemoveAllBookmarksButton: React.FC<Props> = (props) => {
  const handleError = useErrorHandler();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const [deleteAllBookmarks] = useDeleteAllBookmarksMutation({
    variables: {
      memberid: activeMemberId,
      triennium: '23-50'
    },
    onError: handleError
  });

  function deleteAllBookmarksAndRefreshResults() {
    deleteAllBookmarks({ refetchQueries: ['getBookmarks', 'searchBookmarks'] });
    handleConfirmationModalClose();
  }

  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const handleConfirmationModalOpen = () => setConfirmationModalOpen(true);
  const handleConfirmationModalClose = () => setConfirmationModalOpen(false);

  return (
    <Box>
      <Button onClick={() => handleConfirmationModalOpen()} variant="outlined" sx={{ px: 6 }}>
        Remove all
      </Button>
      <Modal
        open={confirmationModalOpen}
        onClose={handleConfirmationModalClose}
        aria-labelledby="delete all bookmarks"
        aria-describedby="delete all bookmarks"
      >
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            width: '90%',
            maxWidth: '600px'
          }}
        >
          <Typography variant="h3">Remove all bookmarks</Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            Are you sure you wish to
            <span style={{ fontWeight: 600 }}> remove all bookmarks?</span> <br />
            You cannot undo this operation.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => handleConfirmationModalClose()}
              variant="text"
              sx={{ color: '#6F6F6F' }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleteAllBookmarksAndRefreshResults()}
              variant="text"
              sx={{ color: 'primary.main' }}
            >
              Remove all bookmarks
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export { RemoveAllBookmarksButton };
