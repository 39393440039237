import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { ErrorCode, FileRejection } from 'react-dropzone';
import { MAX_UPLOAD_FILE_SIZE } from './constants';
import { formatBytes } from './utils';

const getErrorMessage = (fileRejection: FileRejection) => {
  const error = fileRejection.errors[0];
  if (error.code === ErrorCode.FileTooLarge) {
    return `File must be less than ${formatBytes(MAX_UPLOAD_FILE_SIZE)}: ${
      fileRejection.file.name
    } is ${formatBytes(fileRejection.file.size)}`;
  } else {
    return error.message;
  }
};

type FileErrorsProps = {
  fileRejections?: FileRejection[];
  generalError?: string;
};

export const FileErrors = ({ generalError, fileRejections }: FileErrorsProps) => {
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  useEffect(() => {
    if (generalError || fileRejections) {
      setShowErrorMessage(true);
    }
    const errorMessageTimer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 5000);
    return () => {
      clearTimeout(errorMessageTimer);
    };
  }, [generalError, fileRejections]);

  return (
    <Alert severity="error" sx={{ display: showErrorMessage ? 'flex' : 'none' }}>
      <AlertTitle>Error attaching file(s): </AlertTitle>
      {generalError && <Typography variant={'body2'}>{generalError}</Typography>}
      {fileRejections &&
        fileRejections.length > 0 &&
        fileRejections.map((fileRejection) => {
          return <Typography variant={'body2'}>{getErrorMessage(fileRejection)}</Typography>;
        })}
    </Alert>
  );
};
