import { MenuItem, MenuItemProps, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { colors } from 'src/components/App/colors';

type ActionIcon = 'edit' | 'delete' | 'view' | 'upload' | 'download';
type ActionMenuItemProps = MenuItemProps & {
  title: string;
  icon?: ActionIcon;
};

const getIcon = (icon?: ActionIcon) => {
  switch (icon) {
    case 'edit':
      return EditIcon;
    case 'delete':
      return DeleteIcon;
    case 'view':
      return VisibilityIcon;
    case 'upload':
      return UploadIcon;
    case 'download':
      return DownloadIcon;
    default:
      return null;
  }
};

export const ActionMenuItem = ({ icon, title, ...rest }: ActionMenuItemProps) => {
  const Icon = getIcon(icon);
  return (
    <MenuItem sx={{ py: 1, px: 2, mt: 1 }} {...rest}>
      {Icon && <Icon fontSize={'small'} sx={{ mr: 1.5, color: colors['charcoal.700'] }} />}
      <Typography variant={'body2'}>{title}</Typography>
    </MenuItem>
  );
};
