import {
  Box,
  Button,
  Typography,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type AccordionProps = {
  id: string;
  title: string;
  content: string;
  buttonLink?: string;
};

export const PlrAccordion = ({ id, title, content, buttonLink }: AccordionProps) => {
  return (
    <Accordion disableGutters sx={{ mb: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={id + '-content'}
        id={id + 'header'}
      >
        <Typography sx={{ fontWeight: '600' }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          dangerouslySetInnerHTML={{
            __html: content
          }}
        ></Box>
        {buttonLink && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              component={Link}
              endIcon={<ChevronRightIcon style={{ fontSize: 24 }} />}
              href={buttonLink}
              target="_blank"
              sx={{
                display: 'flex',
                marginLeft: 'auto',
                borderRadius: '8px',
                mt: 1
              }}
            >
              View Resources
            </Button>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
