import React, { useCallback } from 'react';
import {
  Box,
  Container,
  Divider,
  Paper,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert
} from '@mui/material';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { AttachmentsArray } from './types';
import {
  PdpDetail,
  useSubmitPdpMutation,
  SavePdpDetailsMutation,
  useGetPdpDetailsQuery,
  PdpStatus
} from 'src/graphql/generated';
import { Attachments } from 'src/components/Attachments/Attachments';
import { PdpDetailData } from './types';
import { useSubmitPDPUpload } from './Upload/useSubmitPDPUpload';
import { clamp } from 'lodash';
import { Button } from 'src/components/Button/Button';
import { nanToZero, roundHalf } from 'src/components/Attachments/utils';
import { useMemberAttachments } from './Upload/useMemberAttachments';
import { PdpLoading } from './PdpLoading';

const PDPUpload = () => {
  const { year } = useParams<{ year: string }>();
  const [pdpSubmitStatus, setPdpSubmitStatus] = React.useState<PdpStatus>();
  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState<String>('');
  const [dialogDescription, setDialogDescription] = React.useState<String>('');

  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;
  const history = useHistory();

  const { register, handleSubmit, control, watch, formState, setValue, reset } =
    useForm<PdpDetailData>({
      defaultValues: {
        current_situation: '',
        goals: '',
        annual_review_and_reflection: '',
        reflection_culturally_save_practice: '',
        reflection_health_inequities: '',
        reflection_professionalism_ethical_practice: '',
        hours_spent: 0,
        attachments: [],
        eaHours: 0,
        eaTags: [],
        rpHours: 0,
        rpTags: [],
        moHours: 0,
        moTags: []
      },
      mode: 'onChange'
    });
  const { loading: pdpGetQuery, error: errorGetQuery } = useGetPdpDetailsQuery({
    variables: { memberid: activeMemberId, pdp_year: year },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const pdpDetailsData = data?.getPdpDetails as PdpDetail;
      setPdpSubmitStatus(pdpDetailsData?.pdp_status as PdpStatus);
      reset({
        attachments: memberAttachmentsData?.attachments as AttachmentsArray,
        hours_spent: pdpDetailsData?.hours_spent ?? 0
      });
    }
  });

  const { memberAttachmentsData } = useMemberAttachments({
    memberId: activeMemberId,
    pdp_year: year
  });
  const [submitPdpMutation, { error: errorSubmitQuery }] = useSubmitPdpMutation({
    variables: {
      memberid: activeMemberId,
      pdp_year: year
    },
    onCompleted: () => {
      setOpen(true);
      setDialogTitle(`Congratulations! You have successfully submitted your PDP for ${year}.`);
      setDialogDescription('Your hours have been added and you can view now in History.');
    }
  });
  const { submit, error: errorSaveMutation } = useSubmitPDPUpload({
    onCompleted: (data: SavePdpDetailsMutation) => {
      if (pdpSubmitStatus === PdpStatus.PdpDraft) {
        submitPdpMutation();
      }
      if (pdpSubmitStatus === PdpStatus.PdpSubmitted) {
        setOpen(true);
        setDialogTitle('Your PDP has been updated');
        setDialogDescription('Your hours have been added and you can view now in History.');
      }
    },
    onError: (e: any) => {
      console.error('submit failed');
      console.error(e);
    }
  });

  const submitForm = handleSubmit((data) => {
    submit(data);
  });

  const roundField = useCallback(
    (fieldName: 'hours_spent') => {
      setValue(fieldName, clamp(roundHalf(watch(fieldName)), 0, 500), {
        shouldDirty: true,
        shouldValidate: true
      });
    },
    [setValue, watch]
  );

  // this needs to be destructured like this, writing formState.isValid will cause the value to be ignored on first render for some reason
  const { errors, isDirty, isValid, isSubmitSuccessful } = formState;

  React.useEffect(() => {
    //reset form to member activity data when it's available, so isDirty will be false after reset
    if (memberAttachmentsData) {
      const { attachments } = memberAttachmentsData;
      reset({
        attachments: (attachments as AttachmentsArray) || []
      });
    }
  }, [memberAttachmentsData, reset]);

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);
  React.useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [isDirty]);

  const handleClose = () => {
    setOpen(false);
    history.push('/pdp/' + year, { selectedTemplate: 'home' });
  };

  if (pdpGetQuery) {
    return <PdpLoading />;
  }

  if (errorGetQuery) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{errorGetQuery.message}</Alert>
      </Box>
    );
  }
  if (errorSubmitQuery) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{errorSubmitQuery.message}</Alert>
      </Box>
    );
  }
  if (errorSaveMutation) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">PDP details are being updated. Please try again later.</Alert>
      </Box>
    );
  }

  return (
    <>
      <form>
        <Prompt
          when={isDirty}
          message={(location) => `You have unsaved changes, are you sure you want to leave?`}
        />
        <Container
          maxWidth={'lg'}
          sx={{
            textAlign: 'start',
            mt: 3
          }}
        >
          <Paper elevation={3} sx={{ p: 3, pb: 4 }}>
            <Typography variant="h2" color="primary">
              Upload your own PDP
            </Typography>
            <Typography variant="body1" sx={{ my: 1 }}>
              Please note, after submitting our PDP, you are able to edit content but you will{' '}
              <Typography variant="caption" sx={{ fontWeight: 600, fontSize: '1rem' }}>
                not
              </Typography>{' '}
              be able to change your selected option.
            </Typography>
            <Typography variant="body1">
              In order to submit your PDP you need a{' '}
              <Typography variant="caption" sx={{ fontWeight: 600, fontSize: '1rem' }}>
                minimum of 1 file
              </Typography>{' '}
              with a maximum of 5.
            </Typography>
            <Divider sx={{ my: 4 }} />
            <Grid container>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h6"
                  color={errors.attachments ? 'red' : 'primary'}
                  sx={{ mb: 2 }}
                >
                  Upload{' '}
                  <Typography
                    variant={'caption'}
                    color={errors.attachments ? 'red' : 'primary'}
                    fontWeight={400}
                  >
                    (Required)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name={'attachments'}
                  rules={{ max: 5, required: true }}
                  render={({ formState, fieldState, field: { value, name, onChange } }) => {
                    return (
                      <Attachments
                        onChangeAttachments={onChange}
                        attachments={value}
                        pdpSubmissionStatus={pdpSubmitStatus}
                        uploadText="FILE"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />
            <Grid container>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h6"
                  color={errors.hours_spent ? 'red' : 'primary'}
                  sx={{ mb: 2 }}
                >
                  Time spent on my PDP{' '}
                  <Typography
                    variant={'caption'}
                    color={errors.hours_spent ? 'red' : 'primary'}
                    fontWeight={400}
                  >
                    (Required)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="body2">
                      Log the time you have spent completing your PDP to a maximum of 5 hours.
                    </Typography>
                    <Typography variant="body2">
                      The hours will be allocated to Reviewing Performance upon submission.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'block' }}>
                    <Controller
                      name="hours_spent"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          color="secondary"
                          {...field}
                          {...register('hours_spent', {
                            required: true,
                            min: 0.5,
                            max: 5,
                            setValueAs: (v: any) => nanToZero(Number.parseFloat(v)),
                            onBlur: () => {
                              roundField('hours_spent');
                            }
                          })}
                          error={!!error}
                          sx={{ width: '130px' }}
                          type={'number'}
                          label="Hours"
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: 0.5
                            }
                          }}
                        />
                      )}
                    />
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '0.725rem', mt: 1 }}
                      color={errors.hours_spent ? 'red' : 'primary'}
                    >
                      5 CPD hour limit per PDP
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ my: 4 }} />
            <Box display={'flex'} gap={2} justifyContent={{ xs: 'center', md: 'flex-end' }}>
              <Button
                sx={{ width: '100%', maxWidth: 180, height: 40 }}
                color="primary"
                variant="contained"
                type={'submit'}
                onClick={submitForm}
                disabled={pdpSubmitStatus === PdpStatus.PdpSubmitted && !isValid}
              >
                {pdpSubmitStatus === PdpStatus.PdpSubmitted ? 'Update' : 'Submit PDP'}
              </Button>
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              disableEscapeKeyDown={true}
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ display: 'flex', gap: 2 }}>
                  <CheckCircleOutlineIcon color={'secondary'} />
                  <Box>
                    <Box>{dialogTitle}</Box>
                    <Box>{dialogDescription}</Box>
                  </Box>
                </DialogContentText>
                <DialogActions sx={{ p: 0 }}>
                  <Button variant="text" onClick={handleClose} sx={{ minWidth: 'initial' }}>
                    Close
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </Paper>
        </Container>
      </form>
    </>
  );
};

export { PDPUpload };
