import React from 'react';
import { Box, Typography, Skeleton, Paper, Pagination, Alert } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { isArray } from 'lodash';

import { GetNotificationsQuery, Notification } from 'src/graphql/generated';
import { SearchActivtyItem } from './SearchResultWithActivityId';
import { SearchResultsEmpty } from './SearchResultsEmpty';
import { CPDUpdateResult } from './CPDUpdateResult';
import { RemoveAllNotificationsButton } from './RemoveAllNotificationsButton';
import { NotificationSettingsButton } from 'src/components/NotificationSettings/NotificationSettings';
import { useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

type NotificationsSearchResultsProps = {
  getNotificationsLoading: boolean;
  getNotificationsData: GetNotificationsQuery | undefined;
  pageNumber: number;
  setPageNumber: (page: number) => void;
  rowsPerPage: number;
};

const NotificationsSearchResults: React.FC<NotificationsSearchResultsProps> = ({
  getNotificationsLoading,
  getNotificationsData,
  pageNumber,
  setPageNumber,
  rowsPerPage
}) => {
  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  const location = useLocation<{ onNotificationDeleted: Boolean }>();
  const { onNotificationDeleted = false } = location?.state || {};
  if (getNotificationsLoading) {
    return (
      <>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Skeleton
            variant="rectangular"
            sx={{ mt: 2, width: '100px', borderRadius: '10px', bgcolor: 'grey.100' }}
          />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '30%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 1, width: '40%', bgcolor: 'grey.100' }} />
          <Skeleton variant="rectangular" sx={{ mt: 2, width: '80%', bgcolor: 'grey.100' }} />
          <Skeleton
            sx={{
              width: '100px',
              borderRadius: '10px',
              bgcolor: 'grey.100',
              mt: 4
            }}
          />
        </Paper>
      </>
    );
  }

  const searchResults = getNotificationsData?.getNotifications;
  const notificationsToDisplay = searchResults?.items ?? ([] as Notification[]);
  const firstItemDisplayed = (pageNumber - 1) * rowsPerPage + 1;
  const lastItemDisplayed = Math.min(searchResults?.total ?? rowsPerPage, pageNumber * rowsPerPage);

  const totalCount = searchResults?.total ?? notificationsToDisplay.length;
  const paginationPageCount = Math.ceil(totalCount / rowsPerPage);
  const hasResults = searchResults?.total && searchResults?.total > 0;

  if (!hasResults) {
    return <SearchResultsEmpty></SearchResultsEmpty>;
  }

  return (
    <Box>
      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="body2">{`Showing ${firstItemDisplayed} to ${lastItemDisplayed} of ${totalCount} notifications`}</Typography>
        </Box>
        {hasResults && (
          <Box display="flex">
            <NotificationSettingsButton
              buttonProps={{
                startIcon: <SettingsOutlinedIcon />,
                sx: { mr: 2 }
              }}
            />
            <RemoveAllNotificationsButton totalResults={searchResults?.total} />
          </Box>
        )}
      </Box>
      <Box>
        {onNotificationDeleted && (
          <Alert
            sx={{ mt: 2 }}
            icon={<CheckCircleOutlineIcon fontSize="inherit" />}
            severity="success"
          >
            Your notification has successfully been dismissed.
          </Alert>
        )}
        {notificationsToDisplay.map((item: Notification | null | undefined, index) => {
          const itemDetails = item?.reference_id?.split('_');
          let activityId;
          let sessionId;

          if (isArray(itemDetails)) {
            activityId = itemDetails[0];
            sessionId = itemDetails[1];
          }

          return item?.type === 'cpd_update' ? (
            <div key={index}>
              <CPDUpdateResult notification={item} />
            </div>
          ) : (
            <SearchActivtyItem
              key={index}
              activityId={activityId}
              notification={item}
              sessionId={sessionId}
            />
          );
        })}
      </Box>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Pagination page={pageNumber} count={paginationPageCount} onChange={onPageChange} />
      </Box>
    </Box>
  );
};

export { NotificationsSearchResults };
